import TextField from "./TextField";
import React, {useEffect} from "react";
import {COLON, EMPTY, TOOLTIP_PROPS} from "../../constants/appConstant";
import {useLazyQuery} from "@apollo/client";
import {GET_YOY_OBSERVED} from "../../constants/graphql-query-mutation";
import {usePlacementFormDataContext} from "../CampaignsContext/useCampaignsContext";
import {hasValue, isEmpty} from "../../utils/util";
import moment from "moment";
import {roundOffToTwoDecimalPlaces} from "../../utils/formatFunctions";
import {useIntl} from "../../useIntl";

const YoYObservedHelp = ({handleChangePerformance, getPlacementFieldData, isEdit}) => {

    let helpMessage, getYoYRequestParams = {};
    const [loadYoYObserved, yoyObservedProps] = useLazyQuery(GET_YOY_OBSERVED)
    const {formData, updateFormData} = usePlacementFormDataContext();
    const intl = useIntl();

    const loadingMessage = <p
        className="fetch-message">{intl.formatMessage({id: "campaigns.yoy.observed.loading.message"})}</p>
    const errorMessage = <p
        className="fetch-message">{intl.formatMessage({id: "campaigns.yoy.observed.error.message"})}</p>
    const successMessagePretext = intl.formatMessage({id: "campaigns.placement.yoy.observed.percentage"}) + COLON

    const yearOverYearObserved = yoyObservedProps.data?.getYoyObserved?.yearOverYearObserved
    const _create_yearOverYearObserved = yearOverYearObserved ? roundOffToTwoDecimalPlaces({value: yearOverYearObserved}) : intl.formatMessage({id: "campaigns.not.available"});
    const _edit_yearOverYearObserved = getPlacementFieldData('yearOverYearObserved', 'number') ? roundOffToTwoDecimalPlaces({value: getPlacementFieldData('yearOverYearObserved', 'number')}) : intl.formatMessage({id: "campaigns.not.available"})

    //if loadYoYObserved query triggers and data loads; render loaded value else value from placementRowData
    const calculatedYearOverYearObserved = (yoyObservedProps.data && !yoyObservedProps.loading) ? _create_yearOverYearObserved : _edit_yearOverYearObserved

    //if input fields in Modal are dirty, formData will have the values
    getYoYRequestParams.advertiserCId = formData?.advertiserCompanyId?.value || parseInt(getPlacementFieldData('advertisercompanyid', 'select')?.value)
    getYoYRequestParams.publisherCId = formData?.pid?.publisherCompanyId || parseInt(getPlacementFieldData('publishercompanyid', 'input'))
    getYoYRequestParams.publisherPropertyId = formData?.pid?.publisherPropertyId || parseInt(getPlacementFieldData('publisherpropertyid', 'input'))

    const hasError = yoyObservedProps.error && !isEmpty(yoyObservedProps.error)

    //if API (Insights or getYoYObserved) is down
    const isDataNull = yoyObservedProps.data && yoyObservedProps.data.getYoyObserved === null

    //Checks for both create and edit scenario
    const isDataLoaded = (yoyObservedProps.data && !yoyObservedProps.loading) || isEdit

    if (hasError || isDataNull) {
        helpMessage = errorMessage
    } else {
        //formData.pid will be null if PID is invalid (check PublisherPropertyId.js) and NaN when input value is removed (and hence empty)
        helpMessage = (formData?.pid === null || getYoYRequestParams.publisherCId.isNaN) ? EMPTY
            : (yoyObservedProps.loading ? loadingMessage : (isDataLoaded ? successMessagePretext + calculatedYearOverYearObserved : EMPTY))
    }

    useEffect(() => {
        //loadYoYObserved query should be triggered if yearOverYearObserved exists in placementRowData and either of advertiserCompanyId or pid is touched in Edit modal
        const hasFormDataUpdatedInEdit = isEdit ? (hasValue(formData['advertiserCompanyId']) || hasValue(formData['pid'])) : true

        if ((formData && getYoYRequestParams.advertiserCId && getYoYRequestParams.publisherCId && getYoYRequestParams.publisherPropertyId) && hasFormDataUpdatedInEdit) {
            const {advertiserCId, publisherCId, publisherPropertyId} = getYoYRequestParams
            let currentDate = moment().subtract(1, 'day').format('YYYY-MM-DD')

            loadYoYObserved({
                    variables: {
                        advertiserCId,
                        publisherCId,
                        publisherPropertyId,
                        currentDate
                    }
                }
            )
        }
        if (!yoyObservedProps.loading && yoyObservedProps.data) {
            if (yoyObservedProps.data.getYoyObserved) {
                const {__typename, ...forecastPerformance} = yoyObservedProps.data.getYoyObserved
                updateFormData({...formData, forecastPerformance})
            } else if (yoyObservedProps.data.getYoyObserved === null) {
                updateFormData({...formData, forecastPerformance: yoyObservedProps.data.getYoyObserved})
            }
        }
    }, [formData.advertiserCompanyId, formData.pid, yoyObservedProps.data?.getYoyObserved]);

    return (<TextField
        label={intl.formatMessage({id: "campaigns.placement.yoy.growth.trend"})}
        id="performanceyoygrowthtrendused"
        className="overwrite-legacy-textField"
        type="number"
        placeholder={intl.formatMessage({id: "campaigns.enter.number.placeholder"})}
        help={helpMessage}
        onChange={({target: {id, value}}) => handleChangePerformance(id, parseFloat(value))}
        defaultValue={getPlacementFieldData('performanceyoygrowthtrendused', 'input')}
        labelTooltip={intl.formatMessage({id: "campaigns.placement.yoy.growth.trend.tooltip"})}
        tooltipProps={TOOLTIP_PROPS}
    />)
}

export default YoYObservedHelp;
