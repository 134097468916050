import {Box} from "@cjdev-internal/visual-stack-x/Box";
import {useUserContext} from "../../context/UserContextProvider";
import {AUTHORIZED_VIEW} from "../../constants/appConstant";

const ActionButtons = ({CreateRecordModal, BulkUploadAndDownloadDropDown}) => {
    const {userView} = useUserContext();
    return userView === AUTHORIZED_VIEW ? (
        <Box direction="row">
            <Box paddingRight={"medium"}>
                {CreateRecordModal}
            </Box>
            {BulkUploadAndDownloadDropDown}
        </Box>
    ) : false;
}

export default ActionButtons;
