import {FlexPage} from "@cjdev-internal/visual-stack-x/FlexPage";
import {DataGridEnterprise} from "@cjdev-internal/visual-stack-x/DataGridEnterprise";
import {DATATABLE_LICENSE_KEY} from "../../constants/appConstant";
import {useEffect, useState} from "react";
import {useIntl} from "../../useIntl";

const BulkUploadDataTable = (columns, downloadIconWrapper) => {
    return function BulkUploadDataTable({values, title}) {
        const [gridRef, setGridRef] = useState();
        const intl = useIntl();
        const defaultColDef = {
            suppressMovable: true,
            suppressMenu: true
        }
        const gridOptions = {
            suppressPropertyNamesCheck: true
        }
        useEffect(() => {
            gridRef && gridRef.api.refreshCells({
                force: true
            });
        }, [values])
        return (
            <div className={"bulk-upload-datatable"}>
                <FlexPage heightAdjust="32px">
                    <DataGridEnterprise
                        rowData={values}
                        columnDefs={columns}
                        gridOptions={gridOptions}
                        getRowId={(data) => data.id}
                        EXPLICIT_ENTERPRISE_AUTH_DO_NOT_USE_WITHOUT_PERMISSION={true}
                        defaultColDef={defaultColDef}
                        pagination
                        suppressContextMenu={true}
                        licenseKey={DATATABLE_LICENSE_KEY}
                        sideBar={false}
                        title={title}
                        topPanel={downloadIconWrapper}
                        onGridReady={setGridRef}
                        intl={intl}
                    />
                </FlexPage>
            </div>
        )
    }
}

export default BulkUploadDataTable;
