import {CampaignsContext, PlacementFormDataContext} from "./CampaignsContext";
import {useState} from "react";
import moment from "moment";
import * as appConstant from '../../constants/appConstant';
import {YYYY_MM_DD} from '../../constants/appConstant';

const CampaignsProvider = ({children, getUserLinkedAdvertiser}) => {

    const [formData, updateFormData] = useState({});

    const defaultFilter = {
        advertisers: [],
        status: [],
        publishers: [],
        dateRange: {
            startDate: moment().startOf("year").format(YYYY_MM_DD),
            endDate: moment().endOf("year").format(YYYY_MM_DD),
            namedRange: ['this_year']
        }
    }
    const [filterData, setFilterData] = useState(defaultFilter);
    const set = new Set([...appConstant.MANDATORY_UI_COLUMNS, ...appConstant.DEFAULT_SELECTED_COLUMN]);
    const [selectedColumns, setSelectedColumns] = useState({value: set, state: null});
    const initCampaignsDataGridState = {
        quickFilterValue: "",
        rowsPerPage: 50,
        filterModel: null
    }
    const [campaignsDataGridState, setCampaignsDataGridState] = useState(initCampaignsDataGridState);

    return (
        <CampaignsContext.Provider value={{
            filterData, setFilterData,
            selectedColumns, setSelectedColumns,
            campaignsDataGridState, setCampaignsDataGridState
        }}>
            <PlacementFormDataContext.Provider value={{formData, updateFormData}}>
                {children}
            </PlacementFormDataContext.Provider>
        </CampaignsContext.Provider>
    )
}

export default CampaignsProvider;

