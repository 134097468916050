import {GET_YOY_OBSERVED} from "../../../constants/graphql-query-mutation";
import moment from "moment/moment";
import {TYPE_BATCH} from "../../../constants/appConstant";

const getYOYDetails = (client, formatMessage) => {

    return (row, warningList, index) => {
        return client.query({
            query: GET_YOY_OBSERVED, variables: {
                advertiserCId: row.advertiserCompanyId?.value,
                publisherCId: row.pid.publisherCompanyId,
                publisherPropertyId: row.pid.publisherPropertyId,
                currentDate: moment().subtract(1, 'day').format('YYYY-MM-DD')
            }, fetchPolicy: 'cache-first',
            context: {type: TYPE_BATCH}
        })
            .then(getYOYDetailsResponse => {
                const {__typename, ...yoyObserved} = getYOYDetailsResponse.data.getYoyObserved
                row.forecastPerformance = yoyObserved;
                warningList[index] = [];
                if (row.forecastPerformance.yearOverYearCurrentYearRevenue === 0)
                    warningList[index].push(formatMessage({id: "campaigns.bulkupload.yoy.warning.details"}));
            });
    }
}

export default getYOYDetails;
