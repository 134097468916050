import {PLACEMENT_MODE_BULK_UPLOAD} from "../../../constants/appConstant";
import {TEMPLATE_START_ROW_NUMBER} from "../BulkUploadConstant";

const bulkUploadValidationProvider = (formValidate, dataTypeValidator, pidValidation, getYOYDetails, getBaselineDetails,
                                      bulkUploadErrorsFormatter, bulkUploadWarningFormatter, formatMessage) => {
    return async (rows) => {
        const errorList = [];
        const warningList = [];
        let errorFlag = {value: false};
        rows.forEach((row) => {
            const {tabErrorMessage, errorData} = formValidate(row, {}, PLACEMENT_MODE_BULK_UPLOAD, formatMessage);
            dataTypeValidator(row, tabErrorMessage, errorData);
            if (tabErrorMessage.length !== 0) {
                errorFlag.value = true;
            }
            errorList.push(tabErrorMessage);
        })
        // Validating Publisher Property ID only if no errors in above validations
        if (!errorFlag.value)
            await Promise.all(rows.map((row, index) => pidValidation(row, errorList[index], errorFlag)));

        // Fetching YOY and Baseline Details if no errors in validations
        if (!errorFlag.value) {
            await Promise.all(rows.map((row, index) =>
                getYOYDetails(row, warningList, index))).catch((error) => {
                console.error("error in getYOYDetails ", error.message)
            });
            await Promise.all(rows.map((row, index) =>
                getBaselineDetails(row, warningList, index))).catch((error) => {
                console.error("error in getBaselineDetails ", error.message)
            });
        }
        const validationErrorList = errorList.map((errorDetails, index) => {
            // checking for error validation of row data
            rows[index].isValid = errorDetails.length === 0;
            return bulkUploadErrorsFormatter(errorDetails, index + TEMPLATE_START_ROW_NUMBER, formatMessage);
        }).filter(value => value !== null);

        const validationWarningList = warningList.map((warningDetails, index) => {
            if (warningDetails.length > 0)
                rows[index].isValid = null;
            return bulkUploadWarningFormatter(warningDetails, index + TEMPLATE_START_ROW_NUMBER, formatMessage);
        }).filter(value => value !== null);
        return {validationErrorList, validationWarningList};
    }
}

export default bulkUploadValidationProvider;
