import {gql} from "@apollo/client";

export const PP_QUERY = gql`
    query GetPublisherPropertyDetails($publisherPropertyId: Int) {
        publisherProperty(publisherPropertyId:$publisherPropertyId){
            publisherName
            publisherCompanyId
            propertyName
            propertyModel
            websiteUrl
            propertyType
        }
    }`;

export const GET_PLACEMENT_DATA = gql`
    query getPlacementData($advertiserCompanyId: advertiser_cid!,  $publisherCompanyId: [Int], $status: [String], $dateRange: DateRange!){
        getPlacementData(filtersParams: {advertiserCompanyId: $advertiserCompanyId, publisherCompanyId: $publisherCompanyId, status: $status, dateRange: $dateRange}){
            id
            status
            placementname
            placementtype
            startdate
            enddate
            fiscalyear
            fiscalquarter
            fiscalmonth
            fiscalweek
            advertisercompanyid
            advertisername
            packagename
            neworcompedplacement
            personalizedortargeted
            dynamicorstatic
            publishercompanyid
            propertymodel
            propertyname
            websiteurl
            publishername
            vipcontent
            publisherpropertyid
            propertytype
            event
            rankofplacement
            proposedofferuseddescription
            bookingcjmarketplaceinsertionordernumber
            bookingotherinsertionordernumber
            bookinginsertionordersignedindicator
            bookingprogramtermname
            bookingprogramtermspushedindicator
            bookinglinkid
            bookingassetduedate
            bookingassetsneeded
            bookingassetssenttoadvertiser
            bookingcashbackorincentiveamountonsite
            bookingtieredoffer
            bookingoffertype
            bookingoffervalue
            bookingofferconditions
            bookingofferthreshold
            bookingprogramtermdescription
            bookinginvoicemonth
            transactionid
            amount
            transactiondate
            paidincj
            notes
            paymentcurrency
            publisherusedspecifiedlinkid
            wascorrectofferused
            description
            screenshotlink
            validationnotes
            performanceoriginalpublishercommission
            performancenewpublishercommission
            performanceoriginalratecardflatfeeprice
            performancecjnegotiatedflatfeecost
            performancepredictedplacementlift
            performanceotherexpectedlift
            performancesendsizeexpecteduniquevalues
            performancesovbefore
            performanceactualsovafter
            performanceyoygrowthtrendused
            calendarmonth
            calendarqtr
            calendarweek
            calendaryear
            paymentid
            validationid
            projectedincrementalreturnonadspend
            projectedincrementalrevenue
            projectedincrementalspend
            projectedreturnonadspend
            projectedrevenuewithoutplacement
            projectedrevenuewithplacement
            projectedspendwithoutplacement
            projectedspendwithplacement
            lastyearsactualrevenue
            lastyearsactualcommissions
            yoyquarterstartdatecurrentyear
            yoyquarterenddatecurrentyear
            yoyquarterstartdatelastyear
            yoyquarterenddatelastyear
            yearoveryearcurrentyearrevenue
            yearoveryearlastyearrevenue
            yearoveryearobserved
            averagebaselinerevenue
            isbaselinecalculated
            actualrevenue
            actualincrementalrevenue
            actualtotalcommissions
            actualtotalspend
            actualincrementalspend
            actualreturnonadspend
            actualincrementalreturnonadspend
            actualclicks
            actualrevenuepervisits
            actualsales
            actualnumberofsalesgeneratedperclick
            actualplacementlift
            revenuedelta
            revenueaccuracy
            returnonadspendaccuracy
            currency
        }
    }`;

export const GET_YOY_OBSERVED = gql`
    query getYoyObserved($advertiserCId: advertiser_cid!, $publisherCId: Int!, $publisherPropertyId: Int!, $currentDate: String!){
        getYoyObserved(queryParameter: {advertiserCId: $advertiserCId, publisherCId: $publisherCId, publisherPropertyId: $publisherPropertyId, currentDate: $currentDate}){
            yearOverYearLastYearRevenue
            yearOverYearCurrentYearRevenue
            yoyQuarterStartDateLastYear
            yoyQuarterEndDateLastYear
            yoyQuarterStartDateCurrentYear
            yoyQuarterEndDateCurrentYear
            yearOverYearObserved
        }
    }`;

export const GET_BASELINE_DETAIL_QUERY = gql`
    query getBaselineDetails($advertiserCompanyId: advertiser_cid!, $publisherCompanyId: Int!, $publisherPropertyId: Int!, $startDate: String!){
        getBaselineDetails(advertiserCompanyId: $advertiserCompanyId,publisherCompanyId: $publisherCompanyId,publisherPropertyId: $publisherPropertyId,startDate: $startDate){
            averagedailyrevenue
            currency
        }
    }`;

export const GET_EXCEL_DOWNLOAD_URL = gql`
    query getExcelDownloadUrl($ExcelSheet1: ExcelSheetDetails!, $ExcelSheet2: ExcelSheetDetails, $ExcelSheet3: ExcelSheetDetails, $Filter:ExportFilter!){
        getExcelDownloadUrl(excelDownloadInputParams: {ExcelSheet1: $ExcelSheet1, ExcelSheet2: $ExcelSheet2, ExcelSheet3: $ExcelSheet3, Filter: $Filter}){
            url
        }
    }`;


export const CREATE_PLACEMENT_MUTATION = gql`
    mutation(
        $placementname: String,
        $status: String,
        $advertisercompanyid: advertiser_cid!,
        $startdate: String,
        $enddate: String,
        $fiscalmonth :String,
        $fiscalquarter :String,
        $fiscalweek :String,
        $fiscalyear :String,
        $advertisername: String,
        $publisherpropertyid: Int,
        $publishercompanyid: Int,
        $propertymodel: String,
        $websiteurl: String,
        $publishername: String,
        $propertyname: String,
        $propertytype: String,
        $createdby: String,
        $packagename:String,
        $placementtype: String,
        $dynamicorstatic: String,
        $personalizedortargeted: String,
        $neworcomped: String,
        $vipcontentcampaign: String,
        $rankofplacement: Int,
        $event: String,
        $proposedofferdescription: String,
        $validationDetails: ValidationDetails,
        $bookingdetails: BookingDetails,
        $paymentDetails:PaymentDetails,
        $performanceDetails: PerformanceDetails,
        $forecastPerformance: ForecastPerformance
    ){
        createPlacementDetails(
            placementDetails:
            {
                placementname:$placementname,
                status: $status,
                advertisercompanyid:$advertisercompanyid,
                startdate:$startdate,
                enddate:$enddate,
                fiscalmonth :$fiscalmonth,
                fiscalquarter :$fiscalquarter,
                fiscalweek :$fiscalweek,
                fiscalyear :$fiscalyear,
                advertisername: $advertisername,
                publisherpropertyid:$publisherpropertyid,
                publishercompanyid:$publishercompanyid,
                propertymodel:$propertymodel,
                websiteurl:$websiteurl,
                publishername:$publishername,
                propertyname:$propertyname,
                propertytype:$propertytype,
                createdby:$createdby,
                packagename:$packagename,
                placementtype: $placementtype,
                dynamicorstatic: $dynamicorstatic,
                personalizedortargeted: $personalizedortargeted,
                neworcomped: $neworcomped,
                vipcontentcampaign: $vipcontentcampaign,
                rankofplacement: $rankofplacement,
                event:$event,
                proposedofferdescription: $proposedofferdescription,
                validationdetails: $validationDetails,
                bookingdetails:$bookingdetails,
                paymentdetails:$paymentDetails,
                performanceDetails: $performanceDetails,
                forecastPerformance: $forecastPerformance
            }){
            message
        }
    }`;

export const UPDATE_PLACEMENT_MUTATION = gql`
    mutation(
        $placementId : Int!,
        $placementname: String,
        $status: String,
        $advertisercompanyid: String,
        $startdate: String,
        $enddate: String,
        $fiscalmonth :String,
        $fiscalquarter :String,
        $fiscalweek :String,
        $fiscalyear :String,
        $advertisername: String,
        $publisherpropertyid: Int,
        $publishercompanyid: Int,
        $propertymodel: String,
        $websiteurl: String,
        $publishername: String,
        $propertyname: String,
        $propertytype: String,
        $createdby: String,
        $packagename:String,
        $placementtype: String,
        $dynamicorstatic: String,
        $personalizedortargeted: String,
        $neworcompedplacement: String,
        $vipcontent: String,
        $rankofplacement: Int,
        $event: String,
        $proposedofferuseddescription: String,
        $validationDetails: ValidationDetails,
        $bookingdetails: BookingDetailsUpdate,
        $paymentDetails: PaymentDetails,
        $performanceDetails: PerformanceDetails,
        $paymentid:Int,
        $validationid:Int,
        $forecastPerformance: ForecastPerformance
    ){
        updatePlacement(
            placementId : $placementId,
            placementUpdateDetails:
            {
                advertisercompanyid:$advertisercompanyid,
                advertisername: $advertisername,
                status: $status,
                placementname:$placementname,
                startdate:$startdate,
                enddate:$enddate,
                fiscalmonth :$fiscalmonth,
                fiscalquarter :$fiscalquarter,
                fiscalweek :$fiscalweek,
                fiscalyear :$fiscalyear,
                publisherpropertyid:$publisherpropertyid,
                publishercompanyid:$publishercompanyid,
                propertymodel:$propertymodel,
                websiteurl:$websiteurl,
                publishername:$publishername,
                propertyname:$propertyname,
                propertytype:$propertytype,
                createdby:$createdby,
                packagename:$packagename,
                placementtype: $placementtype,
                dynamicorstatic: $dynamicorstatic,
                personalizedortargeted: $personalizedortargeted,
                neworcompedplacement: $neworcompedplacement,
                vipcontent: $vipcontent,
                rankofplacement: $rankofplacement,
                event: $event,
                proposedofferuseddescription: $proposedofferuseddescription,
                validationdetails: $validationDetails,
                bookingdetails: $bookingdetails,
                paymentdetails:$paymentDetails,
                performancedetails: $performanceDetails,
                paymentid: $paymentid,
                validationid: $validationid,
                forecastPerformance: $forecastPerformance
            }){
            message
        }
    }`;

export const GET_REGISTERED_LINKED_ADVERTISER_QUERY = gql`
    query  getRegisteredLinkedAdvertiserData{
        getRegisteredLinkedAdvertiserData{
            firstName
            lastName
            emailAddress
            companies {
                id
                name
                type
                status
                functionalCurrency
                companyRole
            }
            isEmployee
            employeeRoles
        }
    }`;

export const REMOVE_PLACEMENT_MUTATION = gql`
    mutation($placementId : Int!){
        deletePlacement(placementId : $placementId){
            message
            id
        }
    }`;

export const CALCULATE_BASELINE_QUERY = gql`query calculateBaseline {
        calculateBaseline {
        message
        statusCode
        }
    }`;

export const REGISTER_ADVERTISER_MUTATION = gql`
    mutation($advertiserCId: Int!, $advertiserName: String!,$timeZone: String!,$currency: String!){
        createOrUpdateAdvertiserSettings(advertiserSettingsDetails :{
            advertiserCId: $advertiserCId,
            advertiserName: $advertiserName,
            timeZone: $timeZone,
            currency: $currency
            })
            {
            message
            }
    }`;
