import React, {useEffect, useState} from "react";
import {Button} from "@cjdev-internal/visual-stack-x/Button";
import {Box} from "@cjdev-internal/visual-stack-x/Box";
import {Modal} from "@cjdev-internal/visual-stack-x/Modal";
import CreateRecordModalContent from "./CreateRecordModalContent";
import * as appConstant from "../../constants/appConstant";
import {formValidate} from "../../utils/util";
import {useApolloClient, useMutation} from "@apollo/client";
import {
    CREATE_PLACEMENT_MUTATION,
    GET_PLACEMENT_DATA,
    UPDATE_PLACEMENT_MUTATION
} from "../../constants/graphql-query-mutation";
import "./styles/CreateRecordModal.css";
import {usePlacementFormDataContext} from "../CampaignsContext/useCampaignsContext";
import PlacementDialogSubmitAction from "./PlacementDialogSubmitAction";
import {useIntl} from "../../useIntl";

const PlacementModalDialog = ({
                                  takeAction,
                                  showToast,
                                  closeModal,
                                  openAlert,
                                  closeAlert,
                                  placementModalTitle,
                                  modeOfPlacement,
                                  placementRecord = {},
                                  campaignsTranslatedDropdownList
                              }) => {
    const [isProcessing, setIsProcessing] = useState(false);
    const [errorData, setErrorData] = useState(new Map());
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const {formData, updateFormData} = usePlacementFormDataContext();
    const client = useApolloClient();
    const intl = useIntl();

    const mutationHandler = {
        onCompleted: setData,
        onError: setError
    }

    const [createPlacementDetails, createPlacementDetailsProps] = useMutation(CREATE_PLACEMENT_MUTATION, mutationHandler)

    const [updatePlacementDetails, updatePlacementDetailsProps] = useMutation(UPDATE_PLACEMENT_MUTATION, mutationHandler);

    const handleCloseModal = () => {
        closeModal();
        updateFormData({});
    }

    const handleChange = (id, value) => {
        updateFormData({
            ...formData,
            [id]: value
        });
        if (id === 'pStartDate')
            errorData.set('pEndDate', false);
        errorData.set(id, false);
    };


    let loading = createPlacementDetailsProps.loading || updatePlacementDetailsProps.loading;
    useEffect(() => {
        setIsProcessing(loading);
        return () => {
            setIsProcessing({});
        };
    }, [loading]);


    useEffect(() => {
        if (!loading) {
            if (error)
                console.error(error);

            if (data) {
                let responseMessage = data.createPlacementDetails ?
                    JSON.stringify(data.createPlacementDetails.message) :
                    JSON.stringify(data.updatePlacement.message);

                responseMessage = responseMessage.replace(/("|')/g, "");

                if (responseMessage.includes(appConstant.CREATE_MODAL_SUCCESS_MESSAGE_TO_COMPARE)) {
                    let successMessage = "";
                    if (responseMessage === appConstant.UPDATE_MODAL_SUCCESS_MESSAGE_TO_COMPARE) {
                        successMessage = intl.formatMessage({id: 'campaigns.update.placement.success.message'})
                    } else {
                        const placementId = responseMessage.match(/\d+/)[0];
                        successMessage = intl.formatMessage({id: 'campaigns.create.placement.success.message'});
                        successMessage = successMessage.replace('${id}', placementId);
                    }
                    showToast({
                        message: successMessage,
                        contentStyle: {textAlign: "center", minWidth: "300px"},
                        type: 'success',
                        duration: '5000',
                        dismissible: true
                    });
                    handleCloseModal();
                    client.refetchQueries({
                        include: [GET_PLACEMENT_DATA],
                    });
                } else if (responseMessage.includes(appConstant.CREATE_MODAL_FAILURE_MESSAGE_TO_COMPARE)) {
                    const failureMessage = intl.formatMessage({id: 'campaigns.create.placement.failure.message'});
                    showToast({
                        message: failureMessage,
                        contentStyle: {textAlign: "center", minWidth: "300px"},
                        type: 'warning',
                        dismissible: true
                    });
                } else if (responseMessage.includes(appConstant.UPDATE_MODAL_FAILURE_MESSAGE_TO_COMPARE)) {
                    const failureMessage = intl.formatMessage({id: 'campaigns.update.placement.failure.message'});
                    showToast({
                        message: failureMessage,
                        contentStyle: {textAlign: "center", minWidth: "300px"},
                        type: 'warning',
                        dismissible: true
                    });
                } else if (responseMessage.includes(appConstant.CREATE_MODAL_REMOVED_PLACEMENT_ERROR_MESSAGE_TO_COMPARE)) {
                    const editedPlacementName = responseMessage.match(/- (.*)/)[1];
                    const placementId = responseMessage.match(/\d+/)[0];
                    let updateFailureMessage = intl.formatMessage({id: 'campaigns.update.deleted.placement.failure.message'});
                    updateFailureMessage = updateFailureMessage.replace('${id}', placementId);
                    updateFailureMessage = updateFailureMessage.replace('${placementName}', editedPlacementName);
                    showToast({
                        message: updateFailureMessage,
                        contentStyle: {textAlign: "center", minWidth: "300px"},
                        type: 'warning',
                        dismissible: true
                    });
                }
                setData(null);
            }
        }
    }, [data, showToast]);

    const clickEvent = async () => {
        let {tabErrorMessage, errorData} = formValidate(formData, placementRecord, modeOfPlacement, intl.formatMessage);
        if (tabErrorMessage.length > 0) {
            setErrorData(errorData);
            alertCall(tabErrorMessage);
        } else {
            switch (modeOfPlacement) {
                case appConstant.PLACEMENT_MODE_CREATE:
                    await takeAction(formData, createPlacementDetails);
                    break;
                case appConstant.PLACEMENT_MODE_EDIT:
                    let canCloseEditModalDialog = takeAction(formData, updatePlacementDetails, placementRecord);
                    canCloseEditModalDialog === true && closeModal();
                    break;
                case appConstant.PLACEMENT_MODE_DUPLICATE:
                    await takeAction(formData, createPlacementDetails, placementRecord, {PlacementDialogSubmitAction});
                    break;
                default:
                    takeAction(formData, createPlacementDetails);
                    break;
            }
        }
    }

    const alertCall = (tabErrorMessage) => {
        openAlert({
            onClose: closeAlert,
            type: 'warning',
            closeType: 'icon',
            children: <div key="tabErrorMessage">{tabErrorMessage}</div>
        })
    }
    const demoModalProps = {
        headerTitle: placementModalTitle,
        footer: <>
            <Box direction="row" justify="end">
                <div className="button-spacing">
                    <Button type="tertiary" onClick={handleCloseModal}
                            disabled={isProcessing}>{intl.formatMessage({id: "campaigns.cancel"})}</Button>
                </div>
                <Button type="primary" onClick={clickEvent}
                        disabled={isProcessing}>{intl.formatMessage({id: "campaigns.save.record"})}</Button>
            </Box>
        </>
    }

    return (<>
        <Modal {...demoModalProps} className={"create-placement-modal"}>
            <CreateRecordModalContent handleChange={handleChange}
                                      errorData={errorData}
                                      placementRecord={placementRecord}
                                      modeOfPlacement={modeOfPlacement}
                                      campaignsTranslatedDropdownList={campaignsTranslatedDropdownList}/>
        </Modal>
    </>);
};

export default PlacementModalDialog;