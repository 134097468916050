import React from "react";
import {DatePicker} from "antd";
import moment from 'moment';
import "antd/dist/antd.css";
import './styles/CreateRecordModal.css';
import {useIntl} from "../../useIntl";

const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
const CampaignsDatePicker = ({id, handleChange, defaultValue, errorData, defaultTime, showTime = true}) => {
    const {formatMessage} = useIntl();
    return <DatePicker id={id}
                       onChange={(date, dateString) => handleChange(id, dateString === "" ? null : dateString)}
                       placeholder={formatMessage({id: "campaigns.select.date.placeholder"})}
                        //can give 2 values boolean and defaultvalue
                        // boolean to hide or show
                        //object to set default time on click of date
                       showTime={showTime ? {defaultValue: defaultTime} : false}
                       defaultValue={defaultValue === '' ? "" : moment(defaultValue, dateTimeFormat)}
                       className={errorData && errorData.value === true ? "datepickerError" : ""}/>;
};
export default CampaignsDatePicker;