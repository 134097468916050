import {PP_QUERY} from "../../../constants/graphql-query-mutation";
import {TYPE_BATCH} from "../../../constants/appConstant";

const pidValidation = (client, formatMessage) => {
    return (row, rowErrors, errorFlag) => {
        const publishPropertyID = row.pid.publisherPropertyId;
        return client.query({
            query: PP_QUERY, variables: {
                publisherPropertyId: parseInt(publishPropertyID)
            }, fetchPolicy: 'cache-first', context: {type: TYPE_BATCH}
        })
            .then(publisherPropertyIdResponse => {
                const pidObject = publisherPropertyIdResponse.data.publisherProperty;
                row.pid = {...pidObject, publisherPropertyId: publishPropertyID};
            })
            .catch(() => {
                errorFlag.value = true;
                const errorMessage = formatMessage({id: "campaigns.bulkupload.invalid.pid.error.message"});
                rowErrors.push(errorMessage);
            });
    }
}

export default pidValidation;