const dataTypeValidator = (columns, formatMessage) => {
    return (row, tabErrorMessages, errorData) => {
        columns.forEach(column => {
            const columnKey = column.field.match('[^.]*$')[0];
            const error = errorData.get(columnKey)?.value;
            if (!error && column.validation) {
                const isValid = column.validation(row, column, formatMessage);
                if (!isValid) {
                    let errorMessage = typeof column.errorMessage === 'function' ? column.errorMessage(row, column, formatMessage) : column.errorMessage
                    tabErrorMessages.push(errorMessage);
                }
            }
        })
    }
}
export default dataTypeValidator;
