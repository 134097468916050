import * as appConstant from "../../constants/appConstant";


const PlacementDialogDuplicateSubmitAction = async (formData, submitAction, placementRecord, {PlacementDialogSubmitAction}) => {

    function compareAndUpdateDuplicatePlacement() {
        const compareAndUpdateDuplicateObject = {
            placementStatusId: setValue('placementStatusId', 'status'),
            advertiserCompanyId: constructUpdateObject(appConstant.detailsTabAdvertiser, "advertiserCompanyId"),
            pid: constructUpdateObject(appConstant.detailsTabPID, "pid"),
            pStartDate: setValue('pStartDate', 'startdate'),
            pEndDate: setValue('pEndDate', 'enddate'),
            placementName: setValue('placementName', 'placementname'),
            placementType: setValue('placementType', 'placementtype'),
            dynamicOrStatic: setValue('dynamicOrStatic', 'dynamicorstatic'),
            forecastPerformance: constructUpdateObject(appConstant.forecastPerformanceDetails, "forecastPerformance"),
            vipContentCampaign: setValue('vipContentCampaign', 'vipcontent'),
            newOrComped: setValue('newOrComped', 'neworcompedplacement'),
            personalizedOrTargeted: setValue('personalizedOrTargeted', 'personalizedortargeted'),
            events: setValue('events', 'event'),
            packageName: setValue('packageName', 'packagename'),
            rankofplacement: setValue('rankofplacement', 'rankofplacement'),
            proposedOfferDescription: setValue('proposedOfferDescription', 'proposedofferuseddescription'),
            performanceDetails: constructUpdateObject(appConstant.performanceTabDetails, "performanceDetails"),
            fiscalYear: setValue('fiscalYear', 'fiscalyear'),
            fiscalQuarter: setValue('fiscalQuarter', 'fiscalquarter'),
            fiscalMonth: setValue('fiscalMonth', 'fiscalmonth'),
            fiscalWeek: setValue('fiscalWeek', 'fiscalweek'),
            bookingdetails: formData["bookingdetails"],
            validationDetails: formData["validationDetails"],
            paymentDetails: formData["paymentDetails"]
        }
        return compareAndUpdateDuplicateObject;
    }

    const setValue = (uiKey, dbKey, objectKey) => {
        if (objectKey) {
            return (formData[objectKey] && (formData[objectKey][uiKey] !== undefined)) ? (formData[objectKey][uiKey]) : placementRecord[dbKey];
        } else {
            return (formData[uiKey] !== undefined) ? formData[uiKey] : placementRecord[dbKey];
        }
    }

    function constructUpdateObject(objectValue, objectKey) {
        let returnObject = {};
        objectValue.forEach((object) => {
            returnObject[object.formKey] = setValue(object.formKey, object.getKey, objectKey);
        });
        return returnObject
    }

    const duplicateFormData = compareAndUpdateDuplicatePlacement(formData, placementRecord);
    await PlacementDialogSubmitAction(duplicateFormData, submitAction);
}

export default PlacementDialogDuplicateSubmitAction;