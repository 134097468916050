import {useBulkUploadContext} from "../../BulkUploadContext/BulkUploadContext";
import {Modal} from "@cjdev-internal/visual-stack-x/Modal";
import {Text} from "@cjdev-internal/visual-stack-x/Text";
import {Box} from "@cjdev-internal/visual-stack-x/Box";
import {useIntl} from "../../../useIntl";

const SavePlacementSummary = ({closeModal}) => {
    const {bulkUploadDetails, setBulkUploadDetails} = useBulkUploadContext();
    const intl = useIntl()
    const closeSavePlacementSummary = () => {
        setBulkUploadDetails((prevState) => ({
            rows: prevState.failedRows,
            successfulRows: [],
            failedRows: []
        }));
        closeModal();
    }
    const modalProps = {
        headerTitle: <>{bulkUploadDetails.failedRows.length} {intl.formatMessage({id: 'campaigns.bulkupload.summary.placement.error.title'})}</>,
        onCloseIconClick: closeSavePlacementSummary,
        height: "205px",
        minHeight: "205px",
        width: "465px"
    }

    let successFullRowsMessage = intl.formatMessage({id: "campaigns.bulkupload.summary.upload.success.message"});
    successFullRowsMessage = successFullRowsMessage.replace('{successFullRows}', bulkUploadDetails.successfulRows.length);
    successFullRowsMessage = successFullRowsMessage.replace('{totalRows}', bulkUploadDetails.rows.length);

    let failedRowsMessage = intl.formatMessage({id: "campaigns.bulkupload.summary.upload.failure.message"});
    failedRowsMessage = failedRowsMessage.replace('{failedRows}', bulkUploadDetails.failedRows.length);
    failedRowsMessage = failedRowsMessage.replace('{totalRows}', bulkUploadDetails.rows.length)
    return (
        <Modal {...modalProps}>
            <Box gap={'large'}>
                <Box>
                    <Text size={13}>
                        {successFullRowsMessage}
                    </Text>
                    <Text size={13}>
                        {failedRowsMessage}
                    </Text>
                </Box>
                <Text size={13}
                      color="subdued">{intl.formatMessage({id: "campaigns.bulkupload.summary.popup.contact.support.message"})}</Text>
            </Box>
        </Modal>
    )
}
export default SavePlacementSummary;
