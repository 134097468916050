import {ButtonWithDropdown} from "@cjdev-internal/visual-stack-x/components/ButtonWithDropdown";
import moment from "moment";
import React, {useCallback, useEffect, useState} from "react";
import {useCampaignsContext} from "../CampaignsContext/useCampaignsContext";
import "./styles/DateFilter.css";
import {DateRangePicker, useDateRangePicker, useDateRangeSidebar} from "@cjdev-internal/visual-stack-x/DateRangePicker";
import * as appConstant from '../../constants/appConstant';
import {useIntl} from "../../useIntl";

export const DateFilter = () => {
    const {filterData, setFilterData} = useCampaignsContext();
    const intl = useIntl();
    const {startDate, endDate, namedRange} = filterData.dateRange;
    const initialRange = [new Date(startDate), new Date(endDate)];
    const [selectedRange, setSelectedRange] = useState(initialRange);
    const [datePickerExpanded, setDatePickerExpanded] = useState(false);
    const [selectedNamedRanges, setSelectedNamedRanges] = useState(namedRange);

    const dateFilterStartDate = appConstant.DATE_FILTER_START_DATE;
    const dateFilterEndDate = moment().add(1, "year").endOf('year').format(appConstant.YYYY_MM_DD);

    const doExpand = () => {
        setDatePickerExpanded(!datePickerExpanded);
    };
    const [config, ranges, updateRange] = useDateRangePicker([
        {key: "selection", startingRange: selectedRange}
    ]);

    useEffect(() => {
        if (!datePickerExpanded) {
            updateRange("selection", {
                startDate: initialRange[0],
                endDate: initialRange[1],
                hidden: false
            });
        }
    }, [datePickerExpanded]);

    useEffect(() => {
        updateRange("selection", {
            startDate: selectedRange[0],
            endDate: selectedRange[1],
            hidden: false
        });
    }, [selectedRange]);

    const predefinedRanges = {
        today: [moment().startOf('day').toDate(), moment().endOf('day').toDate()],
        yesterday: [moment().subtract(1, 'days').startOf('day').toDate(), moment().subtract(1, 'days').endOf('day').toDate()],
        thisWeek: [moment().startOf('week').toDate(), moment().endOf('week').toDate()],
        lastWeek: [moment().subtract(1, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()],
        thisMonth: [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
        lastMonth: [moment().subtract(1, 'months').startOf('month').toDate(), moment().subtract(1, 'months').endOf('month').toDate()],
        nextQuarter: [moment().add(1, 'quarters').startOf('quarter').toDate(), moment().add(1, 'quarters').endOf('quarter').toDate()],
        thisQuarter: [moment().startOf('quarter').toDate(), moment().endOf('quarter').toDate()],
        lastQuarter: [moment().subtract(1, 'quarters').startOf('quarter').toDate(), moment().subtract(1, 'quarters').endOf('quarter').toDate()],
        thisYear: [moment().startOf('year').toDate(), moment().endOf('year').toDate()],
        lastYear: [moment().subtract(1, 'years').startOf('year').toDate(), moment().subtract(1, 'years').endOf('year').toDate()]
    };

    const handleSelect = (rangeKey, range) => {
        setSelectedNamedRanges([rangeKey]);
        setSelectedRange(range);
        updateRange("selection", {
            startDate: range[0],
            endDate: range[1],
            hidden: false
        });
    };

    const sidebarSections = [
        {
            id: "selection",
            title: intl.formatMessage({id: 'campaigns.date.filters.base.period'}),
            selectedItem: selectedNamedRanges[0],
            items: [
                {
                    id: "today",
                    label: intl.formatMessage({id: 'campaigns.date.filters.today'}),
                    onSelect: () => handleSelect('today', predefinedRanges.today)
                },
                {
                    id: "yesterday",
                    label: intl.formatMessage({id: 'campaigns.date.filters.yesterday'}),
                    onSelect: () => handleSelect('yesterday', predefinedRanges.yesterday)
                },
                {
                    id: "this_week",
                    label: intl.formatMessage({id: 'campaigns.date.filters.this.week'}),
                    onSelect: () => handleSelect('this_week', predefinedRanges.thisWeek)
                },
                {
                    id: "last_week",
                    label: intl.formatMessage({id: 'campaigns.date.filters.last.week'}),
                    onSelect: () => handleSelect('last_week', predefinedRanges.lastWeek)
                },
                {
                    id: "this_month",
                    label: intl.formatMessage({id: 'campaigns.date.filters.this.month'}),
                    onSelect: () => handleSelect('this_month', predefinedRanges.thisMonth)
                },
                {
                    id: "last_month",
                    label: intl.formatMessage({id: 'campaigns.date.filters.last.month'}),
                    onSelect: () => handleSelect('last_month', predefinedRanges.lastMonth)
                },
                {
                    id: "next_quarter",
                    label: intl.formatMessage({id: 'campaigns.date.filters.next.quarter'}),
                    onSelect: () => handleSelect('next_quarter', predefinedRanges.nextQuarter)
                },
                {
                    id: "this_quarter",
                    label: intl.formatMessage({id: 'campaigns.date.filters.this.quarter'}),
                    onSelect: () => handleSelect('this_quarter', predefinedRanges.thisQuarter)
                },
                {
                    id: "last_quarter",
                    label: intl.formatMessage({id: 'campaigns.date.filters.last.quarter'}),
                    onSelect: () => handleSelect('last_quarter', predefinedRanges.lastQuarter)
                },
                {
                    id: "this_year",
                    label: intl.formatMessage({id: 'campaigns.date.filters.this.year'}),
                    onSelect: () => handleSelect('this_year', predefinedRanges.thisYear)
                },
                {
                    id: "last_year",
                    label: intl.formatMessage({id: 'campaigns.date.filters.last.year'}),
                    onSelect: () => handleSelect('last_year', predefinedRanges.lastYear)
                },
                {
                    id: "custom",
                    label: intl.formatMessage({id: 'campaigns.date.filters.custom'}),
                    onSelect: () => handleSelect('custom', selectedRange)
                },
            ],
        },
    ];

    const sidebar = useDateRangeSidebar(sidebarSections);
    const displayDate = moment(selectedRange[0]).isSame(selectedRange[1], 'day') ? moment(selectedRange[0]).format('MMM D, YYYY') : moment(selectedRange[0]).format('MMM D, YYYY') + ' – ' + moment(selectedRange[1]).format('MMM D, YYYY');


    const handleDateChange = useCallback((ranges) => {
        const {startDate, endDate} = ranges.selection;
        const updatedSelectedRange = [startDate, endDate];
        setSelectedRange(updatedSelectedRange);

        const isManualChange = !Object.values(predefinedRanges).some(range =>
            moment(startDate).isSame(range[0], 'day') &&
            moment(endDate).isSame(range[1], 'day')
        );

        if (isManualChange) {
            setSelectedNamedRanges(["custom"]);
            const updatedSidebarSections = [...sidebarSections];
            updatedSidebarSections[0].selectedItem = "custom";
            sidebar.setSidebarSections(updatedSidebarSections);
        }
    }, [predefinedRanges, sidebar, sidebarSections]);

    const onApply = () => {
        const selectedRangeStartDate = moment(selectedRange[0]).format(appConstant.YYYY_MM_DD);
        const selectedRangeEndDate = moment(selectedRange[1]).format(appConstant.YYYY_MM_DD);

        setDatePickerExpanded(false);
        const dateRange = {
            startDate: selectedRangeStartDate,
            endDate: selectedRangeEndDate,
            namedRange: selectedNamedRanges
        };

        const filterDataWithRange = {
            ...filterData,
            dateRange
        };
        setFilterData(filterDataWithRange);
    };
    const onCancel = () => {
        setSelectedRange(initialRange);
        setSelectedNamedRanges(namedRange);
        setDatePickerExpanded(false);
    }

    return (
        <ButtonWithDropdown
            id={"date-filter-button"}
            buttonContent={displayDate}
            expanded={datePickerExpanded}
            doExpand={doExpand}
            closeOnClickOutside={true}>
            <DateRangePicker
                {...config}
                {...sidebar}
                applyButtonText={intl.formatMessage({id: 'campaigns.date.filters.apply'})}
                cancelButtonText={intl.formatMessage({id: 'campaigns.date.filters.cancel'})}
                minDate={dateFilterStartDate}
                maxDate={dateFilterEndDate}
                onApply={onApply}
                onCancel={onCancel}
                onChange={handleDateChange}
            />
        </ButtonWithDropdown>
    );
};
