import {createContext, useContext, useState} from "react";

export const UserContext = createContext({});

export const useUserContext = () => useContext(UserContext);

export const UserContextProvider = ({children}) => {
    const [userData, setUserData] = useState(null);
    const [userView, setUserView] = useState("");

    return (
        <UserContext.Provider value={{userData, setUserData, userView, setUserView}}>
            {children}
        </UserContext.Provider>
    )
}
