import React from "react";
import {BlankPageImageSection} from "@cjdev-internal/visual-stack-x/BlankPageLayout";
import {CJImage} from "@cjdev-internal/visual-stack-x/CJImage";
import "./styles/BlankPageImage.css"

const BlankPageImage = ({imageTitle, imageSrc, imageDescription}) => {
    return (
        <BlankPageImageSection
            heading={imageTitle}
            illustration={<CJImage src={imageSrc} alt={imageTitle}/>}
        >
            {imageDescription}
        </BlankPageImageSection>
    );
}
export default BlankPageImage;