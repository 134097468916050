import * as R from "ramda";
import {logout} from "@platform/platform-auth";
import {getBearerToken} from "./util";
import {ApolloClient, createHttpLink, from, InMemoryCache, split} from "@apollo/client";
import {onError} from "@apollo/client/link/error";
import {setContext} from "@apollo/client/link/context";
import debounce from "lodash/debounce";
import {GET_REGISTERED_LINKED_ADVERTISER_QUERY} from "../constants/graphql-query-mutation";
import {BatchHttpLink} from "@apollo/client/link/batch-http";
import {TYPE_BATCH} from "../constants/appConstant";

export const getUser = async (client) => {
    const userDataAPIResponse = await client.query({query: GET_REGISTERED_LINKED_ADVERTISER_QUERY});
    const userData = userDataAPIResponse.data?.getRegisteredLinkedAdvertiserData;
    return {
        ...userData,
    };
};

export const authenticatedFetch = async (uri, options = {}) => {

    options.headers = options.headers || {};
    options.headers.Authorization = await getBearerToken();

    try {
        const fetchResponse = await fetch(uri, options);
        const fetchClone = fetchResponse.clone();
        const fetchFailure = fetchClone.status === 401;
        if (fetchFailure) {
            logout();
        }

        return fetchResponse;
    } catch (err) {
        logout();
        return;
    }
};

export const getApolloClient = () => {
    const BACKEND_API_GATEWAY_URL = process.env.REACT_APP_API_GATEWAY_URL;
    const httpLink = createHttpLink({
        uri: `${BACKEND_API_GATEWAY_URL}`
    });

    const httpBatchLink = new BatchHttpLink({
        uri: `${BACKEND_API_GATEWAY_URL}`,
        batchMax: 5, // No more than 5 operations per batch
        batchInterval: 1 // Wait no more than 20ms after first batched operation
    });

    const isBatchLinkRequired = (queryParams) => {
        return queryParams.getContext().type === TYPE_BATCH;
    }

    const link = split(isBatchLinkRequired, httpBatchLink, httpLink);

    const authLink = setContext(async (_, {headers}) => {
        const token = await getBearerToken();
        return {
            headers: {
                ...headers,
                authorization: token
            }
        }
    });

    const logoutLink = onError((error) => {
        if (error.networkError) {
            logout();
        }
    })

    return new ApolloClient({
        cache: new InMemoryCache(),
        link: from([logoutLink, authLink.concat(link)]),
    });
}

export const userIsEmployee = (user) => {
    return R.propOr(undefined, "isEmployee")(user);
};

const debouncedFunction = debounce(async (value, callback, resolve) => {
    resolve(await callback(value));
}, 300)

export const debouncedFetch = (callback, value) => {
    return new Promise(resolve => {
        debouncedFunction(value, callback, resolve)
    });
}

export const getPublishers = (fetchPublishersBySearchTerm) => {
    return async (publisherSearchTerm, selectedAdvertiserCids) => {
        const response = await fetchPublishersBySearchTerm(publisherSearchTerm, selectedAdvertiserCids);
        return response;
    }
}

export const fetchPublishersBySearchTerm = (authenticatedFetch, sortAndRemoveDuplicates) => {
    const REACT_APP_PUBLISHER_API = process.env.REACT_APP_PUBLISHER_API;
    return async (publisherSearchTerm, selectedAdvertiserCid) => {
        const regexForPublisherCid = /^\d+$/
        if (regexForPublisherCid.test(publisherSearchTerm)) {
            const response = await authenticatedFetch(`${REACT_APP_PUBLISHER_API}/${publisherSearchTerm}`)
            const jsonResponse = response.status === 404 ? [] : [await response.json()]
            return jsonResponse
        } else {
            const promises = selectedAdvertiserCid.map((cid) => authenticatedFetch(`${REACT_APP_PUBLISHER_API}?nameContains=${publisherSearchTerm}&everJoinedWith=${cid}`))
            const results = await Promise.all(promises);
            const json = await Promise.all(results.map(r => r.json()));
            const publishers = json.flat(2);
            return publishers.length > 0 ? sortAndRemoveDuplicates(publishers) : [];
        }
    }
}

export const sortAndRemoveDuplicates = (inputArray) => {
    const jsonObject = inputArray.map(JSON.stringify);
    const uniqueSet = new Set(jsonObject);
    const uniqueArray = Array.from(uniqueSet).map(JSON.parse);

    const compare = (a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
    const sortedArray = uniqueArray.sort(compare);
    return sortedArray;
}