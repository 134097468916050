import {MDIcon} from "@cjdev-internal/visual-stack-x/MDIcon";

const ValidationIcon = ({data}) => {
    const {isValid} = data;
    const Success = () => {
        return (
            <div id='successIcon'><MDIcon icon="check-circle-outline" color={'var(--color-icon-success)'} size={16}
                                          inline={true}/>
            </div>)
    }
    const Error = () => {
        return (
            <div id='warningIcon'><MDIcon icon="alert-circle-outline" color={'var(--color-icon-critical)'} size={16}
                                          inline={true}/>
            </div>)
    }
    const Warning = () => {
        return (
            <div id='alertIcon'><MDIcon icon="alert-outline" color={'var(--color-icon-alert)'} size={16} inline={true}/>
            </div>)
    }
    return <>{
        isValid === null ? <Warning/> : (isValid ? <Success/> : <Error/>)
    }</>
}

export default ValidationIcon;