import {FormModalWithOptions, useNavigate} from "@platform/cj-platform-navigation";
import {cloneElement, useEffect, useState} from "react";
import {useApolloClient} from "@apollo/client";
import {CALCULATE_BASELINE_QUERY, CREATE_PLACEMENT_MUTATION} from "../../constants/graphql-query-mutation";
import {useBulkUploadContext} from "../BulkUploadContext/BulkUploadContext";
import * as appConstant from "../../constants/appConstant";
import {SAVE_PROCESSING_MODAL, SAVE_SUMMARY_MODAL} from "./BulkUploadConstant";
import {useSingleFileUpload} from "@cjdev-internal/visual-stack-x/SingleFileUpload";
import {useIntl} from "../../useIntl";

const BulkUpload = ({
                        children,
                        placementSubmitAction,
                        modalMount,
                        closeModal,
                        openModalDialog,
                        showTopToast
                    }) => {
    const [isOpen, setIsOpen] = useState(true)
    const [isDisable, setIsDisable] = useState(true);
    const [isSavingCompleted, setIsSavingCompleted] = useState(false);
    const navigate = useNavigate();
    const {bulkUploadDetails, setBulkUploadDetails, errorDetails, setWarningDetails} = useBulkUploadContext();
    const {file, onChange, onClear, inputRef} = useSingleFileUpload();


    const navigateToHome = (path) => {
        navigate(path);
    }

    const closeFormModal = () => {
        setIsOpen(false)
        navigateToHome("/");
    }

    const client = useApolloClient();
    const intl = useIntl();

    useEffect(() => {
        if (bulkUploadDetails.rows.length > 0 && isSavingCompleted) {
            if (bulkUploadDetails.failedRows.length > 0) {
                openModalDialog(SAVE_SUMMARY_MODAL);
            } else {
                const rows = bulkUploadDetails.rows;
                setBulkUploadDetails(() => ({
                    rows: [],
                    successfulRows: [],
                    failedRows: []
                }));
                let successMessage = intl.formatMessage({id: "campaigns.bulkupload.upload.placement.alert.success"})
                successMessage = successMessage.replace('${rows.length}', `${rows.length}`)
                showTopToast({
                    message: successMessage,
                    contentStyle: {textAlign: "center", minWidth: "300px"},
                    type: 'success',
                    duration: '5000',
                    dismissible: true
                });
                onClear();
            }
            setIsSavingCompleted(false)
        }
    }, [isSavingCompleted])

    const onSubmitHandler = async () => {
        setWarningDetails([]);
        openModalDialog(SAVE_PROCESSING_MODAL);
        for (const row of bulkUploadDetails.rows) {
            const placementRow = row;
            const mutationHandler = ({variables}) => {
                return client.mutate({
                    mutation: CREATE_PLACEMENT_MUTATION,
                    variables: variables
                })
                    .then(placementSaveResponse => {
                        const responseMessage = placementSaveResponse.data.createPlacementDetails.message;
                        if (responseMessage.includes(appConstant.CREATE_MODAL_SUCCESS_MESSAGE_TO_COMPARE)) {
                            setBulkUploadDetails((prevState) => ({
                                ...prevState,
                                successfulRows: [...prevState.successfulRows, row]
                            }));
                        } else if (responseMessage.includes(appConstant.CREATE_MODAL_ERROR_MESSAGE_TO_COMPARE)) {
                            row.isValid = null;
                            setBulkUploadDetails((prevState) => ({
                                ...prevState,
                                failedRows: [...prevState.failedRows, row]
                            }));
                        }
                    })
                    .catch(() => {
                        row.isValid = null;
                        setBulkUploadDetails((prevState) => ({
                            ...prevState,
                            failedRows: [...prevState.failedRows, row]
                        }));
                    });
            }
            await placementSubmitAction(placementRow, mutationHandler, true);
        }
        closeModal();
        setIsSavingCompleted(true);
        await client.query({
            query: CALCULATE_BASELINE_QUERY
        })
    }

    useEffect(() => {
        setIsDisable(!(errorDetails?.length === 0 && bulkUploadDetails.rows.length > 0));
    }, [errorDetails, bulkUploadDetails.rows]);

    return (
        <div id={"formModalContainer"}>
            <FormModalWithOptions
                id={"bulkUploadContainer"}
                isOpen={isOpen}
                onRequestClose={closeFormModal}
                onSubmit={onSubmitHandler}
                submitText={intl.formatMessage({id: "campaigns.bulkupload.upload.placement.button"})}
                title={intl.formatMessage({id: "campaigns.bulkupload.bulk.upload.title"})}
                disableSubmit={isDisable}
                gap="medium"
            >
                {modalMount}
                {cloneElement(children, {onClear, onChange, file, inputRef})}
            </FormModalWithOptions>
        </div>
    )
}

export default BulkUpload;
