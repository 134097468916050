import React, {useEffect, useState} from "react";
import {useApolloClient, useQuery} from "@apollo/client";
import {GET_PLACEMENT_DATA} from "./constants/graphql-query-mutation";
import {useCampaignsContext} from "./components/CampaignsContext/useCampaignsContext";
import {Box} from "@cjdev-internal/visual-stack-x/Box";
import {LoadingAnimation} from "@cjdev-internal/visual-stack-x/LoadingAnimation";

const MediaTrackerAppNewNav = ({
                                   CampaignsDataTable,
                                   columns,
                                   openModalDialog,
                                   searchPanel,
                                   initializePendo,
                                   getUser,
                                   getCompaniesDetails,
                                   getNonEmptyVariables,
                               }) => {

    const [userData, setUserData] = useState();
    const [userProfileData, setUserProfileData] = useState([]);

    const {filterData} = useCampaignsContext();

    const client = useApolloClient()
    const loadUserData = async () => {
        const userprofile = await getUser(client);
        setUserProfileData(userprofile);
        setUserData(userprofile.companies);
    };
    const companiesName = getCompaniesDetails(userData);
    let advertiserList = [];

    if (companiesName !== undefined && companiesName.length > 0) {
        let advertiserFilterSelectionSet = new Set(filterData.advertisers.map(item => item.id));
        companiesName.forEach(companyName => {
            if (advertiserFilterSelectionSet.has(parseInt(companyName[0]))) {
                advertiserList.push(companyName[0])
            }
        })
    }

    const initPendo = async () => {
        return initializePendo(userProfileData.emailAddress, userProfileData.isEmployee, advertiserList);
    };
    const publisherList = filterData.publishers;

    const isCallToSkip = advertiserList.length === 0;
    const graphQLVariables = getNonEmptyVariables({
            advertiserCompanyId: advertiserList,
            publisherCompanyId: publisherList,
            status: filterData.status,
            dateRange: {
                startDate: filterData.dateRange?.startDate, endDate: filterData.dateRange?.endDate
            }

        })
    ;
    const getPlacementDataHandler = useQuery(GET_PLACEMENT_DATA, {
        variables: graphQLVariables,
        fetchPolicy: 'cache-and-network',
        skip: isCallToSkip
    });

    useEffect(() => {
        loadUserData();
    }, [])

    useEffect(() => {
        initPendo();
    }, [advertiserList, userProfileData])


    let data = getPlacementDataHandler.data;
    let loading = getPlacementDataHandler.loading;

    return (
        <div>
            {
                (loading === true || companiesName === undefined) ?
                    (<Box direction="row" justify="center" className="loadingAnimationBox">
                        <LoadingAnimation/>
                    </Box>) : (data && data.getPlacementData) ?
                        <CampaignsDataTable columns={columns} openModalDialog={openModalDialog} searchPanel={searchPanel}
                                            values={data.getPlacementData}/> : ""
            }
        </div>
    );
};

export default MediaTrackerAppNewNav;
