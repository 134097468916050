import React, {useEffect, useState} from "react";
import {useIntl} from "../../useIntl";
import {Box} from "@cjdev-internal/visual-stack-x/Box";
import {Text} from "@cjdev-internal/visual-stack-x/Text";
import CampaignsDropDown from "../CreateRecord/CampaignsDropDown";
import {Label} from "@cjdev-internal/visual-stack-x/legacy/Form";
import * as momentTimeZone from "moment-timezone";
import "./styles/RegisterAdvertiserModal.css"
import {Note} from "@cjdev-internal/visual-stack-x/Note";
import {CREATE_MODAL_SUCCESS_MESSAGE_TO_COMPARE, NOTE_TYPE} from "../../constants/appConstant";
import {useUserContext} from "../../context/UserContextProvider";
import {useMutation} from "@apollo/client";
import {REGISTER_ADVERTISER_MUTATION,} from "../../constants/graphql-query-mutation";
import {Stack} from "@cjdev-internal/visual-stack-x/Stack";
import {ActionModal} from "@cjdev-internal/visual-stack-x/ActionModal";
import {Button} from "@cjdev-internal/visual-stack-x/Button";
import {Row} from "@cjdev-internal/visual-stack-x/Row";

const RegisterAdvertiserModal = ({
                                     showTopToast, setRegisterAdvertiserModal,
                                     setShowBlankSlateNavigationModal
                                 }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [type, setType] = useState("custom")

    useEffect(() => {
        setIsOpen(true)
    }, [])


    const closeActionModal = () => {
        setRegisterAdvertiserModal(false);
        setIsOpen(false)
    }

    const intl = useIntl();
    const timezoneDropdown = [];
    const {userData} = useUserContext();
    const [selectedTimeZone, setSelectedTimeZone] = useState('');
    const [loadingAnimation, setLoadingAnimation] = useState(false);
    const [errorData, setErrorData] = useState({value: false})

    const timezones = momentTimeZone.tz.names();
    timezones.map((timezone) => {
        const label = `${timezone} (${momentTimeZone.tz(timezone).format("z")})`
        timezoneDropdown.push({label: label, value: timezone})
    })

    const [registerAdvertiser, {data, loading}] = useMutation(REGISTER_ADVERTISER_MUTATION)

    const saveAdvertiseTimeZone = () => {
        if (selectedTimeZone) {
            setLoadingAnimation(true);
            registerAdvertiser({
                variables: {
                    advertiserCId: userData.currentCompany.id,
                    timeZone: selectedTimeZone,
                    advertiserName: userData.currentCompany.name,
                    currency: userData.currentCompany.functionalCurrency
                }
            })
        } else {
            setErrorData({value: true});
        }
    }

    useEffect(() => {
        if (data) {
            const {message} = data.createOrUpdateAdvertiserSettings;
            setLoadingAnimation(false);
            if (message.includes(CREATE_MODAL_SUCCESS_MESSAGE_TO_COMPARE)) {
                showTopToast({
                    message: intl.formatMessage({id: 'campaigns.register.advertiser.save.timezone.success'}),
                    contentStyle: {textAlign: "center", minWidth: "300px"},
                    type: 'success',
                    duration: '5000',
                    dismissible: false
                });
                setIsOpen(false);
                setShowBlankSlateNavigationModal(true);
            } else {
                showTopToast({
                    message: intl.formatMessage({id: 'campaigns.register.advertiser.save.timezone.failure'}),
                    contentStyle: {textAlign: "center", minWidth: "300px"},
                    type: 'warning',
                });
            }
            setRegisterAdvertiserModal(false);
        }
    }, [loading])
    const handleChange = (id, value) => {
        if (value) {
            setErrorData({value: false})
        }
        setSelectedTimeZone(value)
    };

    return (
        <Stack gap="medium">
            <ActionModal id="registerAdvertiserModal"
                         isOpen={isOpen}
                         type={type}
                         footer={<Row gap="medium" justify={"end"}>
                             <Button id='cancel-button-ra' type='tertiary'
                                     disabled={loadingAnimation}
                                     onClick={closeActionModal}>
                                 {intl.formatMessage({id: "campaigns.cancel"})}</Button>
                             <Button id='save-button-ra' type='primary' spinner={loadingAnimation}
                                     disabled={loadingAnimation} onClick={saveAdvertiseTimeZone}>
                                 {intl.formatMessage({id: 'campaigns.save.and.continue'})}</Button>
                         </Row>}
                         headerText={intl.formatMessage({id: 'campaigns.register.advertiser.modal.title'})}
                         onRequestClose={closeActionModal}
                         shouldCloseOnOverlayClick={false}
                         submitButtonText={intl.formatMessage({id: 'campaigns.save.and.continue'})}
                         cancelButtonText={intl.formatMessage({id: "campaigns.cancel"})}
                         height="360px"
                         width="500px"
            >
                <Box gap={'small'}>
                    <Box direction="column" gap="xl">
                        <Text size={15}>
                            {intl.formatMessage({id: 'campaigns.register.advertiser.timezone.info'})}
                        </Text>
                    </Box>
                    <Box direction={"column"} gap={"small"}></Box>
                    <Box direction="column">
                        <Label required>
                            {intl.formatMessage({id: "campaigns.register.advertiser.select.timezone.label"})}
                        </Label>
                        <CampaignsDropDown id='advertiserTimezone'
                                           handleChange={handleChange}
                                           optionsValue={timezoneDropdown}
                                           errorData={errorData}
                                           menuTarget={document.body}/>
                    </Box>
                    <Box direction="column" className={"timezone-warning"}>
                        <Note type={NOTE_TYPE}>
                            {intl.formatMessage({id: "campaigns.register.advertiser.select.timezone.warning"})}
                        </Note>
                    </Box>
                </Box>
            </ActionModal>
        </Stack>
    )
}

export default RegisterAdvertiserModal;
