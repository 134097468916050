import {GET_BASELINE_DETAIL_QUERY} from "../../../constants/graphql-query-mutation";
import {TYPE_BATCH} from "../../../constants/appConstant";

const getBaselineDetails = (client, formatMessage) => {

    return (row, warningList, rowIndex) => {
        if (row.performanceDetails.baselineValue.isBaselineCalculatedEnValue === 'Yes') {
            return client.query({
                query: GET_BASELINE_DETAIL_QUERY, variables: {
                    advertiserCompanyId: row.advertiserCompanyId?.value,
                    publisherCompanyId: row.pid.publisherCompanyId,
                    publisherPropertyId: row.pid.publisherPropertyId,
                    startDate: row.pStartDate
                }, fetchPolicy: 'cache-first', context: {type: TYPE_BATCH}
            })
                .then(getBaselineDetailsResponse => {
                    let averageDailyRevenue = getBaselineDetailsResponse.data.getBaselineDetails.averagedailyrevenue;
                    row.performanceDetails.baselineValue.averageBaselineRevenue =
                        averageDailyRevenue === -1 ? "0" : averageDailyRevenue.toString();
                    if (row.performanceDetails.baselineValue.averageBaselineRevenue === "0") {
                        warningList[rowIndex].push(formatMessage({id: "campaigns.bulkupload.baseline.warning.details"}));
                    }
                });
        } else {
            const manualAverageDailyRevenue = row.performanceDetails.baselineValue.averageBaselineRevenue;
            if (manualAverageDailyRevenue === "0") {
                warningList[rowIndex].push(formatMessage({id: "campaigns.bulkupload.baseline.warning.details"}));
            }
        }
    }
}
export default getBaselineDetails;
