import Pendo from "@cjdev-internal/pendo";
import {getUserId} from '@platform/platform-auth'

export const initializePendo = async (email, isInternal, advertiserList) => {
    const env = process.env.NODE_ENV;
    try {
        if (email !== undefined && email !== null) {
            const userId = await getUserId();
            Pendo.install(env).initialize(userId, email, isInternal).updateViewingCompanies(advertiserList);
        }
    } catch (e) {
        console.log("Unable to send data to Pendo: " + e);
    }
};

