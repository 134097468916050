import {DataGridEnterprise} from "@cjdev-internal/visual-stack-x/DataGridEnterprise";
import "./style/CampaignsDataTable.css";
import {useCampaignsContext} from "../CampaignsContext/useCampaignsContext";
import * as appConstant from "../../constants/appConstant";
import {useCallback, useMemo} from "react";
import {FlexPage} from "@cjdev-internal/visual-stack-x/FlexPage";
import {useIntl} from "@platform/cj-platform-navigation";

const CampaignsDataTable = ({columns, openModalDialog, searchPanel, values}) => {
    const {
        campaignsDataGridState,
        setCampaignsDataGridState
    } = useCampaignsContext();

    const intl = useIntl();
    const initColumns = () => {
        const columnsFromSession = JSON.parse(sessionStorage.getItem("CampaignsDataTableState"));
        if (columnsFromSession !== null && columnsFromSession !== undefined) {
            const updatedColumns = columns.map(column => {
                const searchColumn = columnsFromSession.find(sessionColumn => sessionColumn.colId === column.field);
                if (searchColumn) {
                    return {...column, hide: searchColumn.hide};
                } else {
                    return column;
                }
            });
            const columnSessionMap = {};
            columnsFromSession.forEach((obj, index) => {
                columnSessionMap[obj.colId] = index;
            });

            updatedColumns.sort((a, b) => {
                const previousIndex = columnSessionMap[a.field];
                const postIndex = columnSessionMap[b.field];
                return previousIndex - postIndex;
            });
            return updatedColumns;
        } else {
            sessionStorage.setItem("CampaignsDataTableState", JSON.stringify(columns));
        }
        return columns;
    };

    const defaultColDef = {
        filterParams: {
            buttons: ['reset', 'apply'],
        },
        sortingOrder: ['asc', 'desc'],
        resizable: true,
        wrapHeaderText: true,
        autoHeaderHeight: true
    };

    const rowActions = useMemo(() => [
        {
            label: intl.formatMessage({id: "campaigns.edit.placement"}),
            onClick: ({rowData}) => openModalDialog(appConstant.PLACEMENT_MODE_EDIT, rowData)
        },
        {
            label: intl.formatMessage({id: "campaigns.duplicate.placement"}),
            onClick: ({rowData}) => openModalDialog(appConstant.PLACEMENT_MODE_DUPLICATE, rowData)
        },
        {
            label: intl.formatMessage({id: "campaigns.remove.placement"}),
            onClick: ({rowData}) => openModalDialog(appConstant.PLACEMENT_MODE_REMOVE, rowData)
        }
    ], [openModalDialog]);

    const saveStateToSessionStorage = (e) => {
        const columnState = e.columnApi.getColumnState();
        const quickFilterValue = e.api.getFilterModel();
        const rowsPerPage = e.api.paginationGetPageSize();
        sessionStorage.setItem("CampaignsDataTableState", JSON.stringify(columnState));
        sessionStorage.setItem("CampaignsDataTableQuickFilterValue", JSON.stringify(quickFilterValue));
        sessionStorage.setItem("CampaignsDataTableRowsPerPage", JSON.stringify(rowsPerPage));
    }

    const getStateFromSessionStorage = () => {
        const jsonState = sessionStorage.getItem("CampaignsDataTableState")
        if (jsonState) {
            return JSON.parse(jsonState)
        }
        return null
    }

    const onPaginationChange = useCallback((e) => {
        const oldRowsPerPage = campaignsDataGridState.rowsPerPage;
        const rowsPerPage = e.api.paginationGetPageSize();
        if (oldRowsPerPage !== rowsPerPage) {
            setCampaignsDataGridState(prevState => ({...prevState, rowsPerPage}));
        }
    }, [campaignsDataGridState, setCampaignsDataGridState]);

    return (
        <div className={"campaign-datatable"}>
            <FlexPage heightAdjust="32px">
                <DataGridEnterprise
                    rowData={values}
                    columnDefs={initColumns()}
                    getRowId={(data) => data.id}
                    EXPLICIT_ENTERPRISE_AUTH_DO_NOT_USE_WITHOUT_PERMISSION={true}
                    suppressContextMenu={true} //to suppress context menu of copy,excel download
                    rowActions={rowActions}
                    onColumnVisible={saveStateToSessionStorage}
                    onSortChanged={saveStateToSessionStorage}
                    onColumnMoved={saveStateToSessionStorage}
                    onColumnPinned={saveStateToSessionStorage}
                    onFilterChanged={saveStateToSessionStorage}
                    onPaginationChanged={onPaginationChange}
                    defaultColDef={defaultColDef}
                    pagination
                    quickFilterText={campaignsDataGridState.quickFilterValue}
                    paginationPageSize={campaignsDataGridState.rowsPerPage}
                    onGridReady={(params) => {
                        params.api.setFilterModel(
                            JSON.parse(sessionStorage.getItem("CampaignsDataTableQuickFilterValue"))
                        );

                        const state = getStateFromSessionStorage()
                        if (state) {
                            params.columnApi.applyColumnState({
                                state: state,
                                applyOrder: true,
                            })
                        }
                    }}
                    topPanel={searchPanel}
                    title={intl.formatMessage({id: "campaigns.datatable.title"})}
                    caption={intl.formatMessage({id: "campaigns.datatable.title.description"})}
                    licenseKey={appConstant.DATATABLE_LICENSE_KEY}
                    intl={intl}
                />
            </FlexPage>
        </div>
    )
}
export default CampaignsDataTable;