import {IconButton} from "@cjdev-internal/visual-stack-x/Button";
import moment from "moment";
import * as BulkUploadConstant from "./BulkUploadConstant";
import {FAILED_PLACEMENT_SHEETNAME} from "./BulkUploadConstant";

const DownloadContentIcon = ({api, downloadTemplateAndFailedPlacement, bulkUploadDataTableColumns}) => {
    const downloadFailedPlacements = () => {
        const csvData = api.getDataAsCsv({
            skipColumnHeaders: true
        });

        const dateTime = moment().format("YYYY-MM-DD_HH-mm-ss");
        const fileName = BulkUploadConstant.FAILED_PLACEMENT_FILENAME.replace('{timeStamp}', dateTime);
        downloadTemplateAndFailedPlacement(csvData, fileName, FAILED_PLACEMENT_SHEETNAME, bulkUploadDataTableColumns);
    };

    return (
        <div>
            <IconButton id="download-icon" onClick={downloadFailedPlacements} icon="download" outline/>
        </div>
    );
};

export default DownloadContentIcon;