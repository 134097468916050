import {Stack} from '@cjdev-internal/visual-stack-x/Stack';
import {Text} from "@cjdev-internal/visual-stack-x/Text";
import React from "react";
import {useUserContext} from "../../context/UserContextProvider";
import {AUTHORIZED_VIEW} from "../../constants/appConstant";
import {useIntl} from "../../useIntl";

const Dimensions = ({DateFilter}) => {
    const {userView} = useUserContext();
    const intl = useIntl();
    return userView === AUTHORIZED_VIEW ? (<Stack gap="medium">
        <Text color="primary" size={13} weight="medium"
              className="margin-top-5">{intl.formatMessage({id: 'campaigns.placement.run.date'})}</Text>
        <DateFilter/>
    </Stack>) : false
};

export default Dimensions;
