import React, {useEffect, useState} from "react";
import "./styles/BulkUploadAndDownloadDropdown.css";
import * as appConstant from "../../constants/appConstant";
import {useCampaignsContext} from "../CampaignsContext/useCampaignsContext";
import {useLazyQuery} from "@apollo/client";
import {GET_EXCEL_DOWNLOAD_URL} from "../../constants/graphql-query-mutation";
import {IconButtonWithDropdown} from "@cjdev-internal/visual-stack-x/ButtonWithDropdown";
import {IconButton} from "@cjdev-internal/visual-stack-x/Button";
import {useIntl, useNavigate} from "@platform/cj-platform-navigation";
import {useToast} from "@cjdev-internal/visual-stack-x/Toast";

export const BulkUploadAndDownloadDropdown = ({util}) => {
    const BulkUploadAndDownloadDropdownWrapper = () => {
        const intl = useIntl();
        const {filterData} = useCampaignsContext();
        const navigate = useNavigate();
        const [mountTopToast, showTopToast] = useToast(true);

        let advertiserFilterList = [];
        let filterParams = {};

        const [iconExpanded, setIconExpanded] = useState(false);

        const {getNonEmptyVariables, filterColumnsOnSelectionBasis, getSelectedColumnsForDownload} = util;
        filterData.advertisers.map(item => advertiserFilterList.push(item.id));

        filterParams = getNonEmptyVariables({
            advertiserCompanyId: advertiserFilterList,
            publisherCompanyId: filterData.publishers,
            status: filterData.status,
            dateRange: {startDate: filterData.dateRange?.startDate, endDate: filterData.dateRange?.endDate}
        });

        const getExcelAPIParams = (tabFormat) => {
            const selectedColumnsInGrid = JSON.parse(sessionStorage.getItem("CampaignsDataTableState")).filter(col => !col.hide).map(col => col.colId);
            const getSheetDetails = (sheetName, sheetColumns, mandatoryExcelColumns) => {
                const selectedColumnsForDownload = getSelectedColumnsForDownload(selectedColumnsInGrid);
                const filteredColumns = filterColumnsOnSelectionBasis(sheetColumns, selectedColumnsForDownload)
                return {
                    SheetName: sheetName,
                    ColumnDetails: [...filteredColumns, ...mandatoryExcelColumns]
                }
            }
            if (tabFormat === 'singleTab') {
                const singleSheetParams = {
                    variables: {
                        ExcelSheet1: getSheetDetails(appConstant.EXCEL_SINGLE_SHEET_NAME, appConstant.EXCEL_SINGLE_SHEET_COLUMNS, appConstant.SINGLE_SHEET_MANDATORY_EXCEL_COLUMNS),
                        Filter: filterParams
                    }
                }
                return singleSheetParams;
            }
            if (tabFormat === 'multiTab') {
                const multiSheetParams = {
                    variables: {
                        ExcelSheet1: getSheetDetails(appConstant.EXCEL_MULTI_SHEET1_NAME, appConstant.EXCEL_MULTI_SHEET1_COLUMNS, appConstant.MULTI_SHEET1_MANDATORY_EXCEL_COLUMNS),
                        ExcelSheet2: getSheetDetails(appConstant.EXCEL_MULTI_SHEET2_NAME, appConstant.EXCEL_MULTI_SHEET2_COLUMNS, appConstant.MULTI_SHEET2_MANDATORY_EXCEL_COLUMNS),
                        ExcelSheet3: getSheetDetails(appConstant.EXCEL_MULTI_SHEET3_NAME, appConstant.EXCEL_MULTI_SHEET3_COLUMNS, appConstant.MULTI_SHEET3_MANDATORY_EXCEL_COLUMNS),
                        Filter: filterParams
                    }
                }
                return multiSheetParams;
            }
        }

        const getExcelDownloadHandler = (TabFormat) => {
            return () => {
                const excelParams = getExcelAPIParams(TabFormat);
                getUrl(excelParams)
                setIconExpanded(!iconExpanded);
            }
        }

        const navigateToBulkUpload = () => {
            navigate(appConstant.ROUTE_BULK_UPLOAD);
        };

        const [getUrl, {loading, data, error}] = useLazyQuery(GET_EXCEL_DOWNLOAD_URL, {
            fetchPolicy: 'no-cache'
        });

        useEffect(() => {
            if ((!loading && data?.getExcelDownloadUrl)) {
                window.location = data.getExcelDownloadUrl.url;
            } else if (!loading && error) {
                showTopToast({
                    message: intl.formatMessage({id: "campaigns.excel.download.error.message"}),
                    contentStyle: {textAlign: "center", minWidth: "300px"},
                    type: 'warning',
                    dismissible: true
                });
                console.error(error.message);
            }
        }, [data, error]);

        return (
            <>
                {mountTopToast}
                <div className="bulkUploadAndDownloadContainer">
                    {loading ?
                        <IconButton icon="loading" animation="spin"/>
                        :
                        <>
                            <IconButtonWithDropdown icon="dots-horizontal" buttonContent="Dropdown Menu"
                                                    expanded={iconExpanded} closeOnClickOutside={true}
                                                    doExpand={setIconExpanded}>
                                <ul>
                                    <li key='bulkUpload' id='bulkUploadButton' onClick={navigateToBulkUpload}>
                                        {intl.formatMessage({id: "campaigns.bulkupload.bulk.upload.title"})}
                                    </li>
                                    <li key='multiTabExcel' id='multiTabExcelButton'
                                        onClick={getExcelDownloadHandler('multiTab')}>
                                        {intl.formatMessage({id: "campaigns.new.nav.multi.tab.download"})}
                                    </li>
                                    <li key='singleTabExcel' id='singleTabExcelButton'
                                        onClick={getExcelDownloadHandler('singleTab')}>
                                        {intl.formatMessage({id: "campaigns.new.nav.single.tab.download"})}
                                    </li>
                                </ul>
                            </IconButtonWithDropdown>
                        </>
                    }
                </div>
            </>
        )
    }

    const OpenModalButton = (BulkUploadAndDownloadDropdownWrapper);
    return (
        <>
            <OpenModalButton/>
        </>
    );
}
