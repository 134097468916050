import {ExpandingInputButton} from "@cjdev-internal/visual-stack-x/ExpandingInputButton";
import {useCampaignsContext} from "../CampaignsContext/useCampaignsContext";
import {useEffect, useState} from "react";

const SearchPanel = ({api}) => {
    const {campaignsDataGridState, setCampaignsDataGridState} = useCampaignsContext();
    const useDebounce = (value, delay) => {
        const [debouncedValue, setDebouncedValue] = useState(value);

        useEffect(() => {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            return () => {
                clearTimeout(handler);
            }
        }, [value, delay]);

        return debouncedValue;
    }

    const [inputValue, setInputValue] = useState(campaignsDataGridState.quickFilterValue)
    const debouncedValue = useDebounce(inputValue, 500)

    useEffect(() => {
        setCampaignsDataGridState(prevState => ({...prevState, quickFilterValue: debouncedValue}));
    }, [debouncedValue, setCampaignsDataGridState])

    return (
        <div id="globalSearch">
            <ExpandingInputButton startingValue={campaignsDataGridState.quickFilterValue} onChange={setInputValue}/>
        </div>
    )
};

export default SearchPanel;
