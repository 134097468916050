import {useEffect} from "react";
import {useUserContext} from "../../context/UserContextProvider";
import * as appConstant from "../../constants/appConstant";
import {AUTHORIZED_VIEW, BLANKSLATE_VIEW, MAINTENANCE_VIEW, UNAUTHORIZED_VIEW} from "../../constants/appConstant";

const Content = ({
                     user,
                     MediaTrackerApp,
                     UnauthorizedComponent,
                     BlankSlateComponent,
                     LoadingComponent,
                     MaintenanceComponent,
                     openModalDialog
                 }) => {
    const {setUserData, userView} = useUserContext();
    useEffect(() => {
        setUserData(user);
    }, [user])

    useEffect(() => {
        const isCreateModalOpen = localStorage.getItem('isCreateModalOpen');
        if (isCreateModalOpen === 'true') {
            openModalDialog(appConstant.PLACEMENT_MODE_CREATE);
            localStorage.removeItem('isCreateModalOpen');
        }
    }, [])

    switch (userView) {
        case AUTHORIZED_VIEW:
            return MediaTrackerApp;
        case UNAUTHORIZED_VIEW:
            return UnauthorizedComponent
        case MAINTENANCE_VIEW:
            return MaintenanceComponent
        case BLANKSLATE_VIEW:
            return BlankSlateComponent
        default:
            return LoadingComponent;
    }
};

export default Content;
