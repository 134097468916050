import {Select} from "@cjdev-internal/visual-stack-x/Select";
import {useIntl} from "../../useIntl";

const CampaignsDropDown = ({id, handleChange, optionsValue, defaultValue, errorData, menuTarget}) => {
    const intl = useIntl();
    const customStyles = {
        menuPortal: (base) => ({...base, zIndex: "999999999999 !important"})
    }
    return (<Select
        id={id}
        styles={customStyles}
        menuPortalTarget={menuTarget}
        defaultValue={defaultValue}
        placeholder={intl.formatMessage({id: "campaigns.dropdown.placeholder"})}
        options={optionsValue}
        onChange={(event) => (id === "advertiserCompanyId" ? handleChange(id, event) : handleChange(id, event.value))}
        error={errorData && errorData.value ? true : false}
    />);

};
export default CampaignsDropDown;
