import {Button} from "@cjdev-internal/visual-stack-x/Button";
import * as appConstant from "../../constants/appConstant";
import React from "react";
import {useUserContext} from "../../context/UserContextProvider";
import {useIntl} from "../../useIntl";
import {useModal} from "@cjdev-internal/visual-stack-x/Modal";
import {useAlert} from "@cjdev-internal/visual-stack-x/Alert";
import {useToast} from "@cjdev-internal/visual-stack-x/Toast";


const AddPlacementButton = ({
                                util,
                                openModalDialogProvider,
                                placementModalDialog,
                                PlacementDialogSubmitAction,
                                campaignsTranslatedDropdownList
                            }) => {
    const {userData} = useUserContext();
    const [modalMount, openModal, closeModal] = useModal();
    const [alertMount, openAlert, closeAlert] = useAlert();
    const [mountTopToast, showTopToast] = useToast(true);
    const intl = useIntl();
    const modalConfiguration = {
        [appConstant.PLACEMENT_MODE_CREATE]: {
            ModalContent: placementModalDialog,
            props: {
                takeAction: PlacementDialogSubmitAction,
                showToast: showTopToast,
                placementModalTitle: intl.formatMessage({id: "campaigns.create.record.title"}),
                modeOfPlacement: appConstant.PLACEMENT_MODE_CREATE,
                campaignsTranslatedDropdownList: campaignsTranslatedDropdownList
            }
        }
    }

    const openModalDialog = openModalDialogProvider(openModal, closeModal, openAlert, closeAlert, modalConfiguration,
        util.getBearerToken, util.deSanitizeObject, userData, showTopToast);

    return (<>
        {modalMount}
        {alertMount}
        {mountTopToast}
        <Button
            id={"add-placement-button"}
            type="primary"
            onClick={() => openModalDialog(appConstant.PLACEMENT_MODE_CREATE, {})}
        >
            {intl.formatMessage({id: "campaigns.add.placement"}).toUpperCase()}
        </Button>
    </>)
}

export default AddPlacementButton;
