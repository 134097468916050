import * as appConstant from '../../constants/appConstant'
import {IntlProvider} from "@platform/cj-platform-navigation";
import {getTranslatedValue} from "../../translations";

const openModalDialogProvider = (openModal, closeModal, openAlert, closeAlert, modalConfiguration, getBearerToken,
                                 deSanitizeObject, userData, formatMessage, showBottomToast, showTopToast) => {

    return (modalType, rowData = {}) => {
        const isBulkUploadPopup = modalConfiguration[modalType]?.isBulkUploadPopup;
        let props = modalConfiguration[modalType].props;
        let deSanitizedRowData = deSanitizeObject(rowData);
        if (!isBulkUploadPopup) {
            getBearerToken();
            if (modalType === appConstant.PLACEMENT_MODE_DUPLICATE) {
                props.placementModalTitle = formatMessage({id: "campaigns.duplicate.record.title"}) + deSanitizedRowData.id;
                props.placementRecord = {
                    ...deSanitizedRowData,
                    placementname: appConstant.COPY_OF + appConstant.SPACE + deSanitizedRowData.placementname,
                    status: appConstant.PROPOSED
                }
            }
        }
        const ModalContent = modalConfiguration[modalType].ModalContent;
        const modalProps = {
            closeModal: closeModal,
            openAlert: openAlert,
            closeAlert: closeAlert,
            placementRecord: deSanitizedRowData,
            showBottomToast: showBottomToast,
            showTopToast: showTopToast,
            ...props
        }
        openModal(<IntlProvider locale={userData.language} messages={getTranslatedValue(userData.language)}>
            <ModalContent {...modalProps}/>
        </IntlProvider>);
    }
}

export default openModalDialogProvider;
