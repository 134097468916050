const BulkUploadTemplateTranslations = (formatMessage) => {
    return {
        FIELD: formatMessage({id: 'campaigns.bulkupload.template.field'}),
        TEMPLATE_ROW_ONE: [formatMessage({id: 'campaigns.bulkupload.template.description'})],

        TEMPLATE_ROW_THREE: [formatMessage({id: 'campaigns.bulkupload.template.category'}), formatMessage({id: 'campaigns.bulkupload.template.category.details'}), formatMessage({id: 'campaigns.bulkupload.template.category.details'}), formatMessage({id: 'campaigns.bulkupload.template.category.details'}), formatMessage({id: 'campaigns.bulkupload.template.category.details'}), formatMessage({id: 'campaigns.bulkupload.template.category.details'}), formatMessage({id: 'campaigns.bulkupload.template.category.details'}), formatMessage({id: 'campaigns.bulkupload.template.category.details'}), formatMessage({id: 'campaigns.bulkupload.template.category.details'}), formatMessage({id: 'campaigns.bulkupload.template.category.details'}), formatMessage({id: 'campaigns.bulkupload.template.category.details'}), formatMessage({id: 'campaigns.bulkupload.template.category.details'}), formatMessage({id: 'campaigns.bulkupload.template.category.details'}), formatMessage({id: 'campaigns.bulkupload.template.category.details'}), formatMessage({id: 'campaigns.bulkupload.template.category.details'}), formatMessage({id: 'campaigns.bulkupload.template.category.details'}), formatMessage({id: 'campaigns.bulkupload.template.category.details'}), formatMessage({id: 'campaigns.bulkupload.template.category.details'}), formatMessage({id: 'campaigns.bulkupload.template.category.details'}), formatMessage({id: 'campaigns.bulkupload.template.category.details'}),
            formatMessage({id: 'campaigns.bulkupload.template.category.cost.proj.lift'}), formatMessage({id: 'campaigns.bulkupload.template.category.cost.proj.lift'}), formatMessage({id: 'campaigns.bulkupload.template.category.cost.proj.lift'}), formatMessage({id: 'campaigns.bulkupload.template.category.cost.proj.lift'}), formatMessage({id: 'campaigns.bulkupload.template.category.cost.proj.lift'}), formatMessage({id: 'campaigns.bulkupload.template.category.cost.proj.lift'}), formatMessage({id: 'campaigns.bulkupload.template.category.cost.proj.lift'}), formatMessage({id: 'campaigns.bulkupload.template.category.cost.proj.lift'}), formatMessage({id: 'campaigns.bulkupload.template.category.cost.proj.lift'}), formatMessage({id: 'campaigns.bulkupload.template.category.cost.proj.lift'}), formatMessage({id: 'campaigns.bulkupload.template.category.cost.proj.lift'}), formatMessage({id: 'campaigns.bulkupload.template.category.cost.proj.lift'}),
            formatMessage({id: 'campaigns.bulkupload.template.category.booking'}), formatMessage({id: 'campaigns.bulkupload.template.category.booking'}), formatMessage({id: 'campaigns.bulkupload.template.category.booking'}), formatMessage({id: 'campaigns.bulkupload.template.category.booking'}), formatMessage({id: 'campaigns.bulkupload.template.category.booking'}), formatMessage({id: 'campaigns.bulkupload.template.category.booking'}), formatMessage({id: 'campaigns.bulkupload.template.category.booking'}), formatMessage({id: 'campaigns.bulkupload.template.category.booking'}), formatMessage({id: 'campaigns.bulkupload.template.category.booking'}), formatMessage({id: 'campaigns.bulkupload.template.category.booking'}), formatMessage({id: 'campaigns.bulkupload.template.category.booking'}), formatMessage({id: 'campaigns.bulkupload.template.category.booking'}), formatMessage({id: 'campaigns.bulkupload.template.category.booking'}), formatMessage({id: 'campaigns.bulkupload.template.category.booking'}), formatMessage({id: 'campaigns.bulkupload.template.category.booking'}), formatMessage({id: 'campaigns.bulkupload.template.category.booking'}), formatMessage({id: 'campaigns.bulkupload.template.category.booking'}),
            formatMessage({id: 'campaigns.bulkupload.template.category.validation'}), formatMessage({id: 'campaigns.bulkupload.template.category.validation'}), formatMessage({id: 'campaigns.bulkupload.template.category.validation'}), formatMessage({id: 'campaigns.bulkupload.template.category.validation'}), formatMessage({id: 'campaigns.bulkupload.template.category.validation'}),
            formatMessage({id: 'campaigns.bulkupload.template.category.payment'}), formatMessage({id: 'campaigns.bulkupload.template.category.payment'}), formatMessage({id: 'campaigns.bulkupload.template.category.payment'}), formatMessage({id: 'campaigns.bulkupload.template.category.payment'}), formatMessage({id: 'campaigns.bulkupload.template.category.payment'})],

        TEMPLATE_ROW_FOUR: [formatMessage({id: 'campaigns.bulkupload.template.definition'}), formatMessage({id: 'campaigns.bulkupload.template.placement.status.definition'}), formatMessage({id: 'campaigns.bulkupload.template.advertiser.cid.definition'}), formatMessage({id: 'campaigns.bulkupload.template.publisher.pid.definition'}), formatMessage({id: 'campaigns.bulkupload.template.start.date.time.definition'}),
            formatMessage({id: 'campaigns.bulkupload.template.end.date.time.definition'}), formatMessage({id: 'campaigns.bulkupload.template.fiscal.year.definition'}), formatMessage({id: 'campaigns.bulkupload.template.fiscal.quarter.definition'}), formatMessage({id: 'campaigns.bulkupload.template.fiscal.month.definition'}), formatMessage({id: 'campaigns.bulkupload.template.fiscal.week.definition'}),
            formatMessage({id: 'campaigns.bulkupload.template.placement.name.definition'}), formatMessage({id: 'campaigns.bulkupload.template.placement.type.definition'}), formatMessage({id: 'campaigns.bulkupload.template.dynamic.static.definition'}), formatMessage({id: 'campaigns.bulkupload.template.placement.targeted.definition'}), formatMessage({id: 'campaigns.bulkupload.template.new.or.repeated.definition'}),
            formatMessage({id: 'campaigns.bulkupload.template.vip.content.definition'}), formatMessage({id: 'campaigns.bulkupload.template.events.definition'}), formatMessage({id: 'campaigns.bulkupload.template.package.name.definition'}), formatMessage({id: 'campaigns.bulkupload.template.placement.spot.definition'}), formatMessage({id: 'campaigns.bulkupload.template.proposed.ad.copy.definition'}),
            formatMessage({id: 'campaigns.bulkupload.template.original.publisher.commission.definition'}), formatMessage({id: 'campaigns.bulkupload.template.new.publisher.commission.definition'}), formatMessage({id: 'campaigns.bulkupload.template.original.flat.fee.definition'}), formatMessage({id: 'campaigns.bulkupload.template.cj.flat.fee.definition'}), formatMessage({id: 'campaigns.bulkupload.template.auto.calculated.baseline.used.definition'}),
            formatMessage({id: 'campaigns.bulkupload.template.average.daily.revenue.definition'}), formatMessage({id: 'campaigns.bulkupload.template.yoy.growth.trend.definition'}), formatMessage({id: 'campaigns.bulkupload.template.prior.placement.lift.definition'}), formatMessage({id: 'campaigns.bulkupload.template.other.expected.lift.definition'}), formatMessage({id: 'campaigns.bulkupload.template.send.size.definition'}),
            formatMessage({id: 'campaigns.bulkupload.template.sov.before.definition'}), formatMessage({id: 'campaigns.bulkupload.template.actual.sov.after.definition'}), formatMessage({id: 'campaigns.bulkupload.template.cj.marketplace.io.definition'}), formatMessage({id: 'campaigns.bulkupload.template.publisher.io.definition'}), formatMessage({id: 'campaigns.bulkupload.template.io.signed.definition'}),
            formatMessage({id: 'campaigns.bulkupload.template.program.term.description.definition'}), formatMessage({id: 'campaigns.bulkupload.template.program.term.name.definition'}), formatMessage({id: 'campaigns.bulkupload.template.program.term.pushed.definition'}), formatMessage({id: 'campaigns.bulkupload.template.asset.due.date.definition'}), formatMessage({id: 'campaigns.bulkupload.template.assets.needed.definition'}),
            formatMessage({id: 'campaigns.bulkupload.template.assets.sent.by.advertiser.definition'}), formatMessage({id: 'campaigns.bulkupload.template.tiered.offer.details.definition'}), formatMessage({id: 'campaigns.bulkupload.template.offer.type.definition'}), formatMessage({id: 'campaigns.bulkupload.template.offer.value.definition'}), formatMessage({id: 'campaigns.bulkupload.template.offer.conditions.definition'}),
            formatMessage({id: 'campaigns.bulkupload.template.offer.threshold.definition'}), formatMessage({id: 'campaigns.bulkupload.template.cashback.amount.onsite.definition'}), formatMessage({id: 'campaigns.bulkupload.template.link.id.definition'}), formatMessage({id: 'campaigns.bulkupload.template.cj.invoice.month.definition'}), formatMessage({id: 'campaigns.bulkupload.template.publisher.used.link.id.definition'}),
            formatMessage({id: 'campaigns.bulkupload.template.correct.offer.used.definition'}), formatMessage({id: 'campaigns.bulkupload.template.live.ad.copy.definition'}), formatMessage({id: 'campaigns.bulkupload.template.path.to.saved.email.definition'}), formatMessage({id: 'campaigns.bulkupload.template.validation.notes.definition'}), formatMessage({id: 'campaigns.bulkupload.template.paid.in.cj.definition'}),
            formatMessage({id: 'campaigns.bulkupload.template.payment.oid.definition'}), formatMessage({id: 'campaigns.bulkupload.template.amount.paid.definition'}), formatMessage({id: 'campaigns.bulkupload.template.payment.date.definition'}), formatMessage({id: 'campaigns.bulkupload.template.payment.notes.definition'})],

        TEMPLATE_ROW_FIVE: [formatMessage({id: 'campaigns.bulkupload.template.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.yes.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.yes.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.yes.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.yes.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.yes.mandatory'}),
            formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.yes.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.yes.mandatory'}),
            formatMessage({id: 'campaigns.bulkupload.template.yes.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.yes.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.yes.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.yes.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}),
            formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.yes.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.publisher.commission.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.original.fee.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.negotiated.fee.mandatory'}),
            formatMessage({id: 'campaigns.bulkupload.template.yes.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.average.daily.revenue.baseline.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.organic.lift.over.baseline.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.organic.lift.over.baseline.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.organic.lift.over.baseline.mandatory'}),
            formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.cj.marketplace.io.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.cj.marketplace.io.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}),
            formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}),
            formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}),
            formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}),
            formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'})],

        TEMPLATE_ROW_SIX: [formatMessage({id: 'campaigns.bulkupload.template.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.placement.status.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.advertiser.cid.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.publisher.property.id.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.start.end.date.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.start.end.date.instructions'}),
            formatMessage({id: 'campaigns.bulkupload.template.length.of.500.character.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.length.of.500.character.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.length.of.500.character.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.length.of.500.character.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.placement.name.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.placement.type.instructions'}),
            formatMessage({id: 'campaigns.bulkupload.template.static.dynamic.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.yes.no.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.repeated.new.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.yes.no.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.events.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.package.name.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.placement.position.instructions'}),
            formatMessage({id: 'campaigns.bulkupload.template.length.of.500.character.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.commission.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.commission.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.fee.cost.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.fee.cost.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.auto.calculated.baseline.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.fee.cost.instructions'}),
            formatMessage({id: 'campaigns.bulkupload.template.lift.over.baseline.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.lift.over.baseline.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.lift.over.baseline.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.send.size.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.share.of.placement.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.share.of.placement.instructions'}),
            formatMessage({id: 'campaigns.bulkupload.template.insertion.order.id.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.insertion.order.id.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.status.booked.yes.no.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.status.booked.500.characters.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.status.booked.500.characters.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.program.term.pushed.instructions'}),
            formatMessage({id: 'campaigns.bulkupload.template.asset.due.date.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.status.booked.500.characters.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.asset.sent.on.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.status.booked.500.characters.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.offer.type.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.insertion.order.id.instructions'}),
            formatMessage({id: 'campaigns.bulkupload.template.offer.condition.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.status.booked.500.characters.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.insertion.order.id.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.insertion.order.id.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.status.booked.500.characters.instructions'}),
            formatMessage({id: 'campaigns.bulkupload.template.status.booked.yes.no.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.status.booked.yes.no.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.status.booked.500.characters.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.status.booked.500.characters.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.status.booked.500.characters.instructions'}),
            formatMessage({id: 'campaigns.bulkupload.template.paid.in.cj.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.status.booked.500.characters.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.amount.paid.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.last.payment.date.instructions'}), formatMessage({id: 'campaigns.bulkupload.template.payment.notes.instructions'})],

        TEMPLATE_ROW_SEVEN: [formatMessage({id: 'campaigns.bulkupload.template.sample.data'}), formatMessage({id: 'campaigns.bulkupload.template.placement.status.sample.data'}), "1113122", "46157", "2023-07-31 00:00:00", "2023-08-15 23:59:59", "2023", "Q3", formatMessage({id: 'campaigns.bulkupload.template.fiscal.month.sample.data'}), "33", formatMessage({id: 'campaigns.bulkupload.template.placement.name.sample.data'}), formatMessage({id: 'campaigns.bulkupload.template.placement.type.sample.data'}),
            formatMessage({id: 'campaigns.bulkupload.template.static.dynamic.sample.data'}), formatMessage({id: 'campaigns.bulkupload.template.yes.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.repeated.new.sample.data'}), formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.events.sample.data'}), formatMessage({id: 'campaigns.bulkupload.template.package.name.sample.data'}), "2",
            formatMessage({id: 'campaigns.bulkupload.template.proposed.ad.copy.sample.data'}), "10", "15", "5000", "2000", formatMessage({id: 'campaigns.bulkupload.template.no.mandatory'}), "125000", "10", "20", "1", "3000000", "35", "60", "IO#5667771", formatMessage({id: 'campaigns.bulkupload.template.na.sample.data'}),
            formatMessage({id: 'campaigns.bulkupload.template.yes.mandatory'}), formatMessage({id: 'campaigns.bulkupload.template.program.terms.description.sample.data'}), formatMessage({id: 'campaigns.bulkupload.template.program.terms.name.sample.data'}), formatMessage({id: 'campaigns.bulkupload.template.yes.mandatory'}), "2023-07-15 23:59:59", formatMessage({id: 'campaigns.bulkupload.template.assets.needed.sample.data'}), "2023-06-09 13:00:00", formatMessage({id: 'campaigns.bulkupload.template.tiered.offer.details.sample.data'}),
            formatMessage({id: 'campaigns.bulkupload.template.offer.type.sample.data'}), "500", formatMessage({id: 'campaigns.bulkupload.template.offer.conditions.sample.data'}), formatMessage({id: 'campaigns.bulkupload.template.offer.threshold.sample.data'}), "1000", "1323221", formatMessage({id: 'campaigns.bulkupload.template.cj.invoice.month.sample.data'}), formatMessage({id: 'campaigns.bulkupload.template.na.sample.data'}), formatMessage({id: 'campaigns.bulkupload.template.na.sample.data'}),
            formatMessage({id: 'campaigns.bulkupload.template.live.ad.copy.sample.data'}), formatMessage({id: 'campaigns.bulkupload.template.path.for.screenshot.sample.data'}), formatMessage({id: 'campaigns.bulkupload.template.validation.notes.sample.data'}), formatMessage({id: 'campaigns.bulkupload.template.paid.in.cj.sample.data'}), "3266611", "500", "2023-06-09", formatMessage({id: 'campaigns.bulkupload.template.payment.notes.sample.data'})]
    }
}
export default BulkUploadTemplateTranslations;