const DecideRoute = ({
                         routes,
                         PlatformApp, getPlatformDomains, translatedMessagesByLanguage
                     }) => {
    const platformDomains = getPlatformDomains();

    return <>
        <PlatformApp routes={routes} platformDomains={platformDomains} translations={translatedMessagesByLanguage}/>
    </>
}
export default DecideRoute;
