const PlacementDialogSubmitAction = async (formData, submitAction, isBulkUpload) => {
    const createRecordRequestData = {
        placementname: formData.placementName,
        status: isBulkUpload ? formData.placementStatusIdEnValue : formData.placementStatusId,
        advertisercompanyid: formData.advertiserCompanyId.value && formData.advertiserCompanyId.value.toString(),
        startdate: formData.pStartDate,
        enddate: formData.pEndDate,
        fiscalyear: formData.fiscalYear,
        fiscalmonth: formData.fiscalMonth,
        fiscalquarter: formData.fiscalQuarter,
        fiscalweek: formData.fiscalWeek,
        advertisername: formData.advertiserCompanyId.companyName && formData.advertiserCompanyId.companyName.toString(),
        publisherpropertyid: formData.pid.publisherPropertyId,
        publishercompanyid: formData.pid.publisherCompanyId,
        propertymodel: formData.pid.propertyModel,
        websiteurl: formData.pid.websiteUrl,
        publishername: formData.pid.publisherName,
        propertyname: formData.pid.propertyName,
        propertytype: formData.pid.propertyType,
        createdby: "Frontend",// NOTE: Need to update with username, Extract from authentication token
        packagename: formData.packageName,
        placementtype: isBulkUpload ? formData.placementTypeEnValue : formData.placementType,
        dynamicorstatic: isBulkUpload ? formData.dynamicOrStaticEnValue : formData.dynamicOrStatic,
        personalizedortargeted: isBulkUpload ? formData.personalizedOrTargetedEnValue : formData.personalizedOrTargeted,
        neworcomped: isBulkUpload ? formData.newOrCompedEnValue : formData.newOrComped,
        vipcontentcampaign: isBulkUpload ? formData.vipContentCampaignEnValue : formData.vipContentCampaign,
        rankofplacement: formData.rankofplacement,
        event: isBulkUpload ? formData.eventsEnValue : formData.events,
        proposedofferdescription: formData.proposedOfferDescription !== undefined ? formData.proposedOfferDescription : "",
        forecastPerformance: formData.forecastPerformance
    }

    const tabs = ["validationDetails", "bookingdetails", "paymentDetails", "performanceDetails"];
    const bookingDetailsDropdownFields = ["bookingispushed", "bookingissigned", "bookingoffercondition", "bookingoffertype"]
    const paymentDetailsDropdownFields = ["paidInCJ"];
    const validationDetailsDropdownFields = ["publisherusedspecifiedlinkid", "wascorrectofferused"];
    const setOriginalFiledValues = (fields, tab) => {
        fields.forEach((fieldName) => {
            formData[tab][fieldName] = formData[tab][fieldName + "EnValue"];
            delete formData[tab][fieldName + "EnValue"];
        });
    };
    if (isBulkUpload) {
        tabs.forEach((tab) => {
            if (formData[tab] === undefined || Object.keys(formData[tab]).length === 0) {
                createRecordRequestData[tab] = {};
            } else {
                if (tab === 'performanceDetails') {
                    formData[tab].baselineValue.isBaselineCalculated = formData[tab].baselineValue.isBaselineCalculatedEnValue;
                    delete formData[tab].baselineValue.isBaselineCalculatedEnValue;
                } else if (tab === 'paymentDetails') {
                    setOriginalFiledValues(paymentDetailsDropdownFields, tab)
                } else if (tab === 'bookingdetails') {
                    setOriginalFiledValues(bookingDetailsDropdownFields, tab)
                } else if (tab === 'validationDetails') {
                    setOriginalFiledValues(validationDetailsDropdownFields, tab)
                }
                createRecordRequestData[tab] = formData[tab];
            }
        });
    } else {
        tabs.forEach((tab) => {
            if (formData[tab] === undefined || Object.keys(formData[tab]).length === 0) {
                createRecordRequestData[tab] = {};
            } else {
                createRecordRequestData[tab] = formData[tab];
            }
        });
    }

    //calling submit action for save its create placement record
    await submitAction({
        variables: createRecordRequestData
    });
}


export default PlacementDialogSubmitAction;
