import {bookingdetails, detailsTab, detailsTabAdvertiser, detailsTabPID} from "../../constants/appConstant";

function updatePlacementObjectForTabs(detailsTabField, updatePlacementObject, formData) {
    detailsTabField.forEach((key) => {
        if (formData[key.formKey] !== undefined)
            updatePlacementObject[key.getKey] = formData[key.formKey];
    })
}

const addMetricDependantFields = (updatePlacementObject, placementRecord) => {
    const dependantFields = ['startdate', 'enddate', 'advertisercompanyid', 'publishercompanyid', 'publisherpropertyid'];
    const availableFields = Object.keys(updatePlacementObject);

    const placementContainsDependantFields = (availableFields) => {
        for (let i = 0; i < availableFields.length; i++) {
            const field = availableFields[i];
            if (dependantFields.includes(field)) {
                return true;
            }
        }
    }

    const parseIntegerFields = (placementRecord, field) => {
        const integerFields = ['publishercompanyid', 'publisherpropertyid'];
        if (integerFields.includes(field)) {
            return parseInt(placementRecord[field]);
        }
        return placementRecord[field];
    }

    if (placementContainsDependantFields(availableFields)) {
        let missingDependantFields = dependantFields.filter(dependantField => !availableFields.includes(dependantField));

        const addMissingFields = (field) => {
            updatePlacementObject[field] = parseIntegerFields(placementRecord, field);
        }
        missingDependantFields.forEach(addMissingFields);
    }
}

const PlacementDialogSubmitEditAction = (formData, submitAction, placementRecord) => {
    let updatePlacementObject = {placementId: parseInt(formData.id)};

    updatePlacementObjectForTabs(detailsTab, updatePlacementObject, formData);

    updatePlacementObjectForTabs(detailsTabPID, updatePlacementObject, formData.pid || {});

    updatePlacementObjectForTabs(detailsTabAdvertiser, updatePlacementObject, formData.advertiserCompanyId || {});

    const tabs = ["validationDetails", "bookingdetails", "paymentDetails", "performanceDetails", "forecastPerformance"];
    tabs.forEach((tab) => {
        if (formData[tab]) {
            if (tab === tabs[1]) {
                let tempBookingDetails = {}
                updatePlacementObjectForTabs(bookingdetails, tempBookingDetails, formData[tab]);
                updatePlacementObject[tab] = tempBookingDetails;
            } else {
                updatePlacementObject[tab] = formData[tab];
            }
        }
    });

    addMetricDependantFields(updatePlacementObject, placementRecord);

    if (Object.keys(updatePlacementObject).length > 1) {
        //calling submit action with edited data
        //callback will take care modal closing
        submitAction({
            variables: updatePlacementObject
        });
    } else {
        //closing modal
        return true;
    }
}

export default PlacementDialogSubmitEditAction;
