import {hasValue} from "../../utils/util";

const PlacementDataParser = (data, id, advertiserName) => {
    return {
        id: id,
        placementStatusId: data.placementStatusId,
        advertiserCompanyId: {
            value: data.value,
            companyName: advertiserName
        },
        performanceDetails: {
            baselineValue: {
                isBaselineCalculated: data.isBaselineCalculated,
                averageBaselineRevenue: data.averageBaselineRevenue
            },
            performanceOriginalPublisherCommission: floatParser(data.performanceOriginalPublisherCommission),
            performanceNewPublisherCommission: floatParser(data.performanceNewPublisherCommission),
            performanceOriginalRateCardFlatFeePrice: floatParser(data.performanceOriginalRateCardFlatFeePrice),
            performanceCjNegotiatedFlatFeeCost: floatParser(data.performanceCjNegotiatedFlatFeeCost),
            performanceyoygrowthtrendused: floatParser(data.performanceyoygrowthtrendused),
            performancePredictedPlacementLift: floatParser(data.performancePredictedPlacementLift),
            performanceOtherExpectedLift: floatParser(data.performanceOtherExpectedLift),
            performanceSendSizeExpectedUniqueValues: floatParser(data.performanceSendSizeExpectedUniqueValues),
            performanceSovBefore: floatParser(data.performanceSovBefore),
            performanceActualSovAfter: floatParser(data.performanceActualSovAfter)
        },
        pid: {
            publisherPropertyId: intParser(data.publisherPropertyId),
            publisherCompanyId: data.publisherCompanyId,
            propertyModel: data.propertyModel,
            websiteUrl: data.websiteUrl,
            publisherName: data.publisherName,
            propertyName: data.propertyName,
            propertyType: data.propertyType,
        },
        forecastPerformance: {},
        pStartDate: data.pStartDate,
        pEndDate: data.pEndDate,
        fiscalYear: data.fiscalYear,
        fiscalQuarter: data.fiscalQuarter,
        fiscalMonth: data.fiscalMonth,
        fiscalWeek: data.fiscalWeek,
        placementName: data.placementName,
        placementType: data.placementType,
        dynamicOrStatic: data.dynamicOrStatic,
        personalizedOrTargeted: data.personalizedOrTargeted,
        newOrComped: data.newOrComped,
        vipContentCampaign: data.vipContentCampaign,
        events: data.events,
        packageName: data.packageName,
        rankofplacement: intParser(data.rankofplacement),
        proposedOfferDescription: data.proposedOfferDescription,
        bookingdetails: {
            bookingmarketinsertionorder: data.bookingmarketinsertionorder,
            bookingotherio: data.bookingotherio,
            bookingissigned: data.bookingissigned,
            bookingprogramtermdescription: data.bookingprogramtermdescription,
            bookingprogramtermname: data.bookingprogramtermname,
            bookingispushed: data.bookingispushed,
            bookingduedate: data.bookingduedate,
            bookingassetsneeded: data.bookingassetsneeded,
            bookingassetsentdate: data.bookingassetsentdate,
            bookingtieredoffer: data.bookingtieredoffer,
            bookingoffertype: data.bookingoffertype,
            bookingoffervalue: data.bookingoffervalue,
            bookingoffercondition: data.bookingoffercondition,
            bookingofferthreshold: data.bookingofferthreshold,
            bookingincentiveamount: data.bookingincentiveamount,
            bookinglinkid: data.bookinglinkid,
            bookinginvoicemonth: data.bookinginvoicemonth
        },
        validationDetails: {
            publisherusedspecifiedlinkid: data.publisherusedspecifiedlinkid,
            wascorrectofferused: data.wascorrectofferused,
            description: data.description,
            screenshotlink: data.screenshotlink,
            validationnotes: data.validationnotes

        },
        paymentDetails: {
            paidInCJ: data.paidInCJ,
            transactionid: data.transactionid,
            amount: floatParser(data.amount),
            notes: data.notes,
            transactiondate: data.transactiondate
        }
    }
}
export const floatParser = (value) => {
    if (!hasValue(value)) return undefined;
    const parsedValue = Number(value);
    return isNaN(parsedValue) ? value : parsedValue;
};

export const intParser = (value) => {
    if (!hasValue(value)) return undefined;
    const parsedValue = Number(value);
    return isNaN(parsedValue) ? value : parsedValue;
}

export default PlacementDataParser;