import moment from "moment";
import * as appConstant from "../constants/appConstant";

export function roundOffToTwoDecimalPlaces({value}) {
    const decimals = 2;
    if (value) {
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    }
    return value;
}

export function formatDate({value}) {
    if (value) {
        return moment(value, appConstant.YYYY_MM_DD).format(appConstant.MMM_DD_YYYY);
    }
    return value;
}

export function formatDateAndTime({value}) {
    if (value) {
        return moment(value, appConstant.DATE_TIME_FORMAT).format(appConstant.MMM_DD_YYYY_HH_MI_AM_PM);
    }
    return value;
}

export function formatNullToNotAvailable({value}) {
    if (value === null)
        return appConstant.DISPLAY_NOT_AVAILABLE;
    else
        return value;
}


function isNumeric(inputValue) {
    return !isNaN(parseFloat(inputValue)) && isFinite(inputValue);
}

export function formatCurrency(params) {
    const value = params.value;
    const currency = params.currency || params.data?.currency;
    if (isNumeric(value)) {
        try {
            return new Intl.NumberFormat(appConstant.CURRENCY_AND_PERCENTAGE_FORMAT_LOCALE, {
                style: 'currency',
                currency: currency
            }).format(value);
        } catch (error) {
            return value;
        }
    } else
        return value;
}

export function formatToPercentage({value}) {
    if (isNumeric(value)) {
        return new Intl.NumberFormat(appConstant.CURRENCY_AND_PERCENTAGE_FORMAT_LOCALE, {
            style: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value / 100)
    }
    return value;
}

export function formatToPercentageWhenNotNull(value) {
    const nullCheckedValue = {value: formatNullToNotAvailable(value)};
    return formatToPercentage(nullCheckedValue);
}

export function filterDateComparator(filterInput, cellValue) {
    filterInput = moment(filterInput, appConstant.YYYY_MM_DD);
    const cellDate = moment(cellValue, appConstant.MMM_DD_YYYY);

    if (cellDate.isBefore(filterInput)) //returns true
    {
        return -1;
    } else if (cellDate.isAfter(filterInput)) {
        return 1;
    }
    return 0;
}

export function bulkUploadErrorsFormatter(errors, index, formatMessage) {
    return errors.length < 1 ? null : `${formatMessage({id: "campaigns.bulkupload.excel.error.row"})} ${index}${formatMessage({id: "campaigns.bulkupload.excel.errors"})}\n ${errors.join('\n ')}`

}

export function bulkUploadWarningFormatter(warnings, index, formatMessage) {
    return warnings.length < 1 ? null : `${formatMessage({id: "campaigns.bulkupload.excel.error.row"})} ${index}${formatMessage({id: "campaigns.bulkupload.excel.warnings"})}\n ${warnings.join('\n ')}`;

}
