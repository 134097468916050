import {createContext, useContext, useState} from "react";

export const BulkUploadContext = createContext({});
export const useBulkUploadContext = () => useContext(BulkUploadContext);

export const BulkUploadContextProvider = ({children}) => {
    const [bulkUploadDetails, setBulkUploadDetails] = useState({rows: [], successfulRows: [], failedRows: []});
    const [errorDetails, setErrorDetails] = useState([]);
    const [warningDetails, setWarningDetails] = useState([]);

    return <BulkUploadContext.Provider value={{
        bulkUploadDetails,
        setBulkUploadDetails,
        errorDetails,
        setErrorDetails,
        warningDetails,
        setWarningDetails
    }}>
        {children}
    </BulkUploadContext.Provider>
}