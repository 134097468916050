import * as appConstant from '../../constants/appConstant';
import TextField from './TextField';
import React, {useEffect, useState} from 'react'

const TextFieldHelp = ({
                           id,
                           name,
                           required,
                           label,
                           onChange,
                           maxLength,
                           errorData,
                           defaultValue,
                           help,
                           labelTooltip,
                           tooltipProps
                       }) => {

    const [helpMessage, setHelpMessage] = useState(appConstant.EMPTY)

    const error = errorData.get(id)?.value

    const helpElement = <p className="fetch-message">{help}</p>

    const showHelpHandler = () => setHelpMessage(helpElement)

    const hideHelpHandler = () => {
        if (!error) {
            setHelpMessage(appConstant.EMPTY)
        }
    }

    const resetErrorAndHelp = () => {
        errorData.set(id, {value: false})
        setHelpMessage(appConstant.EMPTY)
    }

    const changeHandler = (e) => {
        resetErrorAndHelp()
        onChange(e);
    }

    useEffect(() => {
        if (error) {
            showHelpHandler()
        }
    }, [errorData])

    useEffect(() => {
        if (!required) {
            resetErrorAndHelp()
        }
    }, [required])

    return (
        <TextField id={id}
                   className="text-field-help"
                   name={name}
                   label={label}
                   required={required}
                   onChange={changeHandler}
                   maxLength={maxLength}
                   error={(error && helpMessage) ? helpMessage : error}
                   onFocus={showHelpHandler}
                   onBlur={hideHelpHandler}
                   help={helpMessage}
                   defaultValue={defaultValue}
                   labelTooltip={labelTooltip}
                   tooltipProps={tooltipProps}
                   className="overwrite-legacy-textField"
        />
    )
};

export default TextFieldHelp;