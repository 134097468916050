import * as FormElements from "@cjdev-internal/visual-stack-x/legacy/Form";

const TextField = ({...restProps}) => {
    const onWheelHandler = (event) => {
        event.target.blur();
    }
    return <FormElements.TextField
        onWheel={onWheelHandler}
        {...restProps}
    />
}

export default TextField;