import React, {useEffect, useState} from 'react';
import {Box} from "@cjdev-internal/visual-stack-x/Box";
import * as appConstant from "../../constants/appConstant";
import {Label} from "@cjdev-internal/visual-stack-x/legacy/Form";
import Input from './Input';
import YoYObservedHelp from "./YoYObservedHelp";
import CustomBaseline from "./CustomBaseline";
import {useIntl} from "../../useIntl";

const PerformanceTabContent = ({handleChange, id, errorData, getPlacementFieldData, modeOfPlacement}) => {
    const isEdit = modeOfPlacement === appConstant.PLACEMENT_MODE_EDIT;
    const [performanceDetails, setPerformanceDetails] = useState({});
    const intl = useIntl();

    useEffect(() => {
        handleChange(id, performanceDetails);
    }, [performanceDetails]);

    const handleChangePerformance = (id, value) => {
        setPerformanceDetails((prevState) => {
            return {
                ...prevState,
                [id]: value
            }
        });
        errorData.set(id, {value: false});
    }

    return (
        <Box direction="column" gap="large">
            <Box direction="row" gap="xl">
                <Box direction="column">
                    <Label
                        required>{intl.formatMessage({id: "campaigns.placement.original.publisher.commission"})}</Label>
                    <Input type="number" id="performanceOriginalPublisherCommission"
                           placeholder={intl.formatMessage({id: "campaigns.enter.number.placeholder"})}
                           onChange={({target: {id, value}}) => handleChangePerformance(id, (parseFloat(value)))}
                           defaultValue={getPlacementFieldData('performanceoriginalpublishercommission', 'input')}
                           className={errorData.get("performanceOriginalPublisherCommission")?.value === true && "input-error"}
                           min="0"/>
                </Box>
                <Box direction="column">
                    <Label>{intl.formatMessage({id: "campaigns.placement.new.publisher.commission"})}</Label>
                    <Input type="number" id="performanceNewPublisherCommission"
                           placeholder={intl.formatMessage({id: "campaigns.enter.number.placeholder"})}
                           onChange={({target: {id, value}}) => handleChangePerformance(id, parseFloat(value))}
                           defaultValue={getPlacementFieldData('performancenewpublishercommission', 'input')}
                           min="0"/>
                </Box>
            </Box>
            <Box direction="row" gap="xl">
                <Box direction="column">
                    <Label labelTooltip={intl.formatMessage({id: "campaigns.placement.original.flat.fee.tooltip"})}
                           tooltipProps={appConstant.TOOLTIP_PROPS}>{intl.formatMessage({id: "campaigns.placement.original.flat.fee"})}</Label>
                    <Input type="number" id="performanceOriginalRateCardFlatFeePrice"
                           placeholder={intl.formatMessage({id: "campaigns.enter.number.placeholder"})}
                           onChange={({target: {id, value}}) => handleChangePerformance(id, parseFloat(value))}
                           defaultValue={getPlacementFieldData('performanceoriginalratecardflatfeeprice', 'input')}
                           min="0"/>
                </Box>
                <Box direction="column">
                    <Label labelTooltip={intl.formatMessage({id: "campaigns.placement.cj.flat.fee.tooltip"})}
                           tooltipProps={appConstant.TOOLTIP_PROPS}>{intl.formatMessage({id: "campaigns.placement.negotiated.flat.fee"})}</Label>
                    <Input type="number" id="performanceCjNegotiatedFlatFeeCost"
                           placeholder={intl.formatMessage({id: "campaigns.enter.number.placeholder"})}
                           onChange={({target: {id, value}}) => handleChangePerformance(id, parseFloat(value))}
                           defaultValue={getPlacementFieldData('performancecjnegotiatedflatfeecost', 'input')}
                           min="0"/>
                </Box>
            </Box>
            <Box direction="column">
                <CustomBaseline handleChange={handleChangePerformance}
                                getPlacementData={getPlacementFieldData}
                                modeOfPlacement={modeOfPlacement}
                                errorData={errorData.get("baselineValue")}
                />
            </Box>
            <Box direction="row" gap="xl">
                <Box direction="column">
                    <YoYObservedHelp handleChangePerformance={handleChangePerformance}
                                     getPlacementFieldData={getPlacementFieldData}
                                     isEdit={isEdit}/>
                </Box>
                <Box direction="column">
                    <Label labelTooltip={intl.formatMessage({id: "campaigns.placement.prior.placement.lift.tooltip"})}
                           tooltipProps={appConstant.TOOLTIP_PROPS}>{intl.formatMessage({id: "campaigns.placement.prior.placement.lift"})}</Label>
                    <Input type="number" id="performancePredictedPlacementLift"
                           placeholder={intl.formatMessage({id: "campaigns.enter.number.placeholder"})}
                           onChange={({target: {id, value}}) => handleChangePerformance(id, parseFloat(value))}
                           defaultValue={getPlacementFieldData('performancepredictedplacementlift', 'input')}/>
                </Box>
                <Box direction="column">
                    <Label labelTooltip={intl.formatMessage({id: "campaigns.placement.other.expected.lift.tooltip"})}
                           tooltipProps={appConstant.TOOLTIP_PROPS}>{intl.formatMessage({id: "campaigns.placement.other.placement.lift"})}</Label>
                    <Input type="number" id="performanceOtherExpectedLift"
                           placeholder={intl.formatMessage({id: "campaigns.enter.number.placeholder"})}
                           onChange={({target: {id, value}}) => handleChangePerformance(id, parseFloat(value))}
                           defaultValue={getPlacementFieldData('performanceotherexpectedlift', 'input')}/>
                </Box>
            </Box>
            <Box direction="row" gap="xl">
                <Box direction="column">
                    <Label labelTooltip={intl.formatMessage({id: "campaigns.placement.send.size.tooltip"})}
                           tooltipProps={appConstant.TOOLTIP_PROPS}>{intl.formatMessage({id: "campaigns.placement.send.size"})}</Label>
                    <Input type="number" id="performanceSendSizeExpectedUniqueValues"
                           placeholder={intl.formatMessage({id: "campaigns.enter.number.placeholder"})}
                           onChange={({target: {id, value}}) => handleChangePerformance(id, parseFloat(value))}
                           defaultValue={getPlacementFieldData('performancesendsizeexpecteduniquevalues', 'input')}
                           min="0"/>
                </Box>
                <Box direction="column">
                    <Label labelTooltip={intl.formatMessage({id: "campaigns.placement.sov.before.tooltip"})}
                           tooltipProps={appConstant.TOOLTIP_PROPS}>{intl.formatMessage({id: "campaigns.placement.sov.before"})}</Label>
                    <Input type="number" id="performanceSovBefore"
                           placeholder={intl.formatMessage({id: "campaigns.enter.number.placeholder"})}
                           onChange={({target: {id, value}}) => handleChangePerformance(id, parseFloat(value))}
                           defaultValue={getPlacementFieldData('performancesovbefore', 'input')}
                           min="0"/>
                </Box>
                <Box direction="column">
                    <Label labelTooltip={intl.formatMessage({id: "campaigns.placement.actual.sov.after.tooltip"})}
                           tooltipProps={appConstant.TOOLTIP_PROPS}>{intl.formatMessage({id: "campaigns.placement.sov.actual"})}</Label>
                    <Input type="number" id="performanceActualSovAfter"
                           placeholder={intl.formatMessage({id: "campaigns.enter.number.placeholder"})}
                           onChange={({target: {id, value}}) => handleChangePerformance(id, parseFloat(value))}
                           defaultValue={getPlacementFieldData('performanceactualsovafter', 'input')}
                           min="0"/>
                </Box>
            </Box>
        </Box>
    )
}

export default PerformanceTabContent;
