import React, {useEffect} from "react";
import {Tab, TabLayout, useTabLayout} from "@cjdev-internal/visual-stack-x/TabLayout";
import {Label} from "@cjdev-internal/visual-stack-x/legacy/Form";
import * as appConstant from "../../constants/appConstant";
import CampaignsDropDown from "./CampaignsDropDown";
import CreateRecordValidationContent from "./CreateRecordValidationContent";
import CreateRecordBookingContent from "./CreateRecordBookingContent";
import PaymentDetailsContent from "./PaymentDetailsContent";
import PerformanceTabContent from "./PerformanceTabContent";
import CreateRecordDetailsContent from "./CreateRecordDetailsContent";
import {getTranslatedLabel, hasValue} from "../../utils/util";
import {useIntl} from "../../useIntl";

const CreateRecordModalContent = ({
                                      handleChange,
                                      errorData,
                                      placementRecord,
                                      modeOfPlacement,
                                      campaignsTranslatedDropdownList
                                  }) => {
    const [selectedId, selectTab] = useTabLayout("details-tab")
    const intl = useIntl();

    useEffect(() => {
        const loadInitialDefaultValues = () => {
            if (modeOfPlacement === appConstant.PLACEMENT_MODE_CREATE || modeOfPlacement === appConstant.PLACEMENT_MODE_DUPLICATE) {
                handleChange('placementStatusId', appConstant.PLACEMENT_STATUS_FIELD[1].value);
            } else {
                handleChange('id', placementRecord.id);
            }
        }
        loadInitialDefaultValues();
    }, []);

    const getPlacementFieldData = (key, type, callback) => {
        if (placementRecord && hasValue(placementRecord[key])) {
            if (callback) {
                return callback(placementRecord);
            }

            if (type === 'select')
                return {
                    value: placementRecord[key],
                    label: getTranslatedLabel(intl.formatMessage, placementRecord[key])
                }
            else
                return placementRecord[key];
        } else {
            return key === 'status' ? {
                value: "Proposed",
                label: intl.formatMessage({id: "campaigns.status.proposed"})
            } : "";
        }
    }

    const getConditionalPlacementData = (...props) => {
        // Props not in (performancecjnegotiatedflatfeecost,performanceoriginalratecardflatfeeprice)
        if (modeOfPlacement === appConstant.PLACEMENT_MODE_DUPLICATE && ((props[0] !== 'performancecjnegotiatedflatfeecost') &&
            (props[0] !== 'performanceoriginalratecardflatfeeprice'))) {
            return "";
        } else {
            return getPlacementFieldData(...props);
        }
    }

    return (
        <div className="create-placement-container">
            <Label required>{intl.formatMessage({id: "campaigns.placement.status"})}</Label>
            <CampaignsDropDown id='placementStatusId'
                               handleChange={handleChange}
                               optionsValue={campaignsTranslatedDropdownList(appConstant.DROPDOWN_PLACEMENT_STATUS, intl)}
                               defaultValue={getPlacementFieldData('status', 'select')}
                               errorData={errorData.get("placementStatusId")}/>
            <TabLayout selectedId={selectedId}
                       selectTab={selectTab}>
                <Tab id="details-tab"
                     label={intl.formatMessage({id: "campaigns.modal.tab.header.details"})}>
                    <CreateRecordDetailsContent id="detailsTab"
                                                handleChange={handleChange}
                                                errorData={errorData}
                                                getPlacementFieldData={getPlacementFieldData}
                                                modeOfPlacement={modeOfPlacement}
                                                campaignsTranslatedDropdownList={campaignsTranslatedDropdownList}
                    />
                </Tab>
                <Tab id="performance-tab"
                     label={intl.formatMessage({id: "campaigns.modal.tab.header.cost.proj.lift"})}>
                    <PerformanceTabContent id="performanceDetails"
                                           handleChange={handleChange}
                                           errorData={errorData}
                                           getPlacementFieldData={getPlacementFieldData}
                                           modeOfPlacement={modeOfPlacement}/>
                </Tab>
                <Tab id="booking-tab"
                     label={intl.formatMessage({id: "campaigns.modal.tab.header.booking"})}>
                    <CreateRecordBookingContent id="bookingdetails"
                                                handleChange={handleChange}
                                                errorData={errorData}
                                                getPlacementFieldData={getConditionalPlacementData}
                                                modeOfPlacement={modeOfPlacement}
                                                campaignsTranslatedDropdownList={campaignsTranslatedDropdownList}/>
                </Tab>
                <Tab id="validation-tab"
                     label={intl.formatMessage({id: "campaigns.modal.tab.header.validation"})}>
                    <CreateRecordValidationContent id="validationDetails"
                                                   handleChange={handleChange}
                                                   getPlacementFieldData={getConditionalPlacementData}
                                                   modeOfPlacement={modeOfPlacement}
                                                   campaignsTranslatedDropdownList={campaignsTranslatedDropdownList}/>
                </Tab>
                <Tab id="payment-tab"
                     label={intl.formatMessage({id: "campaigns.modal.tab.header.payment"})}>

                    <PaymentDetailsContent id="paymentDetails"
                                           handleChange={handleChange}
                                           getPlacementFieldData={getConditionalPlacementData}
                                           modeOfPlacement={modeOfPlacement}
                                           campaignsTranslatedDropdownList={campaignsTranslatedDropdownList}/>
                </Tab>
            </TabLayout>
        </div>
    );
};

export default CreateRecordModalContent;
