import React, {useEffect, useState} from "react";
import {Modal} from "@cjdev-internal/visual-stack-x/Modal";
import {Button} from "@cjdev-internal/visual-stack-x/Button";
import {Row} from "@cjdev-internal/visual-stack-x/Row";
import * as appConstant from "../../constants/appConstant";
import {useApolloClient, useMutation} from "@apollo/client";
import {GET_PLACEMENT_DATA, REMOVE_PLACEMENT_MUTATION} from "../../constants/graphql-query-mutation";
import "./styles/RemoveRecordModal.css";
import {useIntl} from "../../useIntl";

const RemoveRecordModal = ({closeModal, showTopToast, placementRecord}) => {
    const [isRemovingPlacement, setIsRemovingPlacement] = useState(false);
    const [deletePlacement, {data, loading}] = useMutation(REMOVE_PLACEMENT_MUTATION)

    const client = useApolloClient();
    const intl = useIntl()

    const removeHandler = () => {
        deletePlacement({
            variables: {placementId: parseInt(placementRecord.id)}
        })
    }

    useEffect(() => {
        setIsRemovingPlacement(loading);
        if (data) {
            const {message, id} = data.deletePlacement;
            if (message.includes(appConstant.CREATE_MODAL_SUCCESS_MESSAGE_TO_COMPARE)) {
                const removeMessage = `${id}${appConstant.HYPHEN}${placementRecord.placementname}${appConstant.SPACE}${intl.formatMessage({id: "campaigns.remove.placement.notification"})}`
                showTopToast({
                    message: removeMessage,
                    contentStyle: {textAlign: "center", minWidth: "300px"},
                    type: 'success',
                    dismissible: true,
                    duration: '5000'
                });
                closeModal();
                client.refetchQueries({
                    include: [GET_PLACEMENT_DATA],
                });
            } else if (message.includes(appConstant.CREATE_MODAL_ERROR_MESSAGE_TO_COMPARE)) {
                showTopToast({
                    message: message,
                    contentStyle: {textAlign: "center", minWidth: "300px"},
                    type: 'warning',
                    dismissible: true
                });
            }
        }
    }, [loading])

    const removeModalProps = {
        headerTitle:
            <div className='header-rm'>
                {intl.formatMessage({id: "campaigns.remove.record.title"})}
            </div>,
        footer:
            <Row gap="medium">
                <Button id='cancel-button-rm' type='tertiary'
                        onClick={closeModal}>{intl.formatMessage({id: "campaigns.cancel"})}</Button>
                <Button id='remove-button-rm' type='primary' onClick={removeHandler}
                        disabled={isRemovingPlacement}>
                    {intl.formatMessage({id: 'campaigns.placement.remove'})}</Button>
            </Row>
    }

    return (
        <Modal id="removeModal" {...removeModalProps}>
            <div>{intl.formatMessage({id: 'campaigns.placement.remove.modal.message'})}</div>
            <p className={"placement-details"}>
                {placementRecord.id}{appConstant.HYPHEN}{placementRecord.placementname}</p>
        </Modal>
    )
}

export default RemoveRecordModal;
