import {bookingdetails, paymentTabDetails, tabs, validationTabDetails} from "../constants/appConstant";

const CreateRecordData = (placementRecord) => {
    let returnObject = {}
    tabs.forEach((tab) => returnObject[tab] = {});

    bookingdetails.forEach((obj) => {
        returnObject[tabs[1]][obj.formKey] = placementRecord[obj.getKey]
    });

    validationTabDetails.forEach((obj) => {
        returnObject[tabs[0]][obj.formKey] = placementRecord[obj.getKey]
    });

    paymentTabDetails.forEach((obj) => {
        returnObject[tabs[2]][obj.formKey] = placementRecord[obj.getKey]
    });

    return returnObject;
}

export default CreateRecordData;