import React from "react";
import * as appConstant from "../../constants/appConstant";
import {
    averageBaselineRevenueValidation,
    baselineCalculatedValidation, dateValidation,
    exceededMaxLengthMessage,
    invalidMessage,
    maxCharacterValidation, nonNegativeNumericValidation,
    numericValidation, onlyNumberMessage,
    optionListValidation, zeroOrPositiveNumberMessage
} from "./Validation/validationFunctions";

const BulkUploadDataTableColumnsTranslation = (formatMessage) => {
    return [
        {
            field: "placementStatusId",
            headerName: formatMessage({id: "campaigns.bulkupload.placement.status"}),
            validation: optionListValidation,
            validationArgs: {acceptedValues: appConstant.PLACEMENT_STATUS_FIELD},
            errorMessage: invalidMessage
        },
        {
            field: "advertiserCompanyId.value",
            headerName: formatMessage({id: "campaigns.bulkupload.advertiser.cid"}),
        },
        {
            field: "pid.publisherPropertyId",
            headerName: formatMessage({id: "campaigns.bulkupload.publisher.property.id"}),
            validation: numericValidation,
            errorMessage: invalidMessage
        },
        {
            field: "pStartDate",
            headerName: formatMessage({id: "campaigns.bulkupload.placement.start.date"}),
        },
        {
            field: "pEndDate",
            headerName: formatMessage({id: "campaigns.bulkupload.placement.end.date"}),
        },
        {
            field: "fiscalYear",
            headerName: formatMessage({id: "campaigns.bulkupload.fiscal.year"}),
            validation: maxCharacterValidation,
            validationArgs: {maxLength: 500},
            errorMessage: exceededMaxLengthMessage
        },
        {
            field: "fiscalQuarter",
            headerName: formatMessage({id: "campaigns.bulkupload.fiscal.quarter"}),
            validation: maxCharacterValidation,
            validationArgs: {maxLength: 500},
            errorMessage: exceededMaxLengthMessage
        },
        {
            field: "fiscalMonth",
            headerName: formatMessage({id: "campaigns.bulkupload.fiscal.month"}),
            validation: maxCharacterValidation,
            validationArgs: {maxLength: 500},
            errorMessage: exceededMaxLengthMessage
        },
        {
            field: "fiscalWeek",
            headerName: formatMessage({id: "campaigns.bulkupload.fiscal.week"}),
            validation: maxCharacterValidation,
            validationArgs: {maxLength: 500},
            errorMessage: exceededMaxLengthMessage
        },
        {
            field: "placementName",
            headerName: formatMessage({id: "campaigns.bulkupload.placement.name"}),
            validation: maxCharacterValidation,
            validationArgs: {maxLength: 1000},
            errorMessage: exceededMaxLengthMessage
        },
        {
            field: "placementType",
            headerName: formatMessage({id: "campaigns.bulkupload.placement.type"}),
            validation: optionListValidation,
            validationArgs: {acceptedValues: appConstant.PLACEMENT_TYPE},
            errorMessage: invalidMessage
        },
        {
            field: "dynamicOrStatic",
            headerName: formatMessage({id: "campaigns.bulkupload.dynamic.static"}),
            validation: optionListValidation,
            validationArgs: {acceptedValues: appConstant.DYNAMIC_OR_STATIC_FIELD},
            errorMessage: invalidMessage
        },
        {
            field: "personalizedOrTargeted",
            headerName: formatMessage({id: "campaigns.bulkupload.personalized.targeted"}),
            validation: optionListValidation,
            validationArgs: {acceptedValues: appConstant.YES_NO_OPTION},
            errorMessage: invalidMessage
        },
        {
            field: "newOrComped",
            headerName: formatMessage({id: "campaigns.bulkupload.new.or.comped"}),
            validation: optionListValidation,
            validationArgs: {acceptedValues: appConstant.NEW_OR_COMPED_FIELD},
            errorMessage: invalidMessage
        },
        {
            field: "vipContentCampaign",
            headerName: formatMessage({id: "campaigns.bulkupload.vip.content"}),
            validation: optionListValidation,
            validationArgs: {acceptedValues: appConstant.YES_NO_OPTION},
            errorMessage: invalidMessage
        },
        {
            field: "events",
            headerName: formatMessage({id: "campaigns.bulkupload.events"}),
            validation: optionListValidation,
            validationArgs: {acceptedValues: appConstant.EVENTS},
            errorMessage: invalidMessage
        },
        {
            field: "packageName",
            headerName: formatMessage({id: "campaigns.bulkupload.package.name"}),
            validation: maxCharacterValidation,
            validationArgs: {maxLength: 200},
            errorMessage: exceededMaxLengthMessage
        },
        {
            field: "rankofplacement",
            headerName: formatMessage({id: "campaigns.bulkupload.rank.or.spot.number.of.placement.text.field"}),
            validation: nonNegativeNumericValidation,
            errorMessage: zeroOrPositiveNumberMessage
        },
        {
            field: "proposedOfferDescription",
            headerName: formatMessage({id: "campaigns.bulkupload.proposed.offer.used.description.text.field"}),
            validation: maxCharacterValidation,
            validationArgs: {maxLength: 500},
            errorMessage: exceededMaxLengthMessage
        },
        {
            field: "performanceDetails.performanceOriginalPublisherCommission",
            headerName: formatMessage({id: "campaigns.bulkupload.original.publisher.commission"}),
            validation: nonNegativeNumericValidation,
            errorMessage: zeroOrPositiveNumberMessage
        },
        {
            field: "performanceDetails.performanceNewPublisherCommission",
            headerName: formatMessage({id: "campaigns.bulkupload.new.publisher.commission"}),
            validation: nonNegativeNumericValidation,
            errorMessage: zeroOrPositiveNumberMessage
        },
        {
            field: "performanceDetails.performanceOriginalRateCardFlatFeePrice",
            headerName: formatMessage({id: "campaigns.bulkupload.original.flat.fee"}),
            validation: nonNegativeNumericValidation,
            errorMessage: zeroOrPositiveNumberMessage
        },
        {
            field: "performanceDetails.performanceCjNegotiatedFlatFeeCost",
            headerName: formatMessage({id: "campaigns.bulkupload.cj.flat.fee"}),
            validation: nonNegativeNumericValidation,
            errorMessage: zeroOrPositiveNumberMessage
        },
        {
            field: "performanceDetails.baselineValue.isBaselineCalculated",
            headerName: formatMessage({id: "campaigns.bulkupload.use.auto.calculated.baseline"}),
            validation: baselineCalculatedValidation,
            validationArgs: {acceptedValues: appConstant.YES_NO_OPTION}
        },
        {
            field: "performanceDetails.baselineValue.averageBaselineRevenue",
            headerName: formatMessage({id: "campaigns.bulkupload.average.daily.revenue.baseline"}),
            validation: averageBaselineRevenueValidation
        },
        {
            field: "performanceDetails.performanceyoygrowthtrendused",
            headerName: formatMessage({id: "campaigns.bulkupload.yoy.growth.trend"}),
            validation: numericValidation,
            errorMessage: onlyNumberMessage
        },
        {
            field: "performanceDetails.performancePredictedPlacementLift",
            headerName: formatMessage({id: "campaigns.bulkupload.prior.placement.lift"}),
            validation: numericValidation,
            errorMessage: onlyNumberMessage
        },
        {
            field: "performanceDetails.performanceOtherExpectedLift",
            headerName: formatMessage({id: "campaigns.bulkupload.other.expected.lift"}),
            validation: numericValidation,
            errorMessage: onlyNumberMessage
        },
        {
            field: "performanceDetails.performanceSendSizeExpectedUniqueValues",
            headerName: formatMessage({id: "campaigns.bulkupload.send.size"}),
            validation: nonNegativeNumericValidation,
            errorMessage: zeroOrPositiveNumberMessage
        },
        {
            field: "performanceDetails.performanceSovBefore",
            headerName: formatMessage({id: "campaigns.bulkupload.sov.before"}),
            validation: nonNegativeNumericValidation,
            errorMessage: zeroOrPositiveNumberMessage
        },
        {
            field: "performanceDetails.performanceActualSovAfter",
            headerName: formatMessage({id: "campaigns.bulkupload.actual.sov.after"}),
            validation: nonNegativeNumericValidation,
            errorMessage: zeroOrPositiveNumberMessage
        },
        {
            field: "bookingdetails.bookingmarketinsertionorder",
            headerName: formatMessage({id: "campaigns.bulkupload.cj.marketplace.io"}),
            validation: maxCharacterValidation,
            validationArgs: {maxLength: 200},
            errorMessage: exceededMaxLengthMessage
        },
        {
            field: "bookingdetails.bookingotherio",
            headerName: formatMessage({id: "campaigns.bulkupload.publisher.io"}),
            validation: maxCharacterValidation,
            validationArgs: {maxLength: 200},
            errorMessage: exceededMaxLengthMessage
        },
        {
            field: "bookingdetails.bookingissigned",
            headerName: formatMessage({id: "campaigns.bulkupload.io.signed"}),
            validation: optionListValidation,
            validationArgs: {acceptedValues: appConstant.YES_NO_NA_OPTION},
            errorMessage: invalidMessage
        },
        {
            field: "bookingdetails.bookingprogramtermdescription",
            headerName: formatMessage({id: "campaigns.bulkupload.program.term.description"}),
            validation: maxCharacterValidation,
            validationArgs: {maxLength: 500},
            errorMessage: exceededMaxLengthMessage
        },
        {
            field: "bookingdetails.bookingprogramtermname",
            headerName: formatMessage({id: "campaigns.bulkupload.program.term.name"}),
            validation: maxCharacterValidation,
            validationArgs: {maxLength: 500},
            errorMessage: exceededMaxLengthMessage
        },
        {
            field: "bookingdetails.bookingispushed",
            headerName: formatMessage({id: "campaigns.bulkupload.program.term.pushed"}),
            validation: optionListValidation,
            validationArgs: {acceptedValues: appConstant.FLAT_FEE_OPTION},
            errorMessage: invalidMessage
        },
        {
            field: "bookingdetails.bookingduedate",
            headerName: formatMessage({id: "campaigns.bulkupload.asset.due.date"}),
            validation: dateValidation,
            errorMessage: invalidMessage,
            validationArgs: {
                dateFormat: appConstant.DATE_TIME_FORMAT
            }
        },
        {
            field: "bookingdetails.bookingassetsneeded",
            headerName: formatMessage({id: "campaigns.bulkupload.assets.needed"}),
            validation: maxCharacterValidation,
            validationArgs: {maxLength: 500},
            errorMessage: exceededMaxLengthMessage
        },
        {
            field: "bookingdetails.bookingassetsentdate",
            headerName: formatMessage({id: "campaigns.bulkupload.assets.sent.by.advertiser"}),
            validation: dateValidation,
            validationArgs: {
                dateFormat: appConstant.DATE_TIME_FORMAT
            },
            errorMessage: invalidMessage
        },
        {
            field: "bookingdetails.bookingtieredoffer",
            headerName: formatMessage({id: "campaigns.bulkupload.tiered.offer"}),
            validation: maxCharacterValidation,
            validationArgs: {maxLength: 500},
            errorMessage: exceededMaxLengthMessage
        },
        {
            field: "bookingdetails.bookingoffertype",
            headerName: formatMessage({id: "campaigns.bulkupload.offer.type"}),
            validation: optionListValidation,
            validationArgs: {acceptedValues: appConstant.OFFER_TYPE_FIELD},
            errorMessage: invalidMessage
        },
        {
            field: "bookingdetails.bookingoffervalue",
            headerName: formatMessage({id: "campaigns.bulkupload.offer.value"}),
            validation: maxCharacterValidation,
            validationArgs: {maxLength: 200},
            errorMessage: exceededMaxLengthMessage
        },
        {
            field: "bookingdetails.bookingoffercondition",
            headerName: formatMessage({id: "campaigns.bulkupload.offer.conditions"}),
            validation: optionListValidation,
            validationArgs: {acceptedValues: appConstant.OFFER_CONDITION_FIELD},
            errorMessage: invalidMessage
        },
        {
            field: "bookingdetails.bookingofferthreshold",
            headerName: formatMessage({id: "campaigns.bulkupload.offer.threshold"}),
            validation: maxCharacterValidation,
            validationArgs: {maxLength: 500},
            errorMessage: exceededMaxLengthMessage
        },
        {
            field: "bookingdetails.bookingincentiveamount",
            headerName: formatMessage({id: "campaigns.bulkupload.cashback.amount.onsite"}),
            validation: maxCharacterValidation,
            validationArgs: {maxLength: 200},
            errorMessage: exceededMaxLengthMessage
        },
        {
            field: "bookingdetails.bookinglinkid",
            headerName: formatMessage({id: "campaigns.bulkupload.link.id"}),
            validation: maxCharacterValidation,
            validationArgs: {maxLength: 200},
            errorMessage: exceededMaxLengthMessage
        },
        {
            field: "bookingdetails.bookinginvoicemonth",
            headerName: formatMessage({id: "campaigns.bulkupload.cj.invoice.month"}),
            validation: maxCharacterValidation,
            validationArgs: {maxLength: 500},
            errorMessage: exceededMaxLengthMessage
        },
        {
            field: "validationDetails.publisherusedspecifiedlinkid",
            headerName: formatMessage({id: "campaigns.bulkupload.publisher.used.link.id"}),
            validation: optionListValidation,
            validationArgs: {acceptedValues: appConstant.YES_NO_NA_OPTION},
            errorMessage: invalidMessage
        },
        {
            field: "validationDetails.wascorrectofferused",
            headerName: formatMessage({id: "campaigns.bulkupload.correct.offer.used.label"}),
            validation: optionListValidation,
            validationArgs: {acceptedValues: appConstant.YES_NO_NA_OPTION},
            errorMessage: invalidMessage
        },
        {
            field: "validationDetails.description",
            headerName: formatMessage({id: "campaigns.bulkupload.offer.description.text"}),
            validation: maxCharacterValidation,
            validationArgs: {maxLength: 500},
            errorMessage: exceededMaxLengthMessage
        },
        {
            field: "validationDetails.screenshotlink",
            headerName: formatMessage({id: "campaigns.bulkupload.path.to.saved.email"}),
            validation: maxCharacterValidation,
            validationArgs: {maxLength: 500},
            errorMessage: exceededMaxLengthMessage
        },
        {
            field: "validationDetails.validationnotes",
            headerName: formatMessage({id: "campaigns.bulkupload.validation.notes"}),
            validation: maxCharacterValidation,
            validationArgs: {maxLength: 500},
            errorMessage: exceededMaxLengthMessage
        },
        {
            field: "paymentDetails.paidInCJ",
            headerName: formatMessage({id: "campaigns.bulkupload.paid.in.cj"}),
            validation: optionListValidation,
            validationArgs: {acceptedValues: appConstant.PAID_IN_CJ_OPTION},
            errorMessage: invalidMessage
        },
        {
            field: "paymentDetails.transactionid",
            headerName: formatMessage({id: "campaigns.bulkupload.payment.oid"}),
            validation: maxCharacterValidation,
            validationArgs: {maxLength: 500},
            errorMessage: exceededMaxLengthMessage
        },
        {
            field: "paymentDetails.amount",
            headerName: formatMessage({id: "campaigns.bulkupload.amount.paid"}),
            validation: nonNegativeNumericValidation,
            errorMessage: zeroOrPositiveNumberMessage
        },
        {
            field: "paymentDetails.transactiondate",
            headerName: formatMessage({id: "campaigns.bulkupload.payment.date"}),
            validation: dateValidation,
            validationArgs: {dateFormat: appConstant.YYYY_MM_DD},
            errorMessage: invalidMessage
        },
        {
            field: "paymentDetails.notes",
            headerName: formatMessage({id: "campaigns.bulkupload.payment.notes"}),
            validation: maxCharacterValidation,
            validationArgs: {maxLength: 4000},
            errorMessage: exceededMaxLengthMessage
        }
    ];
}
export default BulkUploadDataTableColumnsTranslation;
