import {Modal} from "@cjdev-internal/visual-stack-x/Modal";
import {Text} from "@cjdev-internal/visual-stack-x/Text";
import {LoadingAnimation} from "@cjdev-internal/visual-stack-x/LoadingAnimation";
import {useBulkUploadContext} from "../../BulkUploadContext/BulkUploadContext";
import {useIntl} from "../../../useIntl";

const SavePlacementProcessing = () => {
    const {bulkUploadDetails} = useBulkUploadContext();
    const intl = useIntl();
    return (
        <>
            <Modal height="126px" minHeight="126px" width="465px">
                <div style={{textAlign: "center"}}>
                    <LoadingAnimation
                        loadingMessage={intl.formatMessage({id: "campaigns.bulkupload.uploading.placements"})}/>
                    <div style={{textAlign: "center"}}>
                        <Text size={13}
                              color="subdued">{bulkUploadDetails.successfulRows.length + bulkUploadDetails.failedRows.length} of {bulkUploadDetails.rows.length}</Text>
                    </div>
                    <div>
                        <Text size={13}
                              color="subdued">{intl.formatMessage({id: "campaigns.bulkupload.saving.placement.do.not.close.window"})}</Text>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default SavePlacementProcessing;