import {Heading} from "@cjdev-internal/visual-stack-x/Heading";
import {MDIcon} from "@cjdev-internal/visual-stack-x/MDIcon";
import {Text} from "@cjdev-internal/visual-stack-x/Text";
import {Modal} from "@cjdev-internal/visual-stack-x/Modal";
import {useIntl} from "../../../useIntl";

const UploadNotification = () => {
    const intl = useIntl();
    return (
        <Modal height="110px" minHeight="110px" width="465px">
            <div className='notificationModal' style={{textAlign: "center"}}>
                <MDIcon
                    size={24}
                    animation="beat"
                    icon="progress-upload"
                />
                <div className='heading'>
                    <Heading size={16}
                             color="default">{intl.formatMessage({id: "campaigns.bulkupload.checking.file"})}</Heading>
                </div>
                <div className='description'>
                    <Text size={13}
                          color="subdued">{intl.formatMessage({id: "campaigns.bulkupload.do.not.close.window"})}</Text>
                </div>
            </div>
        </Modal>
    )
}

export default UploadNotification;