import React, {useEffect, useState} from "react";
import {useIntl} from "../../useIntl";
import {SearchFilter} from "@cjdev-internal/visual-stack-x/DataFilter";

const PublisherSelectionDropdown = (getPublishers, debouncedFetch) => {
    return function PublisherSelectionDropdown({
                                                   selectedAdvertiserFilterList,
                                                   setSelectedPublisherFilterList,
                                                   config
                                               }) {
        const [noOptionsMessage, setNoOptionsMessage] = useState("");
        const intl = useIntl();

        const convertSelectedPublisherFilterList = (publisherList) => {
            return publisherList.map((publisher) => ({
                value: publisher.id,
                label: `${publisher.name} (${publisher.id})`,
            }));
        }

        const constructPublisherOptions = async (value) => {

            const advertiserCids = selectedAdvertiserFilterList.map(advertiser => advertiser.value)
            const publisherList = await getPublishers(value, advertiserCids);

            if (publisherList.length <= 0) {
                setNoOptionsMessage(intl.formatMessage({id: 'campaigns.filter.publishers.no.match.found'}));
                return [];
            } else {
                return convertSelectedPublisherFilterList(publisherList);
            }
        };

        const publisherLoadOptions = (searchTerm) => {
            if (selectedAdvertiserFilterList.length !== 0) {
                if (searchTerm.length > 2) {
                    return debouncedFetch(constructPublisherOptions, searchTerm)
                }
            }
            return new Promise(resolve => resolve(null));
        }

        useEffect(() => {
            if (selectedAdvertiserFilterList.length > 0) {
                setNoOptionsMessage(intl.formatMessage({id: 'campaigns.filter.type.to.search'}));
            } else {
                setNoOptionsMessage(intl.formatMessage({id: 'campaigns.filter.select.advertisers'}));
            }
        }, [selectedAdvertiserFilterList]);


        useEffect(() => {
            if (config.tempStates && config.tempStates["publisherSelector"]) {
                setSelectedPublisherFilterList(config.tempStates["publisherSelector"].optionsSelected);
            }
        }, [config]);

        useEffect(() => {
            if (selectedAdvertiserFilterList.length === 0) {
                setSelectedPublisherFilterList([]);
                if (config.tempStates && config.tempStates["publisherSelector"]) {
                    config.tempStates["publisherSelector"].optionsSelected = [];
                }
            }
        }, [selectedAdvertiserFilterList]);

        return (
            <SearchFilter
                id="publisherSelector"
                label={intl.formatMessage({id: 'campaigns.filter.publishers'})}
                placeholder={intl.formatMessage({id: 'campaigns.filter.select.publisher'})}
                loadOptions={publisherLoadOptions}
                noOptionsText={noOptionsMessage}
                intl={intl}
                hideIncludeExclude={true}
            />
        )
    }

}
export default PublisherSelectionDropdown;

