const getPlatformDomains = () => {
    if (process.env.REACT_APP_ENV === 'production') {
        return
    }
    return {
        members: 'lab103',
        accounts: 'dev',
        campaigns: 'dev',
        integrations: 'dev',
        partners: 'dev',
        programs: 'dev',
        pubtag: 'dev',
    }
}

export default getPlatformDomains;