import React, {useState} from "react";
import {PlatformApp} from '@platform/cj-platform-navigation'
import Content from "./components/Content/Content";
import {UserContextProvider} from "./context/UserContextProvider";
import {Filters} from "./components/Filters/Filters";
import CampaignsProvider from "./components/CampaignsContext/CampaignsProvider";
import * as util from "./utils/util";
import {
    authenticatedFetch,
    debouncedFetch,
    fetchPublishersBySearchTerm,
    getApolloClient,
    getPublishers,
    getUser,
    sortAndRemoveDuplicates
} from "./utils/services";
import {ApolloProvider} from "@apollo/client";
import {Provider} from "react-redux";
import {DateFilter} from "./components/DateFilter/DateFilter";
import * as appConstant from "./constants/appConstant";
import {initializePendo} from "./components/Pendo/pendo";
import PlacementModalDialog from "./components/CreateRecord/PlacementModalDialog";
import {combineReducers, createStore} from "redux";
import PublisherSelectionDropdown from "./components/FilterNav/PublisherSelectionDropdown";
import AccountSelectionDropdown from "./components/FilterNav/AccountSelectionDropdown";
import Dimensions from "./components/Dimensions/Dimensions";
import MediaTrackerAppNewNav from "./MediaTrackerAppNewNav";
import ActionButtons from "./components/ActionButtons/ActionButtons";
import RemoveRecordModal from "./components/RemoveRecordModal/RemoveRecordModal";
import PlacementDialogDuplicateSubmitAction from "./components/CreateRecord/PlacementDialogDuplicateSubmitAction";
import PlacementDialogSubmitEditAction from "./components/CreateRecord/PlacementDialogSubmitEditAction";
import DecideRoute from "./components/Routes/DecideRoute";
import {Box} from "@cjdev-internal/visual-stack-x/Box";
import {LoadingAnimation} from "@cjdev-internal/visual-stack-x/LoadingAnimation";
import CampaignsDataTable from "./components/DataTable/CampaignsDataTable";
import openModalDialogProvider from "./components/CreateRecord/OpenModalDialogProvider";
import {useModal} from "@cjdev-internal/visual-stack-x/Modal";
import {useAlert} from "@cjdev-internal/visual-stack-x/Alert";
import AddPlacementButton from "./components/AddPlacementButton/AddPlacementButton";
import placementDialogSubmitAction from "./components/CreateRecord/PlacementDialogSubmitAction";
import PlacementDialogSubmitAction from "./components/CreateRecord/PlacementDialogSubmitAction";
import SearchPanel from "./components/DataTable/SearchPanel";
import getPlatformDomains from "./navigation/getPlatformDomains";
import BulkUpload from "./components/BulkUpload/BulkUpload";
import BulkUploadPageContent from "./components/BulkUpload/BulkUploadPageContent";
import {
    SAVE_PROCESSING_MODAL,
    SAVE_SUMMARY_MODAL,
    UPLOAD_NOTIFICATION_MODAL
} from "./components/BulkUpload/BulkUploadConstant";
import BulkUploadDataTable from "./components/BulkUpload/BulkUploadDataTable";
import {Row} from "@cjdev-internal/visual-stack-x/Row";
import bulkUploadValidationProvider from "./components/BulkUpload/Validation/bulkUploadValidationProvider";
import dataTypeValidator from "./components/BulkUpload/Validation/dataTypeValidator";
import pidValidation from "./components/BulkUpload/Validation/pidValidation";
import * as formatFunctions from "./utils/formatFunctions";
import getYOYDetails from "./components/BulkUpload/Validation/getYOYDetails";
import getBaselineDetails from "./components/BulkUpload/Validation/getBaselineDetails";
import PlacementDataParser from "./components/BulkUpload/PlacementDataParser";
import {BulkUploadContextProvider} from "./components/BulkUploadContext/BulkUploadContext";
import {ErrorPage} from "@cjdev-internal/visual-stack-x/ErrorPage";
import UploadNotification from "./components/BulkUpload/modalContent/UploadNotification";
import DownloadContentIcon from "./components/BulkUpload/DownloadContentIcon";
import SavePlacementProcessing from "./components/BulkUpload/modalContent/SavePlacementProcessing";
import SavePlacementSummary from "./components/BulkUpload/modalContent/SavePlacementSummary";
import {BulkUploadAndDownloadDropdown} from "./components/DropdownMenu/BulkUploadAndDownloadDropdown";
import {translatedMessagesByLanguage} from "./translations";
import {useIntl} from "./useIntl";
import DecideRightPanelIconVisibility from "./components/Filters/DecideRightPanelIconVisibility";
import ValidationIcon from "./components/BulkUpload/ValidationIcon";
import BulkUploadDataTableColumnsTranslation from "./components/BulkUpload/BulkUploadDataTableColumnsTranslation";
import {CampaignsDataTableColumnTranslation} from "./components/DataTable/CampaignsDataTableColumnTranslation";
import {campaignsTranslatedDropdownList} from "./components/CreateRecord/CampaignsTranslatedDropdownList";
import BlankSlate from "./components/BlankSlate/BlankSlate";
import RegisterAdvertiserModal from "./components/BlankSlate/RegisterAdvertiserModal";
import {useToast} from "@cjdev-internal/visual-stack-x/Toast";
import BlankSlateNavigationModal from "./components/BlankSlate/BlankSlateNavigationModal";
import BulkUploadTemplateTranslations from "./components/BulkUpload/BulkUploadTemplateTranslations";

const CampaignsPlatformNavigation = () => {

    const client = getApolloClient();

    const BulkUploadAndDownloadWrapper = <BulkUploadAndDownloadDropdown util={util}/>;

    const _fetchPublishersBySearchTerm = fetchPublishersBySearchTerm(authenticatedFetch, sortAndRemoveDuplicates);

    const _getPublishers = getPublishers(_fetchPublishersBySearchTerm);

    const PublisherSelectionDropdownWrapper = PublisherSelectionDropdown(_getPublishers, debouncedFetch);

    const FiltersWrapper = <Filters
        PublisherSelectionDropdown={PublisherSelectionDropdownWrapper}
        AccountSelectionDropdown={AccountSelectionDropdown} getUserView={util.getUserView} client={client}
        campaignsTranslatedDropdownList={campaignsTranslatedDropdownList}/>;

    const DimensionsWrapper = <Dimensions DateFilter={DateFilter}/>;

    const AddPlacementButtonWrapper = (
        <Row gap={"medium"}>
            <AddPlacementButton util={util} openModalDialogProvider={openModalDialogProvider}
                                PlacementDialogSubmitAction={PlacementDialogSubmitAction}
                                placementModalDialog={PlacementModalDialog}
                                campaignsTranslatedDropdownList={campaignsTranslatedDropdownList}/>
        </Row>
    )

    const reducer = combineReducers({});
    const store = createStore(reducer);
    const LoadingComponent = (
        <Box direction="row" justify="center"
             className="loadingAnimationBox"><LoadingAnimation/>
        </Box>
    );

    const UnauthorizedComponent = (
        <ErrorPage autoPosition={true}
                   errorCode={403}/>
    );

    const CampaignsMaintenance = (
        <ErrorPage autoPosition={true}
                   errorCode={999}/>
    );

    const routes =
        [{
            titleMessage: {
                id: "campaigns.apps.title",
                defaultMessage: "Planning & Performance"
            },
            path: "/",
            layout: {
                filters: true,
                dimensions: true,
                Buttons: true,
                pageTitle: true,
                sidenav: false
            },
            rightPanelIconVisible: () => {
                return DecideRightPanelIconVisibility()
            },
            Filters: () => FiltersWrapper,
            Buttons: () => (
                <ActionButtons CreateRecordModal={AddPlacementButtonWrapper}
                               BulkUploadAndDownloadDropDown={BulkUploadAndDownloadWrapper}
                               client={client}/>),
            Dimensions: () => DimensionsWrapper,
            Content: ({user}) => {
                const intl = useIntl();
                const [modalMount, openModal, closeModal] = useModal();
                const [alertMount, openAlert, closeAlert] = useAlert();
                const [mountTopToast, showTopToast] = useToast(true);
                const [showRegisterAdvertiserModal, setShowRegisterAdvertiserModal] = useState(false);
                const [showBlankSlateNavigationModal, setShowBlankSlateNavigationModal] = useState(false);

                const datagridModalConfiguration = {
                    [appConstant.PLACEMENT_MODE_EDIT]: {
                        ModalContent: PlacementModalDialog,
                        props: {
                            takeAction: PlacementDialogSubmitEditAction,
                            showToast: showTopToast,
                            placementModalTitle: intl.formatMessage({id: "campaigns.edit.record.title"}),
                            modeOfPlacement: appConstant.PLACEMENT_MODE_EDIT,
                            campaignsTranslatedDropdownList: campaignsTranslatedDropdownList
                        }
                    },
                    [appConstant.PLACEMENT_MODE_DUPLICATE]: {
                        ModalContent: PlacementModalDialog,
                        props: {
                            takeAction: PlacementDialogDuplicateSubmitAction,
                            showToast: showTopToast,
                            placementModalTitle: intl.formatMessage({id: "campaigns.duplicate.record.title"}),
                            modeOfPlacement: appConstant.PLACEMENT_MODE_DUPLICATE,
                            campaignsTranslatedDropdownList: campaignsTranslatedDropdownList
                        }
                    },
                    [appConstant.PLACEMENT_MODE_REMOVE]: {
                        ModalContent: RemoveRecordModal,
                        props: {
                            showTopToast: showTopToast
                        }
                    }
                };
                const dataTableColumns = CampaignsDataTableColumnTranslation(intl.formatMessage);
                const openModalDialog = openModalDialogProvider(openModal, closeModal, openAlert, closeAlert,
                    datagridModalConfiguration, util.getBearerToken, util.deSanitizeObject, user, intl.formatMessage);
                const MediaTrackerAppWrapper = (
                    <MediaTrackerAppNewNav
                        getUser={getUser}
                        CampaignsDataTable={CampaignsDataTable}
                        columns={dataTableColumns}
                        openModalDialog={openModalDialog}
                        searchPanel={SearchPanel}
                        getCompaniesDetails={util.getCompaniesDetails}
                        getNonEmptyVariables={util.getNonEmptyVariables}
                        initializePendo={initializePendo}
                    />
                );
                const addPlacementModalConfiguration = {
                    [appConstant.PLACEMENT_MODE_CREATE]: {
                        ModalContent: PlacementModalDialog,
                        props: {
                            takeAction: PlacementDialogSubmitAction,
                            showToast: showTopToast,
                            placementModalTitle: intl.formatMessage({id: "campaigns.create.record.title"}),
                            modeOfPlacement: appConstant.PLACEMENT_MODE_CREATE,
                            campaignsTranslatedDropdownList: campaignsTranslatedDropdownList
                        }
                    }
                }
                const openAddPlacementModalDialog = openModalDialogProvider(openModal, closeModal, openAlert, closeAlert,
                    addPlacementModalConfiguration, util.getBearerToken, util.deSanitizeObject, user, intl.formatMessage);
                const registerAdvertiserModalWrapper = (
                    <RegisterAdvertiserModal showTopToast={showTopToast}
                                             setRegisterAdvertiserModal={setShowRegisterAdvertiserModal}
                                             setShowBlankSlateNavigationModal={setShowBlankSlateNavigationModal}/>)
                const blankSlateNavigationModalWrapper = (<BlankSlateNavigationModal/>)
                const BlankSlateComponent = (<BlankSlate openModalDialog={registerAdvertiserModalWrapper}
                                                         openBlankSlateNavigationModal={blankSlateNavigationModalWrapper}
                                                         showRegisterAdvertiserModal={showRegisterAdvertiserModal}
                                                         setRegisterAdvertiserModal={setShowRegisterAdvertiserModal}
                                                         showBlankSlateNavigationModal={showBlankSlateNavigationModal}/>);
                return (<>
                    {modalMount}
                    {alertMount}
                    {mountTopToast}
                    <Content user={user} MediaTrackerApp={MediaTrackerAppWrapper}
                             UnauthorizedComponent={UnauthorizedComponent}
                             BlankSlateComponent={BlankSlateComponent}
                             LoadingComponent={LoadingComponent}
                             MaintenanceComponent={CampaignsMaintenance}
                             openModalDialog={openAddPlacementModalDialog}
                    />
                </>)
            }
        }, {
            titleMessage: {
                id: "campaigns.apps.title",
                defaultMessage: "Planning & Performance"
            },
            path: appConstant.ROUTE_BULK_UPLOAD,
            layout: {
                filters: false,
                dimensions: false,
                Buttons: false,
                pageTitle: false,
                sidenav: false
            },
            Buttons: () => (<ActionButtons CreateRecordModal={AddPlacementButtonWrapper}
                                           BulkUploadAndDownloadDropDown={BulkUploadAndDownloadWrapper}/>),
            Dimensions: () => DimensionsWrapper,
            Content: ({user}) => {
                const intl = useIntl();
                const [modalMount, openModal, closeModal] = useModal();
                const [uploadModalMount, uploadOpenModal, uploadCloseModal] = useModal();
                const [alertMount, openAlert, closeAlert] = useAlert();
                const [uploadAlertMount] = useAlert();
                const [mountTopToast, showTopToast] = useToast(true);
                const [showRegisterAdvertiserModal, setShowRegisterAdvertiserModal] = useState(false);
                const [showBlankSlateNavigationModal, setShowBlankSlateNavigationModal] = useState(false);

                const bulkUploadModalConfiguration = {
                    [UPLOAD_NOTIFICATION_MODAL]: {
                        isBulkUploadPopup: true,
                        props: {},
                        ModalContent: UploadNotification
                    },
                    [SAVE_PROCESSING_MODAL]: {
                        isBulkUploadPopup: true,
                        ModalContent: SavePlacementProcessing
                    },
                    [SAVE_SUMMARY_MODAL]: {
                        isBulkUploadPopup: true,
                        ModalContent: SavePlacementSummary
                    }
                };
                const bulkUploadTemplateTranslations = BulkUploadTemplateTranslations(intl.formatMessage);
                const bulkUploadDataTableColumns = BulkUploadDataTableColumnsTranslation(intl.formatMessage);
                const bulkUploadDataTableColumnsWithValidation = [{
                    field: "isValid", headerName: '', width: 60, cellRenderer: ValidationIcon, valueGetter: () => {
                        return ""
                    }
                }, ...bulkUploadDataTableColumns]
                const downloadIconWrapper = ({api}) => <DownloadContentIcon api={api}
                                                                            downloadTemplateAndFailedPlacement={util.downloadTemplateAndFailedPlacement}
                                                                            bulkUploadDataTableColumns={bulkUploadDataTableColumns}/>
                const BulkUploadDataTableWrapper = BulkUploadDataTable(bulkUploadDataTableColumnsWithValidation,
                    downloadIconWrapper);
                const typeValidation = dataTypeValidator(bulkUploadDataTableColumns, intl.formatMessage);
                const pidValidationWrapper = pidValidation(client, intl.formatMessage);
                const yoyDetails = getYOYDetails(client, intl.formatMessage);
                const baselineDetails = getBaselineDetails(client, intl.formatMessage);
                const bulkUploadValidation = bulkUploadValidationProvider(util.formValidate, typeValidation, pidValidationWrapper, yoyDetails, baselineDetails, formatFunctions.bulkUploadErrorsFormatter, formatFunctions.bulkUploadWarningFormatter, intl.formatMessage);
                const uploadModalDialog = openModalDialogProvider(uploadOpenModal, uploadCloseModal, {}, {}, bulkUploadModalConfiguration, {}, util.deSanitizeObject, user);
                const addPlacementModalConfigurationForBulkUpload = {
                    [appConstant.PLACEMENT_MODE_CREATE]: {
                        ModalContent: PlacementModalDialog,
                        props: {
                            takeAction: PlacementDialogSubmitAction,
                            showToast: showTopToast,
                            placementModalTitle: intl.formatMessage({id: "campaigns.create.record.title"}),
                            modeOfPlacement: appConstant.PLACEMENT_MODE_CREATE,
                            campaignsTranslatedDropdownList: campaignsTranslatedDropdownList
                        }
                    }
                }
                const openAddPlacementModalDialog = openModalDialogProvider(openModal, closeModal, openAlert, closeAlert,
                    addPlacementModalConfigurationForBulkUpload, util.getBearerToken, util.deSanitizeObject, user, intl.formatMessage);
                const registerAdvertiserModalWrapper = (
                    <RegisterAdvertiserModal showTopToast={showTopToast}
                                             setRegisterAdvertiserModal={setShowRegisterAdvertiserModal}
                                             setShowBlankSlateNavigationModal={setShowBlankSlateNavigationModal}/>)
                const blankSlateNavigationModalWrapper = (<BlankSlateNavigationModal/>)
                const BlankSlateComponent = (<BlankSlate openModalDialog={registerAdvertiserModalWrapper}
                                                         openBlankSlateNavigationModal={blankSlateNavigationModalWrapper}
                                                         showRegisterAdvertiserModal={showRegisterAdvertiserModal}
                                                         setRegisterAdvertiserModal={setShowRegisterAdvertiserModal}
                                                         showBlankSlateNavigationModal={showBlankSlateNavigationModal}/>);
                const BulkUploadPageContentWrapper = (<BulkUploadContextProvider>
                        <BulkUpload placementSubmitAction={placementDialogSubmitAction} modalMount={uploadModalMount}
                                    openModalDialog={uploadModalDialog}
                                    closeModal={uploadCloseModal}
                                    alertMount={uploadAlertMount}
                                    showTopToast={showTopToast}>
                            <BulkUploadPageContent BulkUploadDataTableWrapper={BulkUploadDataTableWrapper}
                                                   getTextAfterLastDot={util.getTextAfterLastDot}
                                                   PlacementDataParser={PlacementDataParser}
                                                   bulkUploadValidation={bulkUploadValidation}
                                                   getCompaniesWithIdName={util.getCompaniesWithIdName}
                                                   hasValue={util.hasValue}
                                                   closeModal={uploadCloseModal}
                                                   openModalDialog={uploadModalDialog}
                                                   downloadExcel={util.downloadTemplateAndFailedPlacement}
                                                   bulkUploadDataTableColumns={bulkUploadDataTableColumns}
                                                   bulkUploadTemplateTranslations={bulkUploadTemplateTranslations}
                            />
                        </BulkUpload>
                    </BulkUploadContextProvider>
                );
                return (<>
                    {modalMount}
                    {alertMount}
                    {mountTopToast}
                    <Content user={user} MediaTrackerApp={BulkUploadPageContentWrapper}
                             UnauthorizedComponent={UnauthorizedComponent}
                             BlankSlateComponent={BlankSlateComponent}
                             LoadingComponent={LoadingComponent}
                             MaintenanceComponent={CampaignsMaintenance}
                             openModalDialog={openAddPlacementModalDialog}/>
                </>)
            }
        }]

    return (
        <Provider store={store}>
            <ApolloProvider client={client}>
                <CampaignsProvider getUserLinkedAdvertiser={util.getUserLinkedAdvertiser}>
                    <UserContextProvider>
                        <DecideRoute routes={routes}
                                     PlatformApp={PlatformApp}
                                     getPlatformDomains={getPlatformDomains}
                                     translatedMessagesByLanguage={translatedMessagesByLanguage}/>
                    </UserContextProvider>
                </CampaignsProvider>
            </ApolloProvider>
        </Provider>
    );
}

export default CampaignsPlatformNavigation;
