import {Box} from "@cjdev-internal/visual-stack-x/Box";
import {Card} from "@cjdev-internal/visual-stack-x/Card";
import {Button} from "@cjdev-internal/visual-stack-x/Button";
import {SingleFileUpload} from "@cjdev-internal/visual-stack-x/SingleFileUpload";
import * as XLSX from "xlsx";
import React, {useEffect, useState} from "react";
import {Text} from "@cjdev-internal/visual-stack-x/Text"
import * as BulkUploadConstant from "./BulkUploadConstant";
import "./styles/BulkUploadDataTable.css";
import "./styles/BulkUploadPageContent.css";
import {Grid} from '@cjdev-internal/visual-stack-x/Grid'
import {Notification} from "@cjdev-internal/visual-stack-x/Notification";
import {TextArea} from "@cjdev-internal/visual-stack-x/TextArea";
import {Label} from "@cjdev-internal/visual-stack-x/legacy/Form";
import {useApolloClient} from "@apollo/client";
import {useBulkUploadContext} from "../BulkUploadContext/BulkUploadContext";
import {useIntl} from "../../useIntl";

const BulkUploadPageContent = ({
                                   BulkUploadDataTableWrapper,
                                   getTextAfterLastDot,
                                   PlacementDataParser,
                                   bulkUploadValidation,
                                   getCompaniesWithIdName,
                                   hasValue,
                                   closeModal,
                                   openModalDialog,
                                   downloadExcel,
                                   file,
                                   onChange,
                                   onClear,
                                   inputRef,
                                   bulkUploadDataTableColumns,
                                   bulkUploadTemplateTranslations
                               }) => {
    const [uploadButtonState, setUploadButtonState] = useState(true);
    const [fileUploadHelpState, setFileUploadHelpState] = useState(false);

    const {
        bulkUploadDetails,
        setBulkUploadDetails,
        errorDetails,
        setErrorDetails,
        warningDetails,
        setWarningDetails
    } = useBulkUploadContext();

    useEffect(() => {
        const uploadButtonDisable = file ? file.size > 50000 : true;
        const fileUploadHelpMessage = file && file?.size > 50000;
        setUploadButtonState(uploadButtonDisable);
        setFileUploadHelpState(fileUploadHelpMessage);
        setErrorDetails([]);
        setWarningDetails([]);
        setBulkUploadDetails(() => ({
            rows: [],
            successfulRows: [],
            failedRows: []
        }));
    }, [file])

    const client = useApolloClient();
    useEffect(() => {
        return () => {
            setErrorDetails([]);
            setWarningDetails([]);
        };
    }, []);

    // not testable code
    useEffect(() => {
        function browseButtonClickHandler() {
            inputRef.current.value = ""
        }

        if (inputRef && inputRef.current) {
            inputRef.current?.addEventListener("click", browseButtonClickHandler, false);
            return function cleanup() {
                inputRef.current?.removeEventListener("click", browseButtonClickHandler, false);
            };
        }
    }, []);

    const intl = useIntl();
    const readExcel = async () => {
        const fileReader = await new FileReader();
        fileReader.readAsArrayBuffer(file)
        fileReader.onload = (e) => {
            const bufferArray = e?.target.result
            const wb = XLSX.read(bufferArray, {type: "buffer"});
            populateGrid(wb);
        }
    }
    const populateGrid = async (workbook) => {
        // our data is in the first sheet
        openModalDialog(BulkUploadConstant.UPLOAD_NOTIFICATION_MODAL);
        try {
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];

            //1st approach to read data from xlsx
            const header = ['', ...getTextAfterLastDot(bulkUploadDataTableColumns)];
            const rows = XLSX.utils.sheet_to_json(worksheet, {
                header,
                range: BulkUploadConstant.TEMPLATE_START_ROW_NUMBER - 1,
                raw: false
            });
            const companiesWithIdName = await getCompaniesWithIdName(client);
            let updatedRows = [];
            const updatedCompanyIds = new Set();
            setWarningDetails([]);
            for (let index = 0; index < rows.length; index++) {
                const row = rows[index];
                const advertiserId = row.value;
                if (advertiserId && !companiesWithIdName.has(advertiserId)) {
                    setErrorDetails([intl.formatMessage({id: "campaigns.bulkupload.invalid.advertiser.error"})
                        .replace('{advertiserCompanyID}', advertiserId)]);
                    closeModal();
                    return;
                }

                //checking for empty cell
                if (hasValue(advertiserId)) {
                    updatedCompanyIds.add(advertiserId);
                    if (updatedCompanyIds.size > 1) {
                        setErrorDetails([intl.formatMessage({id: "campaigns.bulkupload.same.advertiser.error"})]);
                        closeModal();
                        return;
                    }
                }

                const advertiserName = companiesWithIdName.get(advertiserId);
                const parsedRow = PlacementDataParser(row, index, advertiserName);
                updatedRows.push(parsedRow)
            }
            const {validationErrorList, validationWarningList} = await bulkUploadValidation(updatedRows);
            setBulkUploadDetails((prevState) => ({...prevState, rows: updatedRows}));
            setErrorDetails(validationErrorList);
            setWarningDetails(validationWarningList);
            closeModal();
        } catch (error) {
            console.error("error in file validation: ", error.message);
            closeModal();
        }
    }

    const getErrorWarningDetails = (errorDetails, warningDetails) => {
        return errorDetails.length > 0 ? (errorDetails.join('\n\n')) : warningDetails.join('\n\n');
    }

    return (
        <>
            <Box padding='xl'>
                <Card>
                    <Box direction="column" gap="large">
                        <Box direction="row">
                            <Text id="bulk-upload-label" size={16}
                                  weight="medium">{intl.formatMessage({id: "campaigns.bulkupload.bulk.upload"})}</Text>
                        </Box>
                        <Box direction="row">
                            <Text id="bulk-upload-help-message" size={13} weight="regular" color="secondary">
                                {intl.formatMessage({id: "campaigns.bulkupload.bulk.upload.help.message"})}
                                <div>
                                    <Text id="bulk-upload-download-link" size={13} weight="regular" color="link"
                                          onClick={() => downloadExcel('', BulkUploadConstant.TEMPLATE_FILENAME,
                                              BulkUploadConstant.TEMPLATE_SHEET_NAME, bulkUploadDataTableColumns, bulkUploadTemplateTranslations)}>
                                        {intl.formatMessage({id: "campaigns.bulkupload.bulk.upload.link"})}
                                    </Text>
                                </div>
                            </Text>
                        </Box>
                        <Box direction="column" gap="medium">
                            <Box direction="row" gap='medium'>
                                <Grid gap="medium" columns="14fr 1fr">
                                    <SingleFileUpload id="bulkUploadFile" file={file} onChange={onChange}
                                                      onClear={onClear} accept={".xlsx"} inputRef={inputRef}
                                                      intl={intl}/>
                                    <Button type="secondary" onClick={readExcel} id="upload-button"
                                            disabled={uploadButtonState}>
                                        {intl.formatMessage({id: "campaigns.bulkupload.add.file"})} </Button>
                                </Grid>
                            </Box>
                            <Text id="file-size-message"
                                  className={fileUploadHelpState ? "inValidFileSize" : "validFileSize"}>
                                {fileUploadHelpState ?
                                    intl.formatMessage({id: "campaigns.bulkupload.file.size.error.message"})
                                    : intl.formatMessage({id: "campaigns.bulkupload.file.size.default.message"})}
                            </Text>
                        </Box>
                        {file && (errorDetails?.length > 0 || warningDetails?.length > 0) ?
                            (
                                <Box direction="column" gap="large">
                                    <Notification border id="error-warning-message"
                                                  type={errorDetails.length > 0 ? "warning" : "alert"}>
                                        {errorDetails?.length > 0 ?
                                            (intl.formatMessage({id: "campaigns.bulkupload.error.message"}).replace('{errorRowCount}', errorDetails.length)) :
                                            (intl.formatMessage({id: "campaigns.bulkupload.warning.message"}).replace('{warningRowCount}', warningDetails.length))}
                                    < /Notification>
                                    <Box>
                                        <Label>{intl.formatMessage({id: "campaigns.bulkupload.error.warning.details"})}</Label>
                                        <TextArea id="error-warning-details" resize={"vertical"}
                                                  value={getErrorWarningDetails(errorDetails, warningDetails)}
                                                  onChange={() => {
                                                  }}
                                        />
                                    </Box>
                                </Box>
                            ) : ""
                        }
                    </Box>
                </Card>
                {
                    (bulkUploadDetails?.rows?.length > 0) ?
                        <Card id={"BulkUploadDataTable"}>
                            <BulkUploadDataTableWrapper values={bulkUploadDetails.rows} title={file?.name}/>
                        </Card> : ""
                }
            </Box>
        </>
    )
}

export default BulkUploadPageContent;
