export const campaignsDropdownComparatorList = (formatMessage) => {
    return [
        {
            value: "",
            label: formatMessage({id: "campaigns.dropdown.options.select"})
        },
        {
            value: "BRAND_CARD_SIGNUP_SPECIFIC",
            label: formatMessage({id: "campaigns.offer.condition.options.brand.card.signup"})
        },
        {
            value: "BRAND_CARD_SPECIFIC",
            label: formatMessage({id: "campaigns.offer.condition.options.brand.card.specific"})
        },
        {
            value: "COMBO CODE",
            label: formatMessage({id: "campaigns.offer.condition.options.combo.code"})
        },
        {
            value: "DEMOGRAPHICS",
            label: formatMessage({id: "campaigns.offer.condition.options.demographics"})
        },
        {
            value: "EMAIL_SIGNUP_REQUIRED",
            label: formatMessage({id: "campaigns.offer.condition.options.email.signup.required"})
        },
        {
            value: "LOCATION_SPECIFIC",
            label: formatMessage({id: "campaigns.offer.condition.options.location.specific"})
        },
        {
            value: "LOYALTY_REQUIRED",
            label: formatMessage({id: "campaigns.offer.condition.options.loyalty.required"})
        },
        {
            value: "MEMBERSHIP_REQUIRED",
            label: formatMessage({id: "campaigns.offer.condition.options.membership.required"})
        },
        {
            value: "MULTI_BUY",
            label: formatMessage({id: "campaigns.offer.condition.options.multi.buy"})
        },
        {
            value: "NEW_CUSTOMER_SPECIFIC",
            label: formatMessage({id: "campaigns.offer.condition.options.new.customer.specific"})
        },
        {
            value: "POINT_OF_SALE_SPECIFIC",
            label: formatMessage({id: "campaigns.offer.condition.options.point.of.sale.specific"})
        },
        {
            value: "PRODUCT_SPECIFIC",
            label: formatMessage({id: "campaigns.offer.condition.options.product.specific"})
        },
        {
            value: "PROMO_CODE",
            label: formatMessage({id: "campaigns.offer.condition.options.promo.code"})
        },
        {
            value: "VANITY",
            label: formatMessage({id: "campaigns.offer.condition.options.vanity"})
        },
        {
            value: "N/A",
            label: formatMessage({id: "campaigns.offer.condition.options.na"})
        },
        {
            value: "AMOUNT_OFF",
            label: formatMessage({id: "campaigns.offer.type.options.amount.off"})
        },
        {
            value: "BNPL (Buy Now Pay Later)",
            label: formatMessage({id: "campaigns.offer.type.options.bnpl"})
        },
        {
            value: "BOGO",
            label: formatMessage({id: "campaigns.offer.type.options.bogo"})
        },
        {
            value: "COUPON",
            label: formatMessage({id: "campaigns.offer.type.options.coupon"})
        },
        {
            value: "EVERGREEN",
            label: formatMessage({id: "campaigns.offer.type.options.evergreen"})
        },
        {
            value: "FREE_GIFT",
            label: formatMessage({id: "campaigns.offer.type.options.free.gift"})
        },
        {
            value: "FREE_SHIP",
            label: formatMessage({id: "campaigns.offer.type.options.free.ship"})
        },
        {
            value: "INTRODUCTORY_OFFER",
            label: formatMessage({id: "campaigns.offer.type.options.introductory.offer"})
        },
        {
            value: "PERCENT_OFF",
            label: formatMessage({id: "campaigns.offer.type.options.percent.off"})
        },
        {
            value: "PRODUCT",
            label: formatMessage({id: "campaigns.offer.type.options.product"})
        },
        {
            value: "PUBLISHER EXCLUSIVE",
            label: formatMessage({id: "campaigns.offer.type.options.publisher.exclusive"})
        },
        {
            value: "SUBSCRIPTION",
            label: formatMessage({id: "campaigns.offer.type.options.subscription"})
        },
        {
            value: 'August Bank Holiday',
            label: formatMessage({id: "campaigns.events.options.august.bank.holiday"})
        },
        {
            value: 'Back to School',
            label: formatMessage({id: "campaigns.events.options.back.to.school"})
        },
        {
            value: 'Black Friday',
            label: formatMessage({id: "campaigns.events.options.black.friday"})
        },
        {
            value: 'Blue Monday (January)',
            label: formatMessage({id: "campaigns.events.options.blue.monday"})
        },
        {
            value: 'Boxing Day',
            label: formatMessage({id: "campaigns.events.options.boxing.day"})
        },
        {
            value: 'Cheltenham Festival',
            label: formatMessage({id: "campaigns.events.options.cheltenham.festival"})
        },
        {
            value: 'Christmas',
            label: formatMessage({id: "campaigns.events.options.christmas"})
        },
        {
            value: 'Cyber Monday',
            label: formatMessage({id: "campaigns.events.options.cyber.monday"})
        },
        {
            value: 'Cyber/Giving Tuesday',
            label: formatMessage({id: "campaigns.events.options.cyber.giving.tuesday"})
        },
        {
            value: 'Early May Bank Holiday',
            label: formatMessage({id: "campaigns.events.options.early.may.bank.holiday"})
        },
        {
            value: 'Easter',
            label: formatMessage({id: "campaigns.events.options.easter"})
        },
        {
            value: 'FA Cup',
            label: formatMessage({id: "campaigns.events.options.fa.cup"})
        },
        {
            value: 'Father\'s Day',
            label: formatMessage({id: "campaigns.events.options.fathers.day"})
        },
        {
            value: 'Grand National',
            label: formatMessage({id: "campaigns.events.options.grand.national"})
        },
        {
            value: 'Halloween',
            label: formatMessage({id: "campaigns.events.options.halloween"})
        },
        {
            value: 'Holiday',
            label: formatMessage({id: "campaigns.events.options.holiday"})
        },
        {
            value: 'Independence Day',
            label: formatMessage({id: "campaigns.events.options.independence.day"})
        },
        {
            value: 'Labor Day',
            label: formatMessage({id: "campaigns.events.options.labor.day"})
        },
        {
            value: 'Late May Bank Holiday',
            label: formatMessage({id: "campaigns.events.options.late.may.bank.holiday"})
        },
        {
            value: 'Lunar New Year',
            label: formatMessage({id: "campaigns.events.options.lunar.new.year"})
        },
        {
            value: 'Memorial Day',
            label: formatMessage({id: "campaigns.events.options.memorial.day"})
        },
        {
            value: 'Mother\'s Day',
            label: formatMessage({id: "campaigns.events.options.mothers.day"})
        },
        {
            value: 'New Year',
            label: formatMessage({id: "campaigns.events.options.new.year"})
        },
        {
            value: 'President\'s Day',
            label: formatMessage({id: "campaigns.events.options.presidents.day"})
        },
        {
            value: 'Product Launch',
            label: formatMessage({id: "campaigns.events.options.product.launch"})
        },
        {
            value: 'Single\'s Day',
            label: formatMessage({id: "campaigns.events.options.singles.day"})
        },
        {
            value: 'St Patrick\'s Day',
            label: formatMessage({id: "campaigns.events.options.st.patricks.day"})
        },
        {
            value: 'Super Bowl',
            label: formatMessage({id: "campaigns.events.options.super.bowl"})
        },
        {
            value: 'Tax Season',
            label: formatMessage({id: "campaigns.events.options.tax.season"})
        },
        {
            value: 'Thanksgiving',
            label: formatMessage({id: "campaigns.events.options.thanksgiving"})
        },
        {
            value: 'Valentine\'s Day',
            label: formatMessage({id: "campaigns.events.options.valentino"})
        },
        {
            value: 'Wimbledon',
            label: formatMessage({id: "campaigns.events.options.wimbledon"})
        },
        {
            value: 'World Cup',
            label: formatMessage({id: "campaigns.events.options.world.cup"})
        },
        {
            value: 'Booked',
            label: formatMessage({id: "campaigns.placement.status.options.booked"})
        }, {
            value: 'Proposed',
            label: formatMessage({id: "campaigns.placement.status.options.proposed"})
        }, {
            value: 'Declined',
            label: formatMessage({id: "campaigns.placement.status.options.declined"})
        },
        {
            value: 'Yes',
            label: formatMessage({id: "campaigns.dropdown.options.yes"})
        },
        {
            value: 'No',
            label: formatMessage({id: "campaigns.dropdown.options.no"})
        },
        {
            value: 'N/A- Flat Fee Only',
            label: formatMessage({id: "campaigns.program.term.options.na.flat.fee.only"})
        },
        {
            value: 'N/A',
            label: formatMessage({id: "campaigns.dropdown.options.na"})
        },
        {
            value: 'Fully Paid',
            label: formatMessage({id: "campaigns.paid.in.cj.options.fully.paid"})
        },
        {
            value: 'Partially Paid',
            label: formatMessage({id: "campaigns.paid.in.cj.options.partially.paid"})
        }, {
            value: 'Not Paid',
            label: formatMessage({id: "campaigns.paid.in.cj.options.not.paid"})
        }, {
            value: 'CPA Increase Only',
            label: formatMessage({id: "campaigns.paid.in.cj.options.cpa.increase.only"})
        }, {
            value: 'Make Good - No Cost',
            label: formatMessage({id: "campaigns.paid.in.cj.options.make.good.no.cost"})
        },
        {
            value: 'Dynamic',
            label: formatMessage({id: "campaigns.dynamic.static.options.dynamic"})
        },
        {
            value: 'Static',
            label: formatMessage({id: "campaigns.dynamic.static.options.static"})
        },
        {
            value: 'Repeated',
            label: formatMessage({id: "campaigns.new.repeated.options.repeated"})
        },
        {
            value: 'New',
            label: formatMessage({id: "campaigns.new.comped.options.new"})
        },
        {
            value: 'App Push',
            label: formatMessage({id: 'campaigns.placement.type.options.app.push'})
        },
        {
            value: 'Article Inclusion',
            label: formatMessage({id: 'campaigns.placement.type.options.article.inclusion'})
        },
        {
            value: 'Cashback Event',
            label: formatMessage({id: 'campaigns.placement.type.options.cashback.event'})
        },
        {
            value: 'Category Leader',
            label: formatMessage({id: 'campaigns.placement.type.options.category.leader'})
        },
        {
            value: 'Category Page',
            label: formatMessage({id: 'campaigns.placement.type.options.category.page'})
        },
        {
            value: 'Content',
            label: formatMessage({id: 'campaigns.placement.type.options.content'})
        },
        {
            value: 'Content - Dedicated Review',
            label: formatMessage({id: 'campaigns.placement.type.options.content.dedicated.review'})
        },
        {
            value: 'Content - Editorial',
            label: formatMessage({id: 'campaigns.placement.type.options.content.editorial'})
        },
        {
            value: 'Content - Gift Guide',
            label: formatMessage({id: 'campaigns.placement.type.options.content.gift.guide'})
        },
        {
            value: 'Content - Multi Merchant Review',
            label: formatMessage({id: 'campaigns.placement.type.options.content.multi.merchant.review'})
        },
        {
            value: 'Coupon Page',
            label: formatMessage({id: 'campaigns.placement.type.options.coupon.page'})
        },
        {
            value: 'Deal Page',
            label: formatMessage({id: 'campaigns.placement.type.options.deal.page'})
        },
        {
            value: 'Double Cashback',
            label: formatMessage({id: 'campaigns.placement.type.options.double.cashback'})
        },
        {
            value: 'Footer Ads',
            label: formatMessage({id: 'campaigns.placement.type.options.footer.ads'})
        },
        {
            value: 'Homepage',
            label: formatMessage({id: 'campaigns.placement.type.options.homepage'})
        },
        {
            value: 'Homepage - Search Keywords',
            label: formatMessage({id: 'campaigns.placement.type.options.homepage.search.keywords'})
        },
        {
            value: 'Homepage Carousel',
            label: formatMessage({id: 'campaigns.placement.type.options.homepage.carousel'})
        },
        {
            value: 'Loyalty',
            label: formatMessage({id: 'campaigns.placement.type.options.loyalty'})
        },
        {
            value: 'Make Good',
            label: formatMessage({id: 'campaigns.placement.type.options.make.good'})
        },
        {
            value: 'Mobile App',
            label: formatMessage({id: 'campaigns.placement.type.options.mobile.app'})
        },
        {
            value: 'Mobile Messaging',
            label: formatMessage({id: 'campaigns.placement.type.options.mobile.messaging'})
        },
        {
            value: 'Mobile Webpage',
            label: formatMessage({id: 'campaigns.placement.type.options.mobile.webpage'})
        },
        {
            value: 'Multi-Merchant Email',
            label: formatMessage({id: 'campaigns.placement.type.options.multi-merchant.email'})
        },
        {
            value: 'Package',
            label: formatMessage({id: 'campaigns.placement.type.options.package'})
        },
        {
            value: 'Pop-Up Ads',
            label: formatMessage({id: 'campaigns.placement.type.options.pop-up.ads'})
        },
        {
            value: 'Search',
            label: formatMessage({id: 'campaigns.placement.type.options.search'})
        },
        {
            value: 'Seasonal Event Page',
            label: formatMessage({id: 'campaigns.placement.type.options.seasonal.event.page'})
        },
        {
            value: 'Social Media - Facebook',
            label: formatMessage({id: 'campaigns.placement.type.options.social.media.facebook'})
        },
        {
            value: 'Social Media - Google+',
            label: formatMessage({id: 'campaigns.placement.type.options.social.media.google'})
        },
        {
            value: 'Social Media - Instagram',
            label: formatMessage({id: 'campaigns.placement.type.options.social.media.instagram'})
        },
        {
            value: 'Social Media - Snapchat',
            label: formatMessage({id: 'campaigns.placement.type.options.social.media.snapchat'})
        },
        {
            value: 'Social Media - TikTok',
            label: formatMessage({id: 'campaigns.placement.type.options.social.media.tiktok'})
        },
        {
            value: 'Social Media - Twitter',
            label: formatMessage({id: 'campaigns.placement.type.options.social.media.twitter'})
        },
        {
            value: 'Social Media - WeChat',
            label: formatMessage({id: 'campaigns.placement.type.options.social.media.wechat'})
        },
        {
            value: 'Social Media - Weibo',
            label: formatMessage({id: 'campaigns.placement.type.options.social.media.weibo'})
        },
        {
            value: 'Social Media - YouTube',
            label: formatMessage({id: 'campaigns.placement.type.options.social.media.youtube'})
        },
        {
            value: 'Social Media - Others',
            label: formatMessage({id: 'campaigns.placement.type.options.social.media.others'})
        },
        {
            value: 'Solo Email',
            label: formatMessage({id: 'campaigns.placement.type.options.solo.email'})
        },
        {
            value: 'Sponsored',
            label: formatMessage({id: 'campaigns.placement.type.options.sponsored'})
        },
        {
            value: 'Staff Picks',
            label: formatMessage({id: 'campaigns.placement.type.options.staff.picks'})
        },
        {
            value: 'Store Page Category',
            label: formatMessage({id: 'campaigns.placement.type.options.store.page.category'})
        },
        {
            value: 'Widget',
            label: formatMessage({id: 'campaigns.placement.type.options.widget'})
        },
        {
            value: 'Other',
            label: formatMessage({id: 'campaigns.placement.type.options.other'})
        }
    ]
}