import React, {useEffect, useState} from "react";
import {useApolloClient} from "@apollo/client";
import {useUserContext} from "../../context/UserContextProvider";
import {useCampaignsContext} from "../CampaignsContext/useCampaignsContext";
import {useIntl} from "../../useIntl";
import {AccountFilter} from "@cjdev-internal/visual-stack-x/DataFilter";
import {getLinkedAdvertiserList} from "../../utils/util";
import * as R from "ramda";

const AccountSelectionDropdown = ({setSelectedAdvertiserFilterList, optionList, setOptionList, config}) => {
    const client = useApolloClient();
    const {userData} = useUserContext();
    const {filterData, setFilterData} = useCampaignsContext();
    const intl = useIntl();

    const loadAdvertiserData = async () => {
        const data = await getLinkedAdvertiserList(client);
        setOptionList(data);
    };

    const isUserDataAvailable = () => {
        return !!(userData !== undefined && userData.currentCompany);
    };

    const getDefaultFilterAdvertiser = (company) => {
        if (isUserDataAvailable()) {
            const currentCompany = company.currentCompany;
            return [
                {
                    companyName: currentCompany.name,
                    label: currentCompany.name + " (" + currentCompany.id + ")",
                    type: currentCompany.type,
                    value: currentCompany.id,
                },
            ];
        }
    };

    const defaultFilterAdvertiser = getDefaultFilterAdvertiser(userData);
    const [allSelectedValues, setAllSelectValues] = useState([]);

    useEffect(() => {
        loadAdvertiserData();
    }, []);

    useEffect(() => {
        setSelectedAdvertiserFilterList(allSelectedValues);
    }, [allSelectedValues]);

    useEffect(() => {
        if (isUserDataAvailable()) {
            const filters = {
                ...filterData,
                advertisers: [userData.currentCompany],
                status: [],
                publishers: [],
            };
            setFilterData(filters);
        }
    }, [userData && userData.currentCompany]);

    useEffect(() => {
        const newDataFunc = (data) => R.assocPath(["account-filter", "optionsSelected"], [defaultFilterAdvertiser[0]], data);
        config.setDefaultStates(newDataFunc);
        config.setTempStates(newDataFunc);
        config.setFilterStates(newDataFunc);
        if (config.tempStates && config.tempStates["account-filter"]) {
            config.filterStates["account-filter"].optionsSelected = [defaultFilterAdvertiser[0]];
        }
    }, [userData]);

    useEffect(() => {
        if (config && config.tempStates && config.tempStates["account-filter"]) {
            setAllSelectValues(config.tempStates["account-filter"].optionsSelected);
        }
    }, [config]);

    return (
        <AccountFilter
            id="account-filter"
            label={intl.formatMessage({id: "campaigns.filter.advertisers"})}
            options={optionList}
            defaultOptions={allSelectedValues}
            startExpanded={false}
        />
    );
};

export default AccountSelectionDropdown;

