export const BULK_UPLOAD_ERROR_MESSAGE = {
    placementStatusId: "campaigns.bulkupload.error.message.placementstatusid",
    advertiserCompanyId: "campaigns.bulkupload.error.message.advertisercompanyid",
    placementName: "campaigns.bulkupload.error.message.placementname",
    publisherPropertyId: "campaigns.bulkupload.error.message.publisherpropertyid",
    placementType: "campaigns.bulkupload.error.message.placementtype",
    dynamicOrStatic: "campaigns.bulkupload.error.message.dynamicorstatic",
    personalizedOrTargeted: "campaigns.bulkupload.error.message.personalizedortargeted",
    newOrComped: "campaigns.bulkupload.error.message.neworcomped",
    vipContentCampaign: "campaigns.bulkupload.error.message.vipcontentcampaign",
    pStartDate: "campaigns.bulkupload.error.message.pstartdate",
    pEndDate: "campaigns.bulkupload.error.message.penddate",
    bookingmarketinsertionorder: "campaigns.bulkupload.error.message.bookingmarketinsertionorder",
    bookingotherio: "campaigns.bulkupload.error.message.bookingotherio",
    performanceOriginalPublisherCommission: "campaigns.bulkupload.error.message.performanceoriginalpublishercommission",
    isBaselineCalculated: "campaigns.bulkupload.error.message.isbaselinecalculated",
    averageBaselineRevenue: "campaigns.bulkupload.error.message.averagebaselinerevenue",
    invalidBaselineCalculated: "campaigns.bulkupload.error.message.invalidbaselinecalculated",
    invalidAverageBaselineRevenue: "campaigns.bulkupload.error.message.invalidaveragebaselinerevenue",
    negativeAverageBaselineRevenue: "campaigns.bulkupload.error.message.negativeaveragebaselinerevenue"
};
export const TEMPLATE_SHEET_NAME = 'Bulk Upload';
export const FAILED_PLACEMENT_SHEETNAME = "Failed to Save Placements";
export const TEMPLATE_FILENAME = 'Campaigns_Template_For_Bulk_Upload.xlsx';
export const TEMPLATE_START_ROW_NUMBER = 10
export const FAILED_PLACEMENT_FILENAME = 'Campaigns_Failed_To_Save_Placements_{timeStamp}.xlsx';
export const UPLOAD_NOTIFICATION_MODAL = "upload-mode";
export const SAVE_PROCESSING_MODAL = 'bulk-upload-save-processing';
export const SAVE_SUMMARY_MODAL = 'bulk-upload-save-summary';
export const EN_VALUE = 'EnValue';
