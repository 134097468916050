import React from 'react';
import {
    BlankPageButtonBar,
    BlankPageDivider,
    BlankPageLayout,
    BlankPageSection,
    BlankPageText
} from "@cjdev-internal/visual-stack-x/BlankPageLayout";
import * as appConstant from '../../constants/appConstant';
import {Clickable} from "@cjdev-internal/visual-stack-x/Clickable";
import {Button} from "@cjdev-internal/visual-stack-x/Button";
import {useIntl} from "../../useIntl";
import BlankPageImage from "./BlankPageImage";

const BlankSlate = ({
                        openModalDialog,
                        openBlankSlateNavigationModal,
                        showRegisterAdvertiserModal,
                        setRegisterAdvertiserModal,
                        showBlankSlateNavigationModal
                    }) => {
    const {formatMessage} = useIntl();

    const openRegisterAdvertiserModal = () => {
        setRegisterAdvertiserModal(prevState => !prevState);
    };

    return (
        <>
            <BlankPageLayout
                width={"wide"}
                heading={formatMessage({id: "campaigns.blankslate.title"})}
            >
                <BlankPageText>
                    {formatMessage({id: "campaigns.blankslate.description"})}
                </BlankPageText>
                <BlankPageDivider/>
                <BlankPageSection
                    heading={formatMessage({id: "campaigns.get.started.sub.title"})}>
                    {formatMessage({id: "campaigns.performance.kpi.description.before"})}{appConstant.SPACE}
                    <a href={appConstant.PERFORMANCE_KPI_URL}
                       target={appConstant.HELP}>
                        <Clickable>{formatMessage({id: "campaigns.performance.kpi.title"})}</Clickable>
                    </a>
                    {appConstant.SPACE}{formatMessage({id: "campaigns.performance.kpi.description.after"})}
                    <br/>
                    {/*{formatMessage({id: "campaigns.learn.how.description"})}{appConstant.SPACE}*/}
                    {/*<a href={appConstant.LEARN_HOW_URL}*/}
                    {/*   target={appConstant.HELP}>*/}
                    {/*    <Clickable>{formatMessage({id: "campaigns.learn.how.title"})}</Clickable></a>*/}
                </BlankPageSection>
                <BlankPageButtonBar>
                    <Button
                        id="register-advertiser-button"
                        type="primary"
                        onClick={openRegisterAdvertiserModal}
                    >
                        {formatMessage({id: "campaigns.get.started.label"})}
                    </Button>
                </BlankPageButtonBar>

                <BlankPageImage imageTitle={formatMessage({id: "campaigns.select.timezone.title"})}
                                imageSrc="blankpage/timezone.svg"
                                imageDescription={formatMessage({id: "campaigns.select.timezone.description"})}/>
                <BlankPageImage imageTitle={formatMessage({id: "campaigns.upload.historical.placement.title"})}
                                imageSrc="blankpage/select-file.svg"
                                imageDescription={formatMessage({id: "campaigns.upload.historical.placement.description"})}/>
                <BlankPageImage imageTitle={formatMessage({id: "campaigns.upcoming.proposals.title"})}
                                imageSrc="blankpage/campaigns-planning.svg"
                                imageDescription={formatMessage({id: "campaigns.upcoming.proposals.description"})}/>
            </BlankPageLayout>
            {showRegisterAdvertiserModal && openModalDialog}
            {showBlankSlateNavigationModal && openBlankSlateNavigationModal}
        </>
    );
};

export default BlankSlate;
