import React, {useEffect, useState} from "react";
import {useIntl} from "../../useIntl";
import {Text} from "@cjdev-internal/visual-stack-x/Text";
import "./styles/BlankSlateNavigationModal.css"
import {Stack} from "@cjdev-internal/visual-stack-x/Stack";
import {ActionModal} from "@cjdev-internal/visual-stack-x/ActionModal";
import {CardButton} from "@cjdev-internal/visual-stack-x/CardButton";
import {CAMPAIGNS_BULK_UPLOAD_SOLUTIONS_URL, HELP} from "../../constants/appConstant";
import {Clickable} from "@cjdev-internal/visual-stack-x/Clickable";
import {useNavigate} from "@platform/cj-platform-navigation";

const BlankSlateNavigationModal = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [type] = useState("custom")
    const intl = useIntl();
    const navigate = useNavigate();

    useEffect(() => {
        setIsOpen(true)
    }, [])

    const onLearnMoreClick = (e) => {
        e.stopPropagation()
        window.open(CAMPAIGNS_BULK_UPLOAD_SOLUTIONS_URL, HELP)
    }

    const reloadPageAndNavigateToBulkUpload = () => {
        navigate("/bulk-upload")
        navigate(0)
    }
    const reloadPageAndNavigateToCampaignsHome = () => {
        navigate("/")
        navigate(0)
    }

    const reloadPageAndNavigateToCreateRecordModal = () => {
        navigate("/")
        navigate(0);
        localStorage.setItem('isCreateModalOpen', 'true');
    };
    return (
        <Stack gap="medium">
            <ActionModal id="blankSlateNavigationModal"
                         isOpen={isOpen}
                         type={type}
                         headerText={intl.formatMessage({id: 'campaigns.register.advertiser.modal.title'})}
                         shouldCloseOnOverlayClick={false}
                         height="400px"
                         width="530px"
                         hideFooter={true}
            >
                    <Text size={13}>
                        {intl.formatMessage({id: 'campaigns.register.advertiser.modal.sub.title'})}
                    </Text>
                <CardButton
                    title={intl.formatMessage({
                        id: 'campaigns.register.advertiser.bulk.upload.card.button.title'
                    })} className={"margin-top-16"} onClick={reloadPageAndNavigateToBulkUpload}>
                    <Text color="primary" size={13}>
                        {intl.formatMessage({id: 'campaigns.register.advertiser.bulk.upload.card.info'})}
                        &nbsp;
                        <Clickable onClick={onLearnMoreClick}>
                            {intl.formatMessage({id: 'campaigns.register.advertiser.learn.more.label'})}
                        </Clickable>
                    </Text>
                </CardButton>
                <CardButton
                    title={intl.formatMessage({
                        id: 'campaigns.register.advertiser.add.placement.card.button.title'
                    })} className={"margin-top-16"} onClick={reloadPageAndNavigateToCreateRecordModal}>
                    <Text color="primary" size={13}>
                        {intl.formatMessage({id: 'campaigns.register.advertiser.add.placement.card.info'})}
                        &nbsp;
                    </Text>
                </CardButton>
                <Clickable align={"end"} className={"margin-top-16"} onClick={reloadPageAndNavigateToCampaignsHome}>
                    {intl.formatMessage({id: 'campaigns.register.advertiser.explore.label'})}
                </Clickable>
            </ActionModal>
        </Stack>
    )
}

export default BlankSlateNavigationModal;
