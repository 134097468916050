import React, {useEffect, useState} from "react";
import {DataFilterContainer, DropdownFilter, useDataFilterContainer} from "@cjdev-internal/visual-stack-x/DataFilter"
import {useCampaignsContext} from "../CampaignsContext/useCampaignsContext";
import * as appConstant from "../../constants/appConstant";
import {AUTHORIZED_VIEW} from "../../constants/appConstant";
import "./Filters.css";
import {useUser} from "@platform/cj-platform-navigation";
import {useUserContext} from "../../context/UserContextProvider";
import {useIntl} from "../../useIntl";

const convertSelectedAdvertiserFilterList = (selectedAdvertiserFilterList) => {
    return selectedAdvertiserFilterList.map((advertiser) => ({
        id: advertiser.value,
        name: advertiser.companyName,
        type: advertiser.type,
    }));
}

export const Filters = ({
                            AccountSelectionDropdown,
                            PublisherSelectionDropdown,
                            getUserView,
                            client,
                            campaignsTranslatedDropdownList
                        }) => {
    const [selectedAdvertiserFilterList, setSelectedAdvertiserFilterList] = useState([]);
    const [advertiserOptions, setAdvertiserOptions] = useState([]);
    const [selectedPublisherFilterList, setSelectedPublisherFilterList] = useState([]);
    const {filterData, setFilterData} = useCampaignsContext();
    const {userView, setUserView} = useUserContext();
    const [iseCampaignVisible, setIsCampaignVisible] = useState('');
    const user = useUser();
    const intl = useIntl();

    useEffect(async () => {
        setUserView(await getUserView(user, client));
    }, [user]);

    useEffect(() => {
        setIsCampaignVisible(userView);
    }, [userView]);

    const applyFilter = (newStates) => {
        const accountFilter = newStates.find((state) => state.id === "account-filter");
        const publisherSelector = newStates.find((state) => state.id === "publisherSelector");
        const statusSelect = newStates.find((state) => state.id === "status-select");

        const advertisersFilterList = accountFilter.optionsSelected;
        const publisherFilterList = publisherSelector.optionsSelected;
        const statusFilterList = statusSelect.optionsSelected;

        setSelectedAdvertiserFilterList(advertisersFilterList);
        setSelectedPublisherFilterList(publisherFilterList);

        let convertedAdvertiserList;
        if (advertisersFilterList.length === 0) {
            convertedAdvertiserList = convertSelectedAdvertiserFilterList(advertiserOptions);
        } else {
            convertedAdvertiserList = convertSelectedAdvertiserFilterList(advertisersFilterList);
        }

        const filters = {
            ...filterData,
            advertisers: convertedAdvertiserList,
            status: statusFilterList.map((status) => status.value),
            publishers: publisherFilterList.map((publisher) => publisher.value),
        };
        setFilterData(filters);
    };

    const config = useDataFilterContainer();
    return (iseCampaignVisible === AUTHORIZED_VIEW ?
            (<DataFilterContainer
                onDataChange={(newStates) => applyFilter(newStates)}
                intl={intl}
                {...config}>
                <AccountSelectionDropdown
                    setSelectedAdvertiserFilterList={setSelectedAdvertiserFilterList}
                    optionList={advertiserOptions}
                    setOptionList={setAdvertiserOptions}
                    config={config}
                />
                <PublisherSelectionDropdown
                    selectedAdvertiserFilterList={selectedAdvertiserFilterList}
                    selectedPublisherFilterList={selectedPublisherFilterList}
                    setSelectedPublisherFilterList={setSelectedPublisherFilterList}
                    config={config}
                />
                <DropdownFilter
                    id="status-select"
                    label={intl.formatMessage({id: "campaigns.status.label"})}
                    options={campaignsTranslatedDropdownList(appConstant.DROPDOWN_PLACEMENT_STATUS, intl)}
                    placeholder={intl.formatMessage({id: "campaigns.dropdown.placeholder"})}
                    hideIncludeExclude={true}
                />
            </DataFilterContainer>) : false
    )
}
