import {Input as FormInput} from "@cjdev-internal/visual-stack-x/Input"

const Input = ({...restProps}) => {
    const onWheelHandler = (event) => {
        event.target.blur();
    }
    return <FormInput
        style={{width: "270px"}}
        onWheel={onWheelHandler}
        {...restProps}
    />
}

export default Input;