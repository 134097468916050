import _enTranslationMessages from "../messages/en.json";
import _csTranslationMessages from "./cs.json";
import _deTranslationMessages from "./de.json";
import _esTranslationMessages from "./es.json";
import _frTranslationMessages from "./fr.json";
import _jaTranslationMessages from "./ja.json";
import _plTranslationMessages from "./pl.json";
import _ptTranslationMessages from "./pt.json";
import _zhTranslationMessages from "./zh.json";

export const translatedMessagesByLanguage = {
    en: _enTranslationMessages,
    cs: {..._enTranslationMessages, ..._csTranslationMessages},
    de: {..._enTranslationMessages, ..._deTranslationMessages},
    es: {..._enTranslationMessages, ..._esTranslationMessages},
    fr: {..._enTranslationMessages, ..._frTranslationMessages},
    ja: {..._enTranslationMessages, ..._jaTranslationMessages},
    pl: {..._enTranslationMessages, ..._plTranslationMessages},
    pt: {..._enTranslationMessages, ..._ptTranslationMessages},
    zh: {..._enTranslationMessages, ..._zhTranslationMessages}
}

export const getTranslatedValue = (language) => {
    return translatedMessagesByLanguage[language] || _enTranslationMessages;
}