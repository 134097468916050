import React from 'react';
import * as appConstant from "../../constants/appConstant";
import {
    customDateTimeFilter,
    deSanitize,
    getDateComparator,
    getTranslatedLabel,
    getTranslatedValueGetter,
    getValueGetter,
    sortId
} from "../../utils/util";
import {
    filterDateComparator,
    formatCurrency,
    formatDate,
    formatDateAndTime,
    formatToPercentage,
    formatToPercentageWhenNotNull,
    roundOffToTwoDecimalPlaces
} from "../../utils/formatFunctions";

export const CampaignsDataTableColumnTranslation = (formatMessage) => {

    const DATATABLE_COLUMNS = [
        {
            field: "id",
            headerName: formatMessage({id: 'campaigns.placement.id'}),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.placement.id.tooltip'}),
            comparator: sortId
        },
        {
            field: "placementname",
            headerName: formatMessage({id: 'campaigns.placement.name'}),
            valueGetter: getValueGetter(deSanitize),
            suppressColumnsToolPanel: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.placement.name.tooltip'})
        },
        {
            field: "startdate",
            headerName: formatMessage({id: 'campaigns.placement.start.date'}),
            hide: false,
            filter: customDateTimeFilter("startdate"),
            sort: 'asc',
            headerTooltip: formatMessage({id: 'campaigns.placement.start.date.time.tooltip'}),
            valueGetter: getValueGetter(formatDateAndTime),
            comparator: getDateComparator(appConstant.MMM_DD_YYYY_HH_MI_AM_PM)
        },
        {
            field: "enddate",
            headerName: formatMessage({id: 'campaigns.placement.end.date'}),
            hide: false,
            filter: customDateTimeFilter("enddate"),
            headerTooltip: formatMessage({id: 'campaigns.placement.end.date.time.tooltip'}),
            valueGetter: getValueGetter(formatDateAndTime),
            comparator: getDateComparator(appConstant.MMM_DD_YYYY_HH_MI_AM_PM)
        },
        {
            field: "advertisercompanyid",
            headerName: formatMessage({id: 'campaigns.placement.advertiser.cid'}),
            hide: false,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.advertiser.cid.tooltip'})
        },
        {
            field: "advertisername",
            headerName: formatMessage({id: 'campaigns.placement.advertiser.name'}),
            hide: false,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.advertiser.name.tooltip'})
        },
        {
            field: "status",
            headerName: formatMessage({id: 'campaigns.placement.status'}),
            hide: false,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.placement.status.tooltip'}),
            valueGetter: getTranslatedValueGetter(getTranslatedLabel, formatMessage)
        },
        {
            field: "calendaryear",
            headerName: formatMessage({id: 'campaigns.placement.calender.year'}),
            hide: true,
            filter: 'agNumberColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.calendar.year.tooltip'})
        },
        {
            field: "calendarqtr",
            headerName: formatMessage({id: 'campaigns.placement.calender.quarter'}),
            hide: true,
            filter: 'agNumberColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.calendar.quarter.tooltip'})
        },
        {
            field: "calendarmonth",
            headerName: formatMessage({id: 'campaigns.placement.calender.month'}),
            hide: true,
            filter: 'agNumberColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.calendar.month.tooltip'})
        },
        {
            field: "calendarweek",
            headerName: formatMessage({id: 'campaigns.placement.calender.week'}),
            hide: true,
            filter: 'agNumberColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.calendar.week.tooltip'})
        },
        {
            field: "fiscalyear",
            headerName: formatMessage({id: 'campaigns.placement.fiscal.year'}),
            valueGetter: getValueGetter(deSanitize),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.fiscal.year.tooltip'})
        },
        {
            field: "fiscalquarter",
            headerName: formatMessage({id: 'campaigns.placement.fiscal.quarter'}),
            valueGetter: getValueGetter(deSanitize),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.fiscal.quarter.tooltip'})
        },
        {
            field: "fiscalmonth",
            headerName: formatMessage({id: 'campaigns.placement.fiscal.month'}),
            valueGetter: getValueGetter(deSanitize),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.fiscal.month.tooltip'})
        },
        {
            field: "fiscalweek",
            headerName: formatMessage({id: 'campaigns.placement.fiscal.week'}),
            valueGetter: getValueGetter(deSanitize),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.fiscal.week.tooltip'})
        },
        {
            field: "publishername",
            headerName: formatMessage({id: 'campaigns.placement.publisher.name'}),
            hide: false,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.publisher.name.tooltip'})
        },
        {
            field: "publishercompanyid",
            headerName: formatMessage({id: 'campaigns.placement.publisher.cid'}),
            hide: false,
            filter: 'agNumberColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.publisher.cid.tooltip'})
        },
        {
            field: "publisherpropertyid",
            headerName: formatMessage({id: 'campaigns.placement.publisher.property.id'}),
            hide: false,
            filter: 'agNumberColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.publisher.pid.tooltip'})
        },
        {
            field: "placementtype",
            headerName: formatMessage({id: 'campaigns.placement.type'}),
            hide: false,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.placement.type.tooltip'}),
            valueGetter: getTranslatedValueGetter(getTranslatedLabel, formatMessage)
        },
        {
            field: "performanceoriginalpublishercommission",
            headerName: formatMessage({id: 'campaigns.placement.original.publisher.commission'}),
            valueFormatter: formatToPercentage,
            hide: true,
            filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.original.publisher.commission.tooltip'})
        },
        {
            field: "performancenewpublishercommission",
            headerName: formatMessage({id: 'campaigns.placement.new.publisher.commission'}),
            valueFormatter: formatToPercentage,
            hide: true,
            filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.new.publisher.commission.tooltip'})
        },
        {
            field: "performanceoriginalratecardflatfeeprice",
            headerName: formatMessage({id: 'campaigns.placement.original.flat.fee'}),
            valueFormatter: formatCurrency,
            hide: true,
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            filter: 'agNumberColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.original.flat.fee.tooltip'})
        },
        {
            field: "performancecjnegotiatedflatfeecost",
            headerName: formatMessage({id: 'campaigns.placement.negotiated.flat.fee'}),
            valueFormatter: formatCurrency,
            hide: true,
            filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.cj.flat.fee.tooltip'})
        },
        {
            field: "websiteurl",
            headerName: formatMessage({id: 'campaigns.placement.website.url'}),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.website.url.tooltip'})
        },
        {
            field: "propertytype",
            headerName: formatMessage({id: 'campaigns.placement.property.type'}),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.property.type.tooltip'})
        },
        {
            field: "propertymodel",
            headerName: formatMessage({id: 'campaigns.placement.property.model'}),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.property.model.tooltip'})
        },
        {
            field: "personalizedortargeted",
            headerName: formatMessage({id: 'campaigns.placement.personalized.targeted'}),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.placement.targeted.tooltip'}),
            valueGetter: getTranslatedValueGetter(getTranslatedLabel, formatMessage)
        },
        {
            field: "dynamicorstatic",
            headerName: formatMessage({id: 'campaigns.placement.type.dynamic.static'}),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.dynamic.static.tooltip'}),
            valueGetter: getTranslatedValueGetter(getTranslatedLabel, formatMessage)
        },
        {
            field: "rankofplacement",
            headerName: formatMessage({id: 'campaigns.placement.spot.position'}),
            hide: true,
            filter: 'agNumberColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.placement.spot.tooltip'})
        },
        {
            field: "event",
            headerName: formatMessage({id: 'campaigns.placement.events'}),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.events.tooltip'}),
            valueGetter: getTranslatedValueGetter(getTranslatedLabel, formatMessage)
        },
        {
            field: "performancesendsizeexpecteduniquevalues",
            headerName: formatMessage({id: 'campaigns.placement.send.size'}),
            hide: true,
            filter: 'agNumberColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.send.size.tooltip'})
        },
        {
            field: "packagename",
            headerName: formatMessage({id: 'campaigns.placement.package.name'}),
            valueGetter: getValueGetter(deSanitize),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.package.name.tooltip'})
        },
        {
            field: "neworcompedplacement",
            headerName: formatMessage({id: 'campaigns.placement.new.or.comped'}),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.new.or.comped.tooltip'}),
            valueGetter: getTranslatedValueGetter(getTranslatedLabel, formatMessage)
        },
        {
            field: "vipcontent",
            headerName: formatMessage({id: 'campaigns.placement.vip.content'}),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.vip.content.tooltip'}),
            valueGetter: getTranslatedValueGetter(getTranslatedLabel, formatMessage)
        },
        {
            field: "proposedofferuseddescription",
            headerName: formatMessage({id: 'campaigns.placement.proposed.offer.description'}),
            valueGetter: getValueGetter(deSanitize),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.proposed.ad.copy.tooltip'})
        },
        {
            field: "performancesovbefore",
            headerName: formatMessage({id: 'campaigns.placement.sov.before'}),
            valueFormatter: formatToPercentage,
            hide: true,
            filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.sov.before.tooltip'})
        },
        {
            field: "performanceactualsovafter",
            headerName: formatMessage({id: 'campaigns.placement.sov.actual'}),
            valueFormatter: formatToPercentage,
            hide: true,
            filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.actual.sov.after.tooltip'})
        },
        {
            field: "lastyearsactualrevenue",
            headerName: formatMessage({id: 'campaigns.placement.last.year.actual.revenue'}),
            valueFormatter: formatCurrency,
            hide: true,
            filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.last.year.rev.for.same.dates.tooltip'})
        },
        {
            field: "lastyearsactualcommissions",
            headerName: formatMessage({id: 'campaigns.placement.last.year.actual.commission'}),
            valueFormatter: formatCurrency,
            hide: true,
            filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.last.year.comm.for.same.dates.tooltip'})
        },
        {
            field: "yoyquarterstartdatecurrentyear",
            headerName: formatMessage({id: 'campaigns.placement.yoy.quarter.start.date.current.year'}),
            hide: true,
            filter: 'agDateColumnFilter',
            filterParams: {comparator: filterDateComparator},
            valueGetter: getValueGetter(formatDate),
            comparator: getDateComparator(appConstant.MMM_DD_YYYY),
            headerTooltip: formatMessage({id: 'campaigns.placement.past.3.mos.current.year.start.date.tooltip'})
        },
        {
            field: "yoyquarterenddatecurrentyear",
            headerName: formatMessage({id: 'campaigns.placement.yoy.quarter.end.date.current.year'}),
            hide: true,
            filter: 'agDateColumnFilter',
            filterParams: {comparator: filterDateComparator},
            valueGetter: getValueGetter(formatDate),
            comparator: getDateComparator(appConstant.MMM_DD_YYYY),
            headerTooltip: formatMessage({id: 'campaigns.placement.past.3.mos.current.year.end.date.tooltip'})

        },
        {
            field: "yoyquarterstartdatelastyear",
            headerName: formatMessage({id: 'campaigns.placement.yoy.quarter.start.date.last.year'}),
            hide: true,
            filter: 'agDateColumnFilter',
            filterParams: {comparator: filterDateComparator},
            valueGetter: getValueGetter(formatDate),
            comparator: getDateComparator(appConstant.MMM_DD_YYYY),
            headerTooltip: formatMessage({id: 'campaigns.placement.past.3.mos.last.year.start.date.tooltip'})

        },
        {
            field: "yoyquarterenddatelastyear",
            headerName: formatMessage({id: 'campaigns.placement.yoy.quarter.end.date.last.year'}),
            hide: true,
            filter: 'agDateColumnFilter',
            filterParams: {comparator: filterDateComparator},
            valueGetter: getValueGetter(formatDate),
            comparator: getDateComparator(appConstant.MMM_DD_YYYY),
            headerTooltip: formatMessage({id: 'campaigns.placement.past.3.mos.last.year.end.date.tooltip'})
        },
        {
            field: "yearoveryearlastyearrevenue",
            headerName: formatMessage({id: 'campaigns.placement.yoy.calculation.last.year.revenue'}),
            valueFormatter: formatCurrency,
            hide: true,
            filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.past.3.mos.last.year.revenue.tooltip'})
        },
        {
            field: "yearoveryearcurrentyearrevenue",
            headerName: formatMessage({id: 'campaigns.placement.yoy.calculation.current.year.revenue'}),
            valueFormatter: formatCurrency,
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            hide: true,
            filter: 'agNumberColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.past.3.mos.current.year.revenue.tooltip'})
        },
        {
            field: "yearoveryearobserved",
            headerName: formatMessage({id: 'campaigns.placement.yoy.observed.percentage'}),
            valueFormatter: formatToPercentageWhenNotNull,
            hide: true,
            filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.past.3.mos.yoy.observed.tooltip'})
        },
        {
            field: "performanceyoygrowthtrendused",
            headerName: formatMessage({id: 'campaigns.placement.yoy.growth.trend'}),
            valueFormatter: formatToPercentage,
            hide: true,
            filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.yoy.growth.trend.tooltip'})
        },
        {
            field: "performancepredictedplacementlift",
            headerName: formatMessage({id: 'campaigns.placement.prior.placement.lift'}),
            valueFormatter: formatToPercentage,
            hide: true,
            filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.prior.placement.lift.tooltip'})
        },
        {
            field: "performanceotherexpectedlift",
            headerName: formatMessage({id: 'campaigns.placement.other.placement.lift'}),
            valueFormatter: formatToPercentage,
            hide: true,
            filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.other.expected.lift.tooltip'})
        },
        {
            field: "averagebaselinerevenue",
            headerName: formatMessage({id: 'campaigns.placement.average.daily.revenue.baseline'}),
            valueFormatter: formatCurrency,
            hide: true,
            filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.custom.baseline.tooltip'})
        },
        {
            field: "isbaselinecalculated",
            headerName: formatMessage({id: 'campaigns.placement.calculated.baseline.used'}),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.auto.calculated.baseline.used.tooltip'})
        },
        {
            field: "projectedspendwithplacement",
            headerName: formatMessage({id: 'campaigns.placement.projected.spend.with.placement'}),
            valueFormatter: formatCurrency,
            hide: true,
            filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.projected.spend.with.placement.tooltip'})
        },
        {
            field: "projectedspendwithoutplacement",
            headerName: formatMessage({id: 'campaigns.placement.projected.spend.without.placement'}),
            valueFormatter: formatCurrency,
            hide: true, filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.projected.spend.without.placement.tooltip'})
        },
        {
            field: "projectedrevenuewithplacement",
            headerName: formatMessage({id: 'campaigns.placement.projected.revenue.with.placement'}),
            valueFormatter: formatCurrency,
            hide: true, filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.projected.revenue.with.placement.tooltip'})
        },
        {
            field: "projectedrevenuewithoutplacement",
            headerName: formatMessage({id: 'campaigns.placement.projected.revenue.without.placement'}),
            valueFormatter: formatCurrency,
            hide: true, filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.projected.revenue.with.placement.tooltip'})
        },
        {
            field: "projectedreturnonadspend",
            headerName: formatMessage({id: 'campaigns.placement.projected.roas'}),
            valueFormatter: formatCurrency,
            hide: true, filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.projected.roas.tooltip'})
        },
        {
            field: "projectedincrementalrevenue",
            headerName: formatMessage({id: 'campaigns.placement.projected.incremental.revenue'}),
            valueFormatter: formatCurrency,
            hide: true, filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.projected.incremental.revenue.tooltip'})
        },
        {
            field: "projectedincrementalspend",
            headerName: formatMessage({id: 'campaigns.placement.projected.incremental.spend'}),
            valueFormatter: formatCurrency,
            hide: true,
            filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.projected.incremental.spend.tooltip'})
        },
        {
            field: "projectedincrementalreturnonadspend",
            headerName: formatMessage({id: 'campaigns.placement.projected.incremental.roas'}),
            valueFormatter: formatCurrency,
            hide: true,
            filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.projected.incremental.roas.tooltip'})
        },
        {
            field: "actualrevenue",
            headerName: formatMessage({id: 'campaigns.placement.actual.revenue'}),
            valueFormatter: formatCurrency,
            hide: true,
            filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.actual.revenue.tooltip'})
        },
        {
            field: "actualincrementalrevenue",
            headerName: formatMessage({id: 'campaigns.placement.actual.incremental.revenue'}),
            valueFormatter: formatCurrency,
            hide: true,
            filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.actual.incremental.revenue.tooltip'})
        },
        {
            field: "actualtotalcommissions",
            headerName: formatMessage({id: 'campaigns.placement.actual.total.commissions'}),
            valueFormatter: formatCurrency,
            hide: true,
            filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.actual.total.commissions.tooltip'})
        },
        {
            field: "actualtotalspend",
            headerName: formatMessage({id: 'campaigns.placement.actual.total.spend'}),
            valueFormatter: formatCurrency,
            hide: true,
            filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.actual.total.spend.tooltip'})
        },
        {
            field: "actualincrementalspend",
            headerName: formatMessage({id: 'campaigns.placement.actual.incremental.spend'}),
            valueFormatter: formatCurrency,
            hide: true,
            filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.actual.incremental.spend.tooltip'})
        },
        {
            field: "actualreturnonadspend",
            headerName: formatMessage({id: 'campaigns.placement.actual.roas'}),
            valueFormatter: formatCurrency,
            hide: true,
            filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.actual.roas.tooltip'})
        },
        {
            field: "actualincrementalreturnonadspend",
            headerName: formatMessage({id: 'campaigns.placement.actual.incremental.roas'}),
            valueFormatter: formatCurrency,
            hide: true,
            filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.actual.incremental.roas.tooltip'})
        },
        {
            field: "actualclicks",
            headerName: formatMessage({id: 'campaigns.placement.actual.clicks'}),
            hide: true,
            filter: 'agNumberColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.actual.clicks.tooltip'})
        },
        {
            field: "actualrevenuepervisits",
            headerName: formatMessage({id: 'campaigns.placement.actual.rpv'}),
            valueFormatter: formatCurrency,
            hide: true,
            filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.actual.rpv.tooltip'})
        },
        {
            field: "actualsales",
            headerName: formatMessage({id: 'campaigns.placement.actual.sales'}),
            hide: true,
            filter: 'agNumberColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.actual.action.count.tooltip'})
        },
        {
            field: "actualnumberofsalesgeneratedperclick",
            headerName: formatMessage({id: 'campaigns.placement.actual.cr'}),
            valueFormatter: formatToPercentage,
            hide: true,
            filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.actual.cr.tooltip'})
        },
        {
            field: "actualplacementlift",
            headerName: formatMessage({id: 'campaigns.placement.actual.placement.lift'}),
            valueFormatter: formatToPercentage,
            hide: true,
            filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.actual.placement.lift.tooltip'})
        },
        {
            field: "revenuedelta",
            headerName: formatMessage({id: 'campaigns.placement.revenue.delta'}),
            valueFormatter: formatCurrency,
            hide: true,
            filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.revenue.delta.projected.actuals.tooltip'})
        },
        {
            field: "revenueaccuracy",
            headerName: formatMessage({id: 'campaigns.placement.revenue.accuracy'}),
            valueFormatter: formatToPercentage,
            hide: true,
            filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.revenue.accuracy.projected.actuals.tooltip'})
        },
        {
            field: "returnonadspendaccuracy",
            headerName: formatMessage({id: 'campaigns.placement.roas.accuracy'}),
            valueFormatter: formatToPercentage,
            hide: true,
            filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.roas.accuracy.projected.actuals.tooltip'})
        },
        {
            field: "bookingtieredoffer",
            headerName: formatMessage({id: 'campaigns.placement.tiered.offer'}),
            valueGetter: getValueGetter(deSanitize),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.tiered.offer.tooltip'})
        },
        {
            field: "bookingoffertype",
            headerName: formatMessage({id: 'campaigns.placement.offer.type'}),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.offer.type.tooltip'}),
            valueGetter: getTranslatedValueGetter(getTranslatedLabel, formatMessage)
        },
        {
            field: "bookingoffervalue",
            headerName: formatMessage({id: 'campaigns.placement.offer.value'}),
            valueGetter: getValueGetter(deSanitize),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.offer.value.tooltip'})
        },
        {
            field: "bookingofferconditions",
            headerName: formatMessage({id: 'campaigns.placement.offer.condition'}),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.offer.conditions.tooltip'}),
            valueGetter: getTranslatedValueGetter(getTranslatedLabel, formatMessage)
        },
        {
            field: "bookingofferthreshold",
            headerName: formatMessage({id: 'campaigns.placement.offer.threshold'}),
            valueGetter: getValueGetter(deSanitize),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.offer.threshold.tooltip'})
        },
        {
            field: "bookinglinkid",
            headerName: formatMessage({id: 'campaigns.placement.link.id'}),
            valueGetter: getValueGetter(deSanitize),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.link.id.tooltip'})
        },
        {
            field: "bookingassetsneeded",
            headerName: formatMessage({id: 'campaigns.placement.asset.needed'}),
            valueGetter: getValueGetter(deSanitize),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.assets.needed.tooltip'})
        },
        {
            field: "bookingassetduedate",
            headerName: formatMessage({id: 'campaigns.placement.asset.due.date'}),
            hide: true,
            filter: customDateTimeFilter("bookingassetduedate"),
            valueGetter: getValueGetter(formatDateAndTime),
            comparator: getDateComparator(appConstant.MMM_DD_YYYY_HH_MI_AM_PM),
            headerTooltip: formatMessage({id: 'campaigns.placement.asset.due.date.tooltip'})
        },
        {
            field: "bookingassetssenttoadvertiser",
            headerName: formatMessage({id: 'campaigns.placement.asset.sent.by.advertiser'}),
            hide: true,
            filter: customDateTimeFilter("bookingassetssenttoadvertiser"),
            valueGetter: getValueGetter(formatDateAndTime),
            comparator: getDateComparator(appConstant.MMM_DD_YYYY_HH_MI_AM_PM),
            headerTooltip: formatMessage({id: 'campaigns.placement.assets.sent.by.advertiser.tooltip'})
        },
        {
            field: "bookingcjmarketplaceinsertionordernumber",
            headerName: formatMessage({id: 'campaigns.placement.cj.marketplace.io'}),
            valueGetter: getValueGetter(deSanitize),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.cj.marketplace.io.tooltip'})
        },
        {
            field: "bookingotherinsertionordernumber",
            headerName: formatMessage({id: 'campaigns.placement.publisher.insertion.order.id'}),
            valueGetter: getValueGetter(deSanitize),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.publisher.io.tooltip'})
        },
        {
            field: "bookinginsertionordersignedindicator",
            headerName: formatMessage({id: 'campaigns.placement.io.signed'}),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.io.signed.tooltip'}),
            valueGetter: getTranslatedValueGetter(getTranslatedLabel, formatMessage)
        },
        {
            field: "bookingprogramtermdescription",
            headerName: formatMessage({id: 'campaigns.placement.program.term.description'}),
            valueGetter: getValueGetter(deSanitize),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.program.term.description.tooltip'})
        },
        {
            field: "bookingprogramtermname",
            headerName: formatMessage({id: 'campaigns.placement.program.term.name'}),
            valueGetter: getValueGetter(deSanitize),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.program.term.name.tooltip'})
        },
        {
            field: "bookingprogramtermspushedindicator",
            headerName: formatMessage({id: 'campaigns.placement.program.term.pushed'}),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.program.term.pushed.tooltip'}),
            valueGetter: getTranslatedValueGetter(getTranslatedLabel, formatMessage)
        },
        {
            field: "bookingcashbackorincentiveamountonsite",
            headerName: formatMessage({id: 'campaigns.placement.cashback.amount.onsite'}),
            valueGetter: getValueGetter(deSanitize),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.cashback.amount.onsite.tooltip'})
        },
        {
            field: "bookinginvoicemonth",
            headerName: formatMessage({id: 'campaigns.placement.cj.invoice.month'}),
            valueGetter: getValueGetter(deSanitize),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.cj.invoice.month.tooltip'})
        },
        {
            field: "publisherusedspecifiedlinkid",
            headerName: formatMessage({id: 'campaigns.placement.publisher.used.link.id'}),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.publisher.used.link.id.tooltip'}),
            valueGetter: getTranslatedValueGetter(getTranslatedLabel, formatMessage)
        },
        {
            field: "description",
            headerName: formatMessage({id: 'campaigns.placement.offer.description'}),
            valueGetter: getValueGetter(deSanitize),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.offer.description.text.tooltip'})
        },
        {
            field: "wascorrectofferused",
            headerName: formatMessage({id: 'campaigns.placement.correct.offer.used'}),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.correct.offer.used.tooltip'}),
            valueGetter: getTranslatedValueGetter(getTranslatedLabel, formatMessage)
        },
        {
            field: "screenshotlink",
            headerName: formatMessage({id: 'campaigns.placement.path.for.saved.email'}),
            valueGetter: getValueGetter(deSanitize),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.path.to.saved.email.tooltip'})
        },
        {
            field: "validationnotes",
            headerName: formatMessage({id: 'campaigns.placement.validation.notes'}),
            valueGetter: getValueGetter(deSanitize),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.validation.notes.tooltip'})
        },
        {
            field: "paidincj",
            headerName: formatMessage({id: 'campaigns.placement.paid.in.cj'}),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.paid.in.cj.tooltip'}),
            valueGetter: getTranslatedValueGetter(getTranslatedLabel, formatMessage)
        },
        {
            field: "transactiondate",
            headerName: formatMessage({id: 'campaigns.placement.payment.date'}),
            hide: true,
            valueGetter: getValueGetter(formatDate),
            comparator: getDateComparator(appConstant.MMM_DD_YYYY),
            filter: 'agDateColumnFilter',
            filterParams: {comparator: filterDateComparator},
            headerTooltip: formatMessage({id: 'campaigns.placement.payment.date.tooltip'})
        },
        {
            field: "transactionid",
            headerName: formatMessage({id: 'campaigns.placement.payment.oid'}),
            valueGetter: getValueGetter(deSanitize),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.payment.oid.tooltip'})
        },
        {
            field: "amount",
            headerName: formatMessage({id: 'campaigns.placement.amount.paid'}),
            valueFormatter: formatCurrency,
            hide: true,
            filter: 'agNumberColumnFilter',
            filterValueGetter: getValueGetter(roundOffToTwoDecimalPlaces),
            headerTooltip: formatMessage({id: 'campaigns.placement.amount.paid.tooltip'})
        },
        {
            field: "notes",
            headerName: formatMessage({id: 'campaigns.placement.payment.notes'}),
            valueGetter: getValueGetter(deSanitize),
            hide: true,
            filter: 'agTextColumnFilter',
            headerTooltip: formatMessage({id: 'campaigns.placement.payment.notes.tooltip'})
        }
    ];
    return DATATABLE_COLUMNS;
};
