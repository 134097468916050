import {Box} from "@cjdev-internal/visual-stack-x/Box";
import {TextArea} from "@cjdev-internal/visual-stack-x/TextArea";
import {Label} from "@cjdev-internal/visual-stack-x/legacy/Form";
import CampaignsDropDown from "./CampaignsDropDown";
import React, {useEffect, useState} from "react";
import * as appConstant from "../../constants/appConstant";
import {sanitize} from "../../utils/util";
import {useIntl} from "../../useIntl";

const CreateRecordValidationContent = ({
                                           handleChange,
                                           id,
                                           getPlacementFieldData,
                                           modeOfPlacement,
                                           campaignsTranslatedDropdownList
                                       }) => {
    const [validationDetails, setValidationDetails] = useState({});
    const [maxDescription, setMaxDescription] = useState(0);
    const [maxScreenshotPath, setMaxScreenshotPath] = useState(0);
    const [maxNotes, setMaxNotes] = useState(0);
    const intl = useIntl();

    function handleValidationDetails(id, value) {
        //add value and key always
        setValidationDetails({
            ...validationDetails,
            [id]: value
        });

        // empty and create then delete key
        if (value === "" && modeOfPlacement === appConstant.PLACEMENT_MODE_CREATE) {
            setValidationDetails(() => {
                const validationDetailsState = {...validationDetails};
                delete validationDetailsState[id]
                return validationDetailsState
            })
        }

        //adding validation id in case edit with data
        if (getPlacementFieldData('validationid') !== "") {
            handleChange('validationid', getPlacementFieldData('validationid'));
        }
    }

    function validateMaxCharacter(id, value) {
        if (id === 'description') {
            setMaxDescription(value.length);
        }
        if (id === 'screenshotlink') {
            setMaxScreenshotPath(value.length);
        }
        if (id === 'validationnotes') {
            setMaxNotes(value.length);
        }
    }

    function validateTextArea(id, value) {
        validateMaxCharacter(id, value);
        handleValidationDetails(id, sanitize(value));
    }

    useEffect(() => {
        handleChange(id, validationDetails);
    }, [validationDetails]);

    //edit - needs to call only once
    useEffect(() => {
        validateMaxCharacter('description', getPlacementFieldData('description', 'textArea'));
        validateMaxCharacter('screenshotlink', getPlacementFieldData('screenshotlink', 'textArea'));
        validateMaxCharacter('validationnotes', getPlacementFieldData('validationnotes', 'textArea'));
    }, []);

    return (
        <Box direction="column" gap="large">
            <Box direction="row" gap="xl">
                <Box direction="column">
                    <Label>{intl.formatMessage({id: "campaigns.placement.publisher.used.link.id"})}</Label>
                    <CampaignsDropDown id='publisherusedspecifiedlinkid'
                                       handleChange={handleValidationDetails}
                                       optionsValue={campaignsTranslatedDropdownList(appConstant.DROPDOWN_YES_NO_NA, intl)}
                                       defaultValue={getPlacementFieldData('publisherusedspecifiedlinkid', 'select')}/>
                </Box>
                <Box direction="column">
                    <Label>{intl.formatMessage({id: "campaigns.placement.correct.offer.used"})}</Label>
                    <CampaignsDropDown id='wascorrectofferused'
                                       handleChange={handleValidationDetails}
                                       defaultValue={getPlacementFieldData('wascorrectofferused', 'select')}
                                       optionsValue={campaignsTranslatedDropdownList(appConstant.DROPDOWN_YES_NO_NA, intl)}/>
                </Box>
            </Box>
            <Box direction="column">
                <Label>{intl.formatMessage({id: "campaigns.placement.offer.description"})}</Label>
                <TextArea id="description"
                          name="descriptionText"
                          onChange={(e) => {
                              validateTextArea('description', e.target.value);
                          }}
                          defaultValue={getPlacementFieldData('description', 'textArea')}
                          maxLength={appConstant.MAX_LENGTH_500}/>
                <span className="textArea-maxCharacter-notification"
                      id="maxChar">{maxDescription}{appConstant.SLASH}{appConstant.MAX_LENGTH_500}</span>
            </Box>
            <Box direction="column">
                <Label labelTooltip={intl.formatMessage({id: "campaigns.placement.path.to.saved.email.tooltip"})}
                       tooltipProps={appConstant.TOOLTIP_PROPS}>{intl.formatMessage({id: "campaigns.placement.path.for.saved.email"})}</Label>
                <TextArea id="screenshotlink" name="screenshotOrEmail"
                          onChange={(e) => {
                              validateTextArea('screenshotlink', e.target.value);
                          }}
                          defaultValue={getPlacementFieldData('screenshotlink', 'textArea')}
                          maxLength={appConstant.MAX_LENGTH_500}/>
                <span className="textArea-maxCharacter-notification"
                      id="maxChar">{maxScreenshotPath}{appConstant.SLASH}{appConstant.MAX_LENGTH_500}</span>
            </Box>
            <Box direction="column">
                <Label labelTooltip={intl.formatMessage({id: "campaigns.placement.validation.notes.tooltip"})}
                       tooltipProps={appConstant.TOOLTIP_PROPS}>{intl.formatMessage({id: "campaigns.placement.validation.notes"})}</Label>
                <TextArea id="validationnotes" name="notes"
                          onChange={(e) => {
                              validateTextArea('validationnotes', e.target.value);
                          }}
                          defaultValue={getPlacementFieldData('validationnotes', 'textArea')}
                          maxLength={appConstant.MAX_LENGTH_500}/>
                <span className="textArea-maxCharacter-notification"
                      id="maxChar">{maxNotes}{appConstant.SLASH}{appConstant.MAX_LENGTH_500}</span>
            </Box>
        </Box>
    );
}
export default CreateRecordValidationContent;
