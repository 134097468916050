import {Box} from "@cjdev-internal/visual-stack-x/Box";
import {TextArea} from "@cjdev-internal/visual-stack-x/TextArea";
import {Label} from "@cjdev-internal/visual-stack-x/legacy/Form";
import Input from './Input';
import TextField from './TextField';
import * as appConstant from "../../constants/appConstant";
import CampaignsDropDown from "./CampaignsDropDown";
import PublisherPropertyId from "./PublisherPropertyId";
import {Text} from "@cjdev-internal/visual-stack-x/Text"
import CampaignsDatePicker from "./CampaignsDatePicker";
import {useEffect, useState} from "react";
import {sanitize} from "../../utils/util";
import moment from "moment";
import {getUser} from "../../utils/services";
import {useApolloClient} from "@apollo/client";
import {useIntl} from "../../useIntl";

const CreateRecordDetailsContent = ({
                                        handleChange,
                                        errorData,
                                        getPlacementFieldData,
                                        modeOfPlacement,
                                        campaignsTranslatedDropdownList
                                    }) => {
    const [companiesOptions, setCompaniesOptions] = useState([]);
    const [maxChars, setMaxChars] = useState(0);
    const intl = useIntl();

    const client = useApolloClient();
    const validateMaxCharacter = (value) => setMaxChars(Object.entries(value).length);
    const advertiserCompanyIdDefaultValueCallback = (placementRecord) => ({
        value: placementRecord.advertisercompanyid,
        label: `${placementRecord.advertisername} (${placementRecord.advertisercompanyid})`
    })

    useEffect(() => {
        const loadUserData = async () => {
            const userprofile = await getUser(client);
            const companies = userprofile?.companies ?
                userprofile.companies
                    .filter(company => company.type === "advertiser")
                    .map((user) => ({
                        value: `${user.id}`,
                        companyName: user.name,
                        label: `${user.name} (${user.id})`,
                    })) : []
            setCompaniesOptions(companies);
        };
        loadUserData();
        validateMaxCharacter(getPlacementFieldData('proposedofferuseddescription', 'textArea'));
    }, []);

    return (
        <Box direction="column" gap="large">
            <Box direction="row" gap="xl">
                <Box direction="column">
                    <Label required>{intl.formatMessage({id: "campaigns.advertiser.label"})}</Label>
                    <CampaignsDropDown id='advertiserCompanyId' handleChange={handleChange}
                                       optionsValue={companiesOptions}
                                       defaultValue={getPlacementFieldData('advertisercompanyid',
                                           'select', advertiserCompanyIdDefaultValueCallback)}
                                       errorData={errorData.get("advertiserCompanyId")}/>
                </Box>
                <Box direction="column">
                    <PublisherPropertyId handleChange={handleChange}
                                         errorData={errorData.get('publisherPropertyId')}
                                         defaultValue={getPlacementFieldData('publisherpropertyid', 'input')}
                                         yoyCurrentYearRevenue={getPlacementFieldData('yearoveryearcurrentyearrevenue')}
                                         modeOfPlacement={modeOfPlacement}/>
                </Box>
            </Box>
            <Text className="record-modal-hr"/>
            <Box direction="row" gap="xl">
                <Box direction="column">
                    <Label required>{intl.formatMessage({id: "campaigns.placement.start.date"})}</Label>
                    <CampaignsDatePicker id='pStartDate' handleChange={handleChange}
                                         defaultValue={getPlacementFieldData('startdate', 'date')}
                                         errorData={errorData.get("pStartDate")}
                                         defaultTime={moment(appConstant.DETAILS_TIMESTAMP_START_DATE, 'HH:mm:ss')}/>
                </Box>
                <Box direction="column">
                    <Label required>{intl.formatMessage({id: "campaigns.placement.end.date"})}</Label>
                    <CampaignsDatePicker id="pEndDate" handleChange={handleChange}
                                         defaultValue={getPlacementFieldData('enddate', 'date')}
                                         errorData={errorData.get("pEndDate")}
                                         defaultTime={moment(appConstant.DETAILS_TIMESTAMP_END_DATE, 'HH:mm:ss')}/>
                </Box>
                <Box direction="column">
                    <Label>{intl.formatMessage({id: "campaigns.placement.fiscal.year"})}</Label>
                    <Input id="fiscalyear" type={"text"}
                           defaultValue={getPlacementFieldData('fiscalyear', 'textField')}
                           onChange={(e) => {
                               handleChange('fiscalYear', sanitize(e.target.value));
                           }}
                           maxLength={appConstant.MAX_LENGTH_500}/>
                </Box>
            </Box>
            <Box direction="row" gap="xl">
                <Box direction="column">
                    <Label>{intl.formatMessage({id: "campaigns.placement.fiscal.quarter"})}</Label>
                    <Input
                        id="fiscalquarter"
                        type={"text"}
                        defaultValue={getPlacementFieldData('fiscalquarter', 'textField')}
                        onChange={(e) => {
                            handleChange('fiscalQuarter', sanitize(e.target.value));
                        }}
                        maxLength={appConstant.MAX_LENGTH_500}
                    />
                </Box>
                <Box direction="column">
                    <Label>{intl.formatMessage({id: "campaigns.placement.fiscal.month"})}</Label>
                    <Input
                        id="fiscalmonth"
                        type={"text"}
                        defaultValue={getPlacementFieldData('fiscalmonth', 'textField')}
                        onChange={(e) => {
                            handleChange('fiscalMonth', sanitize(e.target.value));
                        }}
                        maxLength={appConstant.MAX_LENGTH_500}
                    />
                </Box>
                <Box direction="column">
                    <Label>{intl.formatMessage({id: "campaigns.placement.fiscal.week"})}</Label>
                    <Input
                        id="fiscalweek"
                        type={"text"}
                        defaultValue={getPlacementFieldData('fiscalweek', 'textField')}
                        onChange={(e) => {
                            handleChange('fiscalWeek', sanitize(e.target.value));
                        }}
                        maxLength={appConstant.MAX_LENGTH_500}
                    />
                </Box>
            </Box>
            <Box direction="row" gap="xl">
                <Box direction="column">
                    <TextField
                        name={appConstant.PLACEMENT_NAME}
                        label={intl.formatMessage({id: "campaigns.placement.name"})}
                        required
                        id="placementName"
                        className="overwrite-legacy-textField"
                        onChange={(e) => handleChange('placementName', sanitize(e.target.value))}
                        error={errorData.get("placementName") && errorData.get("placementName").value ? appConstant.HTML_HIDDEN_TAG : appConstant.EMPTY}
                        defaultValue={getPlacementFieldData('placementname', 'textField')}
                    />
                </Box>
                <Box direction="column">
                    <Label required>{intl.formatMessage({id: "campaigns.placement.type"})}</Label>
                    <CampaignsDropDown id='placementType' handleChange={handleChange}
                                       optionsValue={campaignsTranslatedDropdownList(appConstant.DROPDOWN_PLACEMENT_TYPE, intl)}
                                       defaultValue={getPlacementFieldData('placementtype', 'select')}
                                       errorData={errorData.get("placementType")}/>
                </Box>
                <Box direction="column">
                    <Label labelTooltip={intl.formatMessage({id: "campaigns.placement.dynamic.static.tooltip"})}
                           tooltipProps={appConstant.TOOLTIP_PROPS}
                           required>{intl.formatMessage({id: "campaigns.placement.type.dynamic.static"})}</Label>
                    <CampaignsDropDown id='dynamicOrStatic' handleChange={handleChange}
                                       optionsValue={campaignsTranslatedDropdownList(appConstant.DROPDOWN_DYNAMIC_OR_STATIC, intl)}
                                       defaultValue={getPlacementFieldData('dynamicorstatic', 'select')}
                                       errorData={errorData.get("dynamicOrStatic")}/>
                </Box>
            </Box>
            <Box direction="row" gap="xl">
                <Box direction="column">
                    <Label labelTooltip={intl.formatMessage({id: "campaigns.placement.placement.targeted.tooltip"})}
                           tooltipProps={appConstant.TOOLTIP_PROPS}
                           required>{intl.formatMessage({id: "campaigns.placement.personalized.targeted"})}</Label>
                    <CampaignsDropDown id='personalizedOrTargeted' handleChange={handleChange}
                                       optionsValue={campaignsTranslatedDropdownList(appConstant.DROPDOWN_YES_NO, intl)}
                                       defaultValue={getPlacementFieldData('personalizedortargeted', 'select')}
                                       errorData={errorData.get("personalizedOrTargeted")}/>
                </Box>
                <Box direction="column">
                    <Label labelTooltip={intl.formatMessage({id: "campaigns.placement.new.or.comped.tooltip"})}
                           tooltipProps={appConstant.TOOLTIP_PROPS}
                           required>{intl.formatMessage({id: "campaigns.placement.new.or.comped"})}</Label>
                    <CampaignsDropDown id='newOrComped' handleChange={handleChange}
                                       optionsValue={campaignsTranslatedDropdownList(appConstant.DROPDOWN_NEW_OR_COMPED, intl)}
                                       defaultValue={getPlacementFieldData('neworcompedplacement', 'select')}
                                       errorData={errorData.get("newOrComped")}/>
                </Box>
                <Box direction="column">
                    <Label required>{intl.formatMessage({id: "campaigns.placement.vip.content"})}</Label>
                    <CampaignsDropDown id='vipContentCampaign' handleChange={handleChange}
                                       optionsValue={campaignsTranslatedDropdownList(appConstant.DROPDOWN_YES_NO, intl)}
                                       defaultValue={getPlacementFieldData('vipcontent', 'select')}
                                       errorData={errorData.get("vipContentCampaign")}/>
                </Box>
            </Box>
            <Box direction="row" gap="xl">
                <Box direction="column">
                    <Label>{intl.formatMessage({id: "campaigns.placement.events"})}</Label>
                    <CampaignsDropDown id='events' handleChange={handleChange}
                                       optionsValue={campaignsTranslatedDropdownList(appConstant.DROPDOWN_EVENTS, intl)}
                                       defaultValue={getPlacementFieldData('event', 'select')}
                                       errorData={errorData.get("events")}/>
                </Box>
                <Box direction="column">
                    <Label labelTooltip={intl.formatMessage({id: "campaigns.placement.package.name.tooltip"})}
                           tooltipProps={appConstant.TOOLTIP_PROPS}>{intl.formatMessage({id: "campaigns.placement.package.name"})}</Label>
                    <Input id="packageName"
                           defaultValue={getPlacementFieldData('packagename', 'input')}
                           onChange={(e) => handleChange('packageName', sanitize(e.target.value))}/>
                </Box>
                <Box direction="column">
                    <Label labelTooltip={intl.formatMessage({id: "campaigns.placement.placement.spot.tooltip"})}
                           tooltipProps={appConstant.TOOLTIP_PROPS}>{intl.formatMessage({id: "campaigns.placement.spot.position"})}</Label>
                    <Input
                        id="rankofplacement"
                        type="number"
                        placeholder={intl.formatMessage({id: "campaigns.enter.number.placeholder"})}
                        onChange={(e) => handleChange('rankofplacement', parseInt(e.target.value))}
                        defaultValue={getPlacementFieldData('rankofplacement', 'textField')}
                        min="0"
                    />
                </Box>
            </Box>
            <Box direction="column">
                <Label>{intl.formatMessage({id: "campaigns.placement.proposed.offer.description"})}</Label>
                <TextArea
                    name={appConstant.PROPOSED_OFFER_USED_DESCRIPTION_TEXT_FIELD}
                    id="proposedOfferDescription"
                    defaultValue={getPlacementFieldData('proposedofferuseddescription', 'textArea')}
                    onChange={(e) => {
                        handleChange('proposedOfferDescription', sanitize(e.target.value));
                        validateMaxCharacter(e.target.value)
                    }}
                    maxLength={appConstant.MAX_LENGTH_500}
                />
                <span className="textArea-maxCharacter-notification"
                      id="maxChar">{maxChars}{appConstant.SLASH}{appConstant.MAX_LENGTH_500}</span>
            </Box>
        </Box>

    )
}

export default CreateRecordDetailsContent;
