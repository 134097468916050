import React, {useEffect, useState} from "react";
import {Box} from "@cjdev-internal/visual-stack-x/Box";
import {TextArea} from "@cjdev-internal/visual-stack-x/TextArea";
import {Label} from "@cjdev-internal/visual-stack-x/legacy/Form";
import Input from './Input';
import * as appConstant from "../../constants/appConstant";
import {hasValue, sanitize} from "../../utils/util";
import CampaignsDropDown from "./CampaignsDropDown";
import "./styles/CreateRecordModal.css";
import moment from "moment";
import CampaignsDatePicker from "./CampaignsDatePicker";
import {useIntl} from "../../useIntl";

const PaymentDetailsContent = ({
                                   id,
                                   handleChange,
                                   getPlacementFieldData,
                                   modeOfPlacement,
                                   campaignsTranslatedDropdownList
                               }) => {
    const [paymentDetails, setPaymentDetails] = useState({});
    const [count, setCount] = React.useState(0);
    const intl = useIntl();

    function handleChangePayment(id, value) {
        setPaymentDetails({
            ...paymentDetails,
            [id]: value
        });

        if ((value === "" || value === null) && modeOfPlacement === appConstant.PLACEMENT_MODE_CREATE) {
            setPaymentDetails(() => {
                const paymentDetailsState = {...paymentDetails};
                delete paymentDetailsState[id]
                return paymentDetailsState
            })
        }

        //adding payment id in case edit with data
        if (getPlacementFieldData('paymentid') !== "") {
            handleChange('paymentid', getPlacementFieldData('paymentid'));
        }
    }

    useEffect(() => {
        handleChange(id, paymentDetails);
    }, [paymentDetails]);

    //for edit case only call once
    useEffect(() => {
        paymentCharactersCount(getPlacementFieldData('notes', 'textArea'));
    }, []);

    const paymentCharactersCount = (enteredValue) => {
        let charLength = enteredValue.length
        setCount(charLength);
    }

    return (
        <form>
            <Box direction="column" gap="large">
                <Box direction="row" gap="xl">
                    <Box direction="column">
                        <Label>{intl.formatMessage({id: "campaigns.placement.paid.in.cj"})}</Label>
                        <CampaignsDropDown id='paidInCJ' handleChange={handleChangePayment}
                                           optionsValue={campaignsTranslatedDropdownList(appConstant.DROPDOWN_PAID_IN_CJ, intl)}
                                           defaultValue={getPlacementFieldData('paidincj', 'select')}
                                           errorData={null}/>
                    </Box>
                </Box>
                <Box direction="row" gap="xl">
                    <Box direction="column">
                        <Label labelTooltip={intl.formatMessage({id: "campaigns.placement.payment.oid.tooltip"})}
                               tooltipProps={appConstant.TOOLTIP_PROPS}>{intl.formatMessage({id: "campaigns.placement.payment.oid"})}</Label>
                        <Input id="transactionid"
                               onChange={(e) => handleChangePayment('transactionid', sanitize(e.target.value))}
                               defaultValue={getPlacementFieldData('transactionid', 'input')}
                               maxLength={appConstant.MAX_LENGTH_500}/>
                    </Box>
                    <Box direction="column">
                        <Label>{intl.formatMessage({id: "campaigns.placement.amount.paid"})}</Label>
                        <Input id="amount" placeholder={intl.formatMessage({id: "campaigns.enter.number.placeholder"})}
                               type="number"
                               onChange={(e) => handleChangePayment('amount', hasValue(e.target.value) ? parseFloat(e.target.value) : null)}
                               defaultValue={getPlacementFieldData('amount', 'input')}
                               min="0"/>
                    </Box>
                    <Box direction="column">
                        <Label>{intl.formatMessage({id: "campaigns.placement.payment.date"})}</Label>
                        <CampaignsDatePicker
                            defaultValue={getPlacementFieldData('transactiondate', 'date') === '' ? '' : moment(getPlacementFieldData('transactiondate', 'date'), appConstant.DATE_TIME_FORMAT)}
                            handleChange={handleChangePayment}
                            id="transactiondate"
                            showTime={false}>
                        </CampaignsDatePicker>
                    </Box>
                </Box>
                <Box direction="column">
                    <Label labelTooltip={intl.formatMessage({id: "campaigns.placement.payment.notes.tooltip"})}
                           tooltipProps={appConstant.TOOLTIP_PROPS}>{intl.formatMessage({id: "campaigns.placement.payment.notes"})}</Label>
                    <TextArea id="notes"

                              maxLength={appConstant.PAYMENT_NOTES_TEXT_FIELD_MAX_CHARACTER}
                              defaultValue={getPlacementFieldData('notes', 'textArea')}
                              onChange={e => {
                                  paymentCharactersCount(e.target.value);
                                  handleChangePayment('notes', sanitize(e.target.value))
                              }}
                    />
                    <span className="textArea-maxCharacter-notification"
                          id="paymentCharCount">{count}{appConstant.SLASH}{appConstant.PAYMENT_NOTES_TEXT_FIELD_MAX_CHARACTER}</span>
                </Box>
            </Box>
        </form>);
}
export default PaymentDetailsContent
