/* global BigInt */
import {getBearerToken as getPlatformBearerToken} from "@platform/platform-auth";
import {DateTimeFilter} from "@cjdev-internal/visual-stack-x/DataGrid";
import * as appConstant from "../constants/appConstant";
import {ASCENDING, DESCENDING} from "../constants/appConstant";
import CreateRecordData from "./CreateRecordData";
import moment from "moment";
import sanitizeHtml from "sanitize-html";
import {getUser} from "./services";
import * as BulkUploadConstant from "../components/BulkUpload/BulkUploadConstant";
import * as XLSX from "xlsx";
import {campaignsDropdownComparatorList} from "../components/CreateRecord/CampaignsDropdownComparatorList";

export const getCompaniesDetails = (userData) => {
    return userData ? userData
        .filter(company => company.type === "advertiser")
        .map((company) => {
            return [company.id, company.name];
        }) : undefined;
};

export const checkTabValidation = (formData, tabErrorMessage, id, errorData, tabDetails, errorMessage, modeOfPlacement, formatMessage) => {

    const tabData = formData[tabDetails];
    const highlightFieldId = 'placementStatusId';
    const isBulkUploadMode = modeOfPlacement === appConstant.PLACEMENT_MODE_BULK_UPLOAD;
    let placementStatus = formData.placementStatusId;

    if (isBulkUploadMode) {
        appConstant.PLACEMENT_STATUS_FIELD.find(option => {
            const translatedValue = formatMessage({id: option.translationKey});
            const placementStatusOption = translatedValue?.toLowerCase() === placementStatus?.toLowerCase();
            if (placementStatusOption) {
                placementStatus = option.value;
                return option.value;
            }
        });
    }

    if (tabData !== undefined && placementStatus?.toLowerCase() !== appConstant.BOOKED.toLowerCase()) {
        Object.keys(tabData).map((key) => {
            if (!isBulkUploadMode && tabData[key] !== "") {
                errorData.set(key, {value: true});
                errorData.set(id, {value: true});
                errorData.set(highlightFieldId, {value: true});
                if (!tabErrorMessage.includes(errorMessage)) {
                    tabErrorMessage.push(errorMessage);
                }
            } else if (isBulkUploadMode && hasValue(tabData[key])) {
                errorData.set(key, {value: true});
                errorData.set(id, {value: true});
                if (!tabErrorMessage.includes(errorMessage)) {
                    tabErrorMessage.push(errorMessage);
                }
            }
        })
    }
}

/*
    Booked -> proposed
    1. create time empty -> edit non empty
    2. create time non empty -> edit time empty
    2. create non empty -> edit non empty

    formData[tab][tabField] && formData[tab][tabField] == "" -> good
    !formData[tab][tabField] && placementRecord[tab][tabField] == "" -> good

    formData[tab][tabField] ?
    formData[tab][tabField] == "" ? false : true :
    placementRecord[tab][tabField] == "" ? false : true

    code for validation to understand
    ----------------------------------
    if (formData[tab]) {
        //value not changed in tab
        if (formData[tab][tabField] === undefined) {
            if (hasValue(structuredPlacementRecord[tab][tabField]))
                return true;
            else
                return false;
        } else {
            if (hasValue(formData[tab][tabField]))
                return true;
            else
                return false;

        }
        else{
            if (hasValue(structuredPlacementRecord[tab][tabField]))
                return true;
            else
                return false;
        }
    }

 */
const checkNotProposedValidation = (formData, placementRecord, errorData, tabErrorMessage, formatMessage) => {
    let isErrorOccur = false;
    const highlightFieldId = 'placementStatusId';
    const structuredPlacementRecord = CreateRecordData(placementRecord);
    const placementStatus = formData.placementStatusId || placementRecord.status;
    //if proposed or declined
    if (placementStatus !== appConstant.PLACEMENT_STATUS_FIELD[0].value) {
        appConstant.tabs.forEach((tab) => {

            isErrorOccur = Object.keys(structuredPlacementRecord[tab]).some((tabField) => {

                return formData[tab] && formData[tab][tabField] !== undefined ?
                    !hasNullOrEmptyValue(formData[tab][tabField]) :
                    !hasNullOrEmptyValue(structuredPlacementRecord[tab][tabField]);

            });
            if (isErrorOccur) {
                const errorMessage = formatMessage({id: appConstant.tabErrorMessages[tab]});
                errorData.set(highlightFieldId, {value: true});
                tabErrorMessage.push(errorMessage);
            }
        });
    }
}

/*
    -> if no placement record -> create else -> edit
        -> create
            -> mandatory field validation (check not empty)  (formData)
            -> Tab validation ( whether booked or not)

        -> Edit
            -> mandatory field validation (check not empty) if data field (placement record)
            -> If Placement status changed to proposed
                -> form data 3 tabs should not have any value.
 */

const getCreateEditData = (formData, placementRecord, isCreate) => {
    return isCreate ? formData : formData === undefined ? placementRecord : formData;
}

export const isFlatFeeGT0 = (recordCjNegotiatedFlatFeeCost, recordOriginalRateCardFlatFeePrice,
                             formCjNegotiatedFlatFeeCost, formOriginalRateCardFlatFeePrice) => {

    const negotiatedFlatFee = formCjNegotiatedFlatFeeCost !== undefined ? formCjNegotiatedFlatFeeCost : recordCjNegotiatedFlatFeeCost;
    const originalFlatFee = formOriginalRateCardFlatFeePrice !== undefined ? formOriginalRateCardFlatFeePrice : recordOriginalRateCardFlatFeePrice;

    return negotiatedFlatFee > 0 || originalFlatFee > 0;
}

function checkBookingMandatoryFields(formData, placementRecord, isCreate, tabErrorMessage, errorData, isCreateOrDuplicate, getErrorMessage) {

    const placementStatus = formData.placementStatusId ? formData.placementStatusId : placementRecord.status;

    const flatFeeGT0 = isFlatFeeGT0(placementRecord.performancecjnegotiatedflatfeecost,
        placementRecord.performanceoriginalratecardflatfeeprice, formData?.performanceDetails?.performanceCjNegotiatedFlatFeeCost,
        formData?.performanceDetails?.performanceOriginalRateCardFlatFeePrice)

    if (placementStatus?.toLowerCase() === appConstant.BOOKED.toLowerCase() && flatFeeGT0) {
        if (!formData.bookingdetails) {
            formData.bookingdetails = {}
        }
        checkEmptyFormField(
            getCreateEditData(formData.bookingdetails.bookingmarketinsertionorder, placementRecord.bookingcjmarketplaceinsertionordernumber, isCreateOrDuplicate),
            getErrorMessage('bookingmarketinsertionorder'),
            tabErrorMessage,
            "bookingmarketplaceioid",
            errorData
        );
        checkEmptyFormField(
            getCreateEditData(formData.bookingdetails.bookingotherio, placementRecord.bookingotherinsertionordernumber, isCreateOrDuplicate),
            getErrorMessage('bookingotherio'),
            tabErrorMessage,
            "bookingotherioid",
            errorData
        );
    }
}


function checkPlacementDetailsMandatoryFields(formData, placementRecord, isCreate, tabErrorMessage, errorData, getErrorMessage) {
    // Create Edit option
    checkEmptyFormField(getCreateEditData(formData.placementStatusId, placementRecord.status, isCreate), getErrorMessage('placementStatusId'), tabErrorMessage, "placementStatusId", errorData);
    checkEmptyFormField(getCreateEditData(formData.advertiserCompanyId?.value, placementRecord.advertisercompanyid, isCreate), getErrorMessage('advertiserCompanyId'), tabErrorMessage, "advertiserCompanyId", errorData);
    checkEmptyFormField(getCreateEditData(formData.placementName, placementRecord.placementname, isCreate), getErrorMessage('placementName'), tabErrorMessage, "placementName", errorData);
    checkPublisherPropertyDetailsField(formData, placementRecord, getErrorMessage('publisherPropertyId'), tabErrorMessage, errorData);
    checkEmptyFormField(getCreateEditData(formData.placementType, placementRecord.placementtype, isCreate), getErrorMessage('placementType'), tabErrorMessage, "placementType", errorData);
    checkEmptyFormField(getCreateEditData(formData.dynamicOrStatic, placementRecord.dynamicorstatic, isCreate), getErrorMessage('dynamicOrStatic'), tabErrorMessage, "dynamicOrStatic", errorData);
    checkEmptyFormField(getCreateEditData(formData.personalizedOrTargeted, placementRecord.personalizedortargeted, isCreate), getErrorMessage('personalizedOrTargeted'), tabErrorMessage, "personalizedOrTargeted", errorData);
    checkEmptyFormField(getCreateEditData(formData.newOrComped, placementRecord.neworcompedplacement, isCreate), getErrorMessage('newOrComped'), tabErrorMessage, "newOrComped", errorData);
    checkEmptyFormField(getCreateEditData(formData.vipContentCampaign, placementRecord.vipcontent, isCreate), getErrorMessage('vipContentCampaign'), tabErrorMessage, "vipContentCampaign", errorData);
    checkEmptyFormField(getCreateEditData(formData.pStartDate, placementRecord.startdate, isCreate), getErrorMessage('pStartDate'), tabErrorMessage, "pStartDate", errorData);
    checkEmptyFormField(getCreateEditData(formData.pEndDate, placementRecord.enddate, isCreate), getErrorMessage('pEndDate'), tabErrorMessage, "pEndDate", errorData);

}

function checkPerformanceMandatoryFields(performanceDetails, placementRecord, tabErrorMessage, errorData, isCreate, getErrorMessage, modeOfPlacement) {

    checkEmptyFormField(getCreateEditData(performanceDetails?.performanceOriginalPublisherCommission, placementRecord.performanceoriginalpublishercommission, isCreate),
        getErrorMessage("performanceOriginalPublisherCommission"),
        tabErrorMessage,
        "performanceOriginalPublisherCommission",
        errorData);
    const isBaselineCalculated = performanceDetails?.baselineValue ? performanceDetails?.baselineValue.isBaselineCalculated : placementRecord?.isbaselinecalculated;
    const averageBaselineRevenue = performanceDetails?.baselineValue ? performanceDetails?.baselineValue.averageBaselineRevenue : placementRecord?.averagebaselinerevenue;
    const defaultBaselineSelected = isBaselineCalculated === appConstant.YES;
    const customBaselineSelectedWithInput = isBaselineCalculated === appConstant.NO &&
        hasValue(averageBaselineRevenue);
    const checkBaselineSelected = (defaultBaselineSelected || customBaselineSelectedWithInput)

    if (modeOfPlacement !== appConstant.PLACEMENT_MODE_BULK_UPLOAD) {
        if (checkBaselineSelected) {
            errorData.set("baselineValue", {value: false});
        } else {
            errorData.set("baselineValue", {value: true});
            const errorMessage = isBaselineCalculated ? getErrorMessage('averageBaselineRevenue') : getErrorMessage('isBaselineCalculated');
            if (!tabErrorMessage.includes(errorMessage)) {
                tabErrorMessage.push(errorMessage);
            }
        }
    }
}

export const formValidate = (formData, placementRecord, modeOfPlacement, formatMessage) => {
    let tabErrorMessage = [];
    let errorData = new Map();
    const isCreate = modeOfPlacement === appConstant.PLACEMENT_MODE_CREATE || modeOfPlacement === appConstant.PLACEMENT_MODE_BULK_UPLOAD;
    const isCreateOrDuplicate = (modeOfPlacement === appConstant.PLACEMENT_MODE_CREATE ||
        modeOfPlacement === appConstant.PLACEMENT_MODE_DUPLICATE || modeOfPlacement === appConstant.PLACEMENT_MODE_BULK_UPLOAD);

    function mandatoryErrorMessageProvider(defaultMessage) {
        return modeOfPlacement === appConstant.PLACEMENT_MODE_BULK_UPLOAD ?
            (key) => formatMessage({id: BulkUploadConstant.BULK_UPLOAD_ERROR_MESSAGE[key]}) :
            () => defaultMessage;
    }

    function tabValidationErrorMessageProvider(tabDetails) {
        return modeOfPlacement === appConstant.PLACEMENT_MODE_BULK_UPLOAD ?
            formatMessage({id: 'campaigns.bulkupload.booked.status.validation.error.message'}) :
            formatMessage({id: appConstant.tabErrorMessages[tabDetails]});
    }

    checkPlacementDetailsMandatoryFields(formData, placementRecord, isCreate, tabErrorMessage, errorData, mandatoryErrorMessageProvider(formatMessage({id: "campaigns.details.tab.error.message"})));
    checkBookingMandatoryFields(formData, placementRecord, isCreate, tabErrorMessage, errorData, isCreateOrDuplicate, mandatoryErrorMessageProvider(formatMessage({id: "campaigns.booking.details.tab.error.message"})));
    checkPerformanceMandatoryFields(formData.performanceDetails, placementRecord, tabErrorMessage, errorData, isCreate, mandatoryErrorMessageProvider(formatMessage({id: "campaigns.performance.details.tab.error.message"})), modeOfPlacement);

    switch (modeOfPlacement) {
        case appConstant.PLACEMENT_MODE_CREATE:
        case appConstant.PLACEMENT_MODE_DUPLICATE:
        case appConstant.PLACEMENT_MODE_BULK_UPLOAD:
            checkTabValidation(formData, tabErrorMessage, "bookingPlacementStatusId", errorData, appConstant.tabs[1], tabValidationErrorMessageProvider('bookingdetails'), modeOfPlacement, formatMessage);
            checkTabValidation(formData, tabErrorMessage, "validationPlacementStatusId", errorData, appConstant.tabs[0], tabValidationErrorMessageProvider('validationDetails'), modeOfPlacement, formatMessage);
            checkTabValidation(formData, tabErrorMessage, "paymentPlacementStatusId", errorData, appConstant.tabs[2], tabValidationErrorMessageProvider('paymentDetails'), modeOfPlacement, formatMessage);
            break;
        case appConstant.PLACEMENT_MODE_EDIT:
            checkNotProposedValidation(formData, placementRecord, errorData, tabErrorMessage, formatMessage);
            break;
    }

//start date end date comparison needs to check if it can move to checkPlacementDetailsMandatoryFields
    if (modeOfPlacement === appConstant.PLACEMENT_MODE_BULK_UPLOAD) {
        if (formData.pStartDate) {
            const isStartDateValid = moment(formData.pStartDate, appConstant.DATE_TIME_FORMAT, true).isValid();
            if (!isStartDateValid) {
                tabErrorMessage.push(formatMessage({id: 'campaigns.bulkupload.booked.status.invalid.start.date'}))
            }
        }
        if (formData.pEndDate) {
            const isEndDateValid = moment(formData.pEndDate, appConstant.DATE_TIME_FORMAT, true).isValid();
            if (!isEndDateValid) {
                tabErrorMessage.push(formatMessage({id: 'campaigns.bulkupload.booked.status.invalid.end.date'}))
            }
        }
    }
//start date end date comparison needs to check if it can move to checkPlacementDetailsMandatoryFields
    if (formData.pStartDate !== undefined || formData.pEndDate !== undefined)
        checkStartAndEndDate(formData, placementRecord, tabErrorMessage, errorData, formatMessage);

    if (modeOfPlacement !== appConstant.PLACEMENT_MODE_BULK_UPLOAD) {
        tabErrorMessage = tabErrorMessage.map((message) => (<p key={message}>{message}</p>))
    }
    return {tabErrorMessage, errorData};
}

export const isStringEmpty = (fieldValue) => {
    return ((typeof fieldValue === 'string' || fieldValue instanceof String) && fieldValue.trim() === "");
}

export const isUndefinedOrNull = (fieldValue) => {
    return (fieldValue === undefined || fieldValue === null)
};

const checkEmptyFormField = (formField, errorMessage, tabErrorMessage, id, errorData) => {
    if (isUndefinedOrNull(formField) || isStringEmpty(formField) || Number.isNaN(formField)) {
        errorData.set(id, {value: true});
        if (!tabErrorMessage.includes(errorMessage)) {
            tabErrorMessage.push(errorMessage);
        }
    } else {
        errorData.set(id, {value: false});
    }
}

const checkPublisherPropertyDetailsField = (formData, placementRecord, errorMessage, tabErrorMessage, errorData) => {
    let publisherPropertyDetails = formData?.pid?.publisherPropertyId ? formData.pid.publisherPropertyId : placementRecord?.publisherpropertyid;
    if (!publisherPropertyDetails) {
        errorData.set('publisherPropertyId', {value: true});
        if (!tabErrorMessage.includes(errorMessage)) {
            tabErrorMessage.push(errorMessage);
        }
    } else {
        errorData.set('pid', {value: false});
    }
}

export const getBearerToken = async () => {
    let auth_token = await getPlatformBearerToken();
    return auth_token === null || auth_token === undefined ? null : `Bearer ` + auth_token;
}

export const isEmpty = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
}

export const hasValue = (obj) => {
    return obj !== undefined && obj !== null && obj !== "";
}

export const getNonEmptyVariables = (obj) => {
    let nonEmptyVariables = {};
    for (const variablesKey in obj) {
        let value = obj[variablesKey];
        if (value !== null && value !== undefined && value?.length !== 0) {
            nonEmptyVariables[variablesKey] = value;
        }
    }
    return nonEmptyVariables;
}

export const hasNullOrEmptyValue = (obj) => {
    return obj === null || obj === "";
}

const checkStartAndEndDate = (formData, placementRecord, tabErrorMessage, errorData, formatMessage) => {
//if edit case it will get one of the date value from placement record.
    let startDate = formData.pStartDate || placementRecord.startdate;
    let endDate = formData.pEndDate || placementRecord.enddate;

    if (moment(startDate, appConstant.DATE_TIME_FORMAT, true).isAfter(moment(endDate, appConstant.DATE_TIME_FORMAT, true))) {
        errorData.set("pEndDate", {value: true});
        tabErrorMessage.push(formatMessage({id: "campaigns.start.date.and.end.date.error.message"}));
    }
}

export const sanitize = (inputValue) => {
    return sanitizeHtml(inputValue);
}

export const deSanitize = ({value}) => {
    let returnValue = value;
    if (value !== undefined && value !== null && typeof value === "string") {
        returnValue = String(value).replace(/&amp;/g, "&")
            .replace(/&lt;/g, "<")
            .replace(/&gt;/g, ">");
    }
    return returnValue;

}

export const deSanitizeObject = (object) => {
    const deSanitizedObject = {};
    for (let [key, value] of Object.entries(object)) {
        deSanitizedObject[key] = deSanitize({value});
    }
    return deSanitizedObject;
}

export const getUserLinkedAdvertiser = async (client) => {
    const userprofile = await getUser(client);
    return (userprofile.companies
        ?.filter(company => company.type === "advertiser"));
}

export const getLinkedAdvertiserList = async (client) => {
    const advertisers = await getUserLinkedAdvertiser(client)
    return advertisers?.map((advertiser) => ({
        value: advertiser.id,
        companyName: advertiser.name,
        label: `${advertiser.name} (${advertiser.id})`,
        type: advertiser.type
    }))
}

export const getCompaniesWithIdName = async (client) => {
    const userprofile = await getUser(client);
    const companiesLinked = userprofile.companies;
    return new Map(companiesLinked.map(company => [String(company.id), company.name]));
}

//custom sort for Date based on format as passed in DATATABLE_COLUMNS
export function customSortDate(dateFormat) {
    return (key, order, data) => {
        const sortedData = data.sort((previous, next) => {
            const momentPrev = previous.row[0][key] ? moment(previous.row[0][key], dateFormat) : null
            const momentNext = next.row[0][key] ? moment(next.row[0][key], dateFormat) : null

            if (momentPrev === null && momentNext === null)
                return 0;

            if (order === ASCENDING)
                return !momentPrev ? -1 : !momentNext ? 1 : momentPrev.diff(momentNext);
            else if (order === DESCENDING)
                return !momentPrev ? 1 : !momentNext ? -1 : momentNext.diff(momentPrev);
        })
        return sortedData;
    }
}

// custom sort for formatted numerical fields
export function customSortByKey(key, order, data) {
    return data.sort((previous, next) => {
        const prevValue = previous.row[0][key];
        const nextValue = next.row[0][key];

        const handleSort = (value, comparisonValue) => {
            if (value === null && comparisonValue === null) {
                return 0;
            } else if (value === null) {
                return -1;
            } else if (comparisonValue === null) {
                return 1;
            } else {
                return (value - comparisonValue);
            }
        }

        if (order === ASCENDING) {
            return handleSort(prevValue, nextValue);
        } else if (order === DESCENDING) {
            return handleSort(nextValue, prevValue);
        }
    })
}

export function getDateComparator(dateFormat) {
    return (date1, date2) => {
        const momentPrev = date1 ? moment(date1, dateFormat) : null;
        const momentNext = date2 ? moment(date2, dateFormat) : null;
        if (!momentPrev && !momentNext) return 0;
        return !momentPrev ? -1 : !momentNext ? 1 : (momentPrev.isAfter(momentNext) ? 1 : -1);
    }
}

export const fetchAdvertiserIds = (advertisersObject) => {
    let listOfIds = [];
    if (Array.isArray(advertisersObject) && advertisersObject.length > 0) {
        advertisersObject.forEach(item => {
            listOfIds.push(item.id);
        });
    }
    return listOfIds;
}

export function filterColumnsOnSelectionBasis(columns, selectedColumns) {
    const allColumns = columns.reduce((accumulator, currentValue) => {
        accumulator[currentValue.key] = currentValue
        return accumulator
    }, {});

    const updatedColumns = Array.from(selectedColumns)
        .filter((col) => {
            return allColumns[col];
        })
        .map(col => {
            return allColumns[col];
        });
    return updatedColumns;
}

export function getSelectedColumnsForDownload(columns) {
    const selectedColumnsForDownload = new Set(columns);
    selectedColumnsForDownload.add("advertisercurrency").add("timezone");
    return selectedColumnsForDownload;
}

export function compareSets(firstSet, secondSet) {
    if (firstSet.size !== secondSet.size) {
        return false;
    }
    return Array.from(secondSet).every(element => {
        return firstSet.has(element);
    })
}

export function isAdvertiserLinked(linkedAdvertiserCompanies, currentCompany) {
    if (linkedAdvertiserCompanies !== undefined && linkedAdvertiserCompanies.length > 0) {
        return linkedAdvertiserCompanies.some(company => company.id === currentCompany.id);
    }
    return false;
}

export const getUserView = async (user, client) => {
    if (process.env.REACT_APP_IS_IN_MAINTENANCE === "true") {
        return appConstant.MAINTENANCE_VIEW;
    } else if (user !== null && user.currentCompany) {
        const userprofile = await getUser(client);
        if (user.currentCompany.type === "publisher") {
            return appConstant.UNAUTHORIZED_VIEW;
        } else {
            const isLinked = isAdvertiserLinked(userprofile.companies, user.currentCompany);
            if (isLinked) {
                return appConstant.AUTHORIZED_VIEW;
            } else {
                return appConstant.BLANKSLATE_VIEW;
            }
        }
    }
    return appConstant.UNAUTHORIZED_VIEW;
};

export function convertToMoment(params, columnName) {
    return (params && params.data[columnName]) ? (moment(params.data[columnName], appConstant.DATE_TIME_FORMAT).toDate()) : null;
}

export function customDateTimeFilter(columnName) {
    return DateTimeFilter(params => convertToMoment(params, columnName), ['reset', 'apply']);
}

export function getValueGetter(valueFormatter) {
    return (params) => {
        const cellValue = params.data[params.colDef.field];
        return valueFormatter({value: cellValue});
    }
}

export function sortId(valueOne, valueTwo) {
    const convertedValueOne = BigInt(valueOne);
    const convertedValueTwo = BigInt(valueTwo);
    return (convertedValueOne < convertedValueTwo) ? -1 : 1; //sortId is for placementID field which has unique values and can't be same.
}

export function getTextAfterLastDot(input) {
    return input.map((header) => {
        const headerField = header.field;
        const index = headerField.lastIndexOf(".") + 1;
        const subString = headerField.substring(index, headerField.length);
        return subString;
    });
}

export const headerAsCsvFormat = (workSheetHeaders) => {
    const mappedHeaders = workSheetHeaders.map(header => `"${header.join('","')}"`);
    mappedHeaders.push("");
    return mappedHeaders.join("\n");
}

export const downloadTemplateAndFailedPlacement = (placementDetailsCsvFormat, fileName, sheetName, bulkUploadDataTableColumns, bulkUploadTemplateTranslations) => {
    const headers = [bulkUploadTemplateTranslations.FIELD, ...bulkUploadDataTableColumns.map((obj) => {
        return obj.headerName;
    })];
    const workSheetHeaders = [
        bulkUploadTemplateTranslations.TEMPLATE_ROW_ONE,
        headers,
        bulkUploadTemplateTranslations.TEMPLATE_ROW_THREE,
        bulkUploadTemplateTranslations.TEMPLATE_ROW_FOUR,
        bulkUploadTemplateTranslations.TEMPLATE_ROW_FIVE,
        bulkUploadTemplateTranslations.TEMPLATE_ROW_SIX,
        bulkUploadTemplateTranslations.TEMPLATE_ROW_SEVEN,
        [],
        headers
    ];

    let dataForDownload = workSheetHeaders;
    if (placementDetailsCsvFormat) {
        const workSheetHeadersCsvFormat = headerAsCsvFormat(workSheetHeaders);
        const workSheetHeaderWithData = workSheetHeadersCsvFormat.concat(placementDetailsCsvFormat);

        const data = XLSX.read(workSheetHeaderWithData, {
            type: 'string',
            cellNF: false,
            cellText: false,
            cellDates: false,
            raw: true
        });
        const dataWorkSheet = data.Sheets['Sheet1'];
        dataForDownload = XLSX.utils.sheet_to_json(dataWorkSheet, {
            header: 1
        });
    }
//create new workbook
    const workBook = XLSX.utils.book_new();

//convert data into sheet
    const workSheet = XLSX.utils.aoa_to_sheet(dataForDownload);

//append sheet to workbook
    XLSX.utils.book_append_sheet(workBook, workSheet, sheetName);
    XLSX.writeFileXLSX(workBook, fileName, {bookType: 'xlsx', type: 'binary', bookSST: true, compression: true});
}

export function getTranslatedDropdownOptions(options) {
    const formattedOptionList = options.map(item =>
        `{value: "${item.value}", label: intl.formatMessage({id: "${item.label}"})}`
    );
    return JSON.stringify(formattedOptionList);
}

export function getTranslatedLabel(formatMessage, value) {
    const getCampaignsDropdownComparatorList = campaignsDropdownComparatorList(formatMessage);
    const selectedDropdownTranslatedList = getCampaignsDropdownComparatorList.find((option) => {
        return option.value === value;
    })
    return selectedDropdownTranslatedList ? selectedDropdownTranslatedList.label : '';
}

export function getTranslatedValueGetter(valueFormatter, formatMessage) {
    return (params) => {
        const cellValue = params.data[params.colDef.field];
        return valueFormatter(formatMessage, cellValue);
    }
}