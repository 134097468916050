import {useLazyQuery} from "@apollo/client";
import {PP_QUERY} from "../../constants/graphql-query-mutation";
import React, {useState} from "react";
import TextField from "./TextField";
import {
    EMPTY,
    HTML_HIDDEN_TAG,
    HYPHEN,
    LEFT_ROUND_BRACKET,
    NOTE_TYPE,
    PIPE,
    PLACEMENT_MODE_EDIT,
    PUBLISHER_PROPERTY_INVALID_PID,
    PUBLISHER_PROPERTY_NOT_AVAILABLE,
    PUBLISHER_PROPERTY_TEXT_FIELD,
    RIGHT_ROUND_BRACKET,
} from "../../constants/appConstant";
import {usePlacementFormDataContext} from "../CampaignsContext/useCampaignsContext";
import {Note} from "@cjdev-internal/visual-stack-x/Note";
import {Box} from "@cjdev-internal/visual-stack-x/Box";
import "./styles/PublisherPropertyId.css"
import {useIntl} from "../../useIntl";

function PublisherPropertyId({handleChange, errorData, defaultValue, yoyCurrentYearRevenue, modeOfPlacement}) {

    const [loadPPQuery, {error, data, loading}] = useLazyQuery(PP_QUERY);
    const [ppId, setPpId] = useState();
    const {formData} = usePlacementFormDataContext();
    const intl = useIntl();
    let checkInvalidPIDErrorMessage = EMPTY;

    if (error && error.message) {
        checkInvalidPIDErrorMessage = error.message.includes(PUBLISHER_PROPERTY_INVALID_PID);
    }

    const getPPDetails = (event) => {
        if (event.target.value !== EMPTY && event.target.value !== ppId) {
            loadPPQuery({variables: {publisherPropertyId: parseInt(event.target.value)}});
        }
        setPpId(parseInt(event.target.value));
        if (errorData) {
            errorData.value = false;
        }
    }
    const handleChangeCondition = data && data.publisherProperty ? data.publisherProperty : error;
    React.useEffect(() => {
        if (!loading && data && data.publisherProperty) {
            const publisherPropertyResponse = {publisherPropertyId: ppId, ...data.publisherProperty};
            handleChange("pid", publisherPropertyResponse);

        } else if (!loading && error && error.message) {
            handleChange("pid", null);
        }
    }, [handleChangeCondition]);

    const YoyWarning = () => {
        return (
            <Box direction="row" gap="xl" className="yoy-warning">
                <Note type={NOTE_TYPE}>{intl.formatMessage({id: 'campaigns.yoy.warning.message'})}</Note>
            </Box>
        );
    }
    const loadingMessage = <p
        className="fetch-message">{intl.formatMessage({id: "campaigns.publisher.property.fetching.message"})}</p>
    const errorCondition = error && error.message ? ppId !== EMPTY ? checkInvalidPIDErrorMessage
        ? intl.formatMessage({id: 'campaigns.publisher.property.invalid.pid'}) + " : " +
        error.message.split(":")[1] :
        intl.formatMessage({id: "campaigns.publisher.property.error.message"}) : HTML_HIDDEN_TAG : EMPTY;
    const helpCondition = loading ? loadingMessage
        : (data && data.publisherProperty
            ? (data.publisherProperty.publisherName === null ?
                intl.formatMessage({id: "campaigns.publisher.name.not.available"}) :
                data.publisherProperty.publisherName)
            + LEFT_ROUND_BRACKET + data.publisherProperty.publisherCompanyId
            + RIGHT_ROUND_BRACKET + PIPE + data.publisherProperty.propertyType + HYPHEN
            + (data.publisherProperty.websiteUrl === PUBLISHER_PROPERTY_NOT_AVAILABLE ?
                intl.formatMessage({id: "campaigns.not.available"})
                : data.publisherProperty.websiteUrl) : EMPTY);

    return (
        <>
            <TextField
                name={PUBLISHER_PROPERTY_TEXT_FIELD}
                required
                label={intl.formatMessage({id: "campaigns.placement.publisher.property.id"})}
                id="pid"
                className="overwrite-legacy-textField"
                type="number"
                onBlur={getPPDetails}
                placeholder={intl.formatMessage({id: "campaigns.enter.number.placeholder"})}
                error={errorCondition === EMPTY ? errorData && errorData.value ? HTML_HIDDEN_TAG : EMPTY : errorCondition}
                help={helpCondition}
                defaultValue={defaultValue}
            />
            {!loading && formData?.forecastPerformance?.yearOverYearCurrentYearRevenue === 0 && errorCondition === EMPTY &&
                <YoyWarning/>}
            {modeOfPlacement === PLACEMENT_MODE_EDIT && formData.forecastPerformance === undefined && (isNaN(ppId) || ppId === undefined) && yoyCurrentYearRevenue === 0 &&
                <YoyWarning/>}
        </>
    );
}

export default PublisherPropertyId;
