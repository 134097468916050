/* To_Be_Deleted - remove the constants once completely switched to new nav */
export const EMPTY = "";
export const COMMA = " , ";
export const HYPHEN = ' - ';
export const SLASH = ' / ';
export const COLON = ': ';
export const LEFT_ROUND_BRACKET = ' (';
export const RIGHT_ROUND_BRACKET = ') ';
export const PIPE = ' | ';
export const HTML_HIDDEN_TAG = <p hidden/>;
export const DISPLAY_NOT_AVAILABLE = "Not Available";
export const PUBLISHER_PROPERTY_INVALID_PID = "Invalid PID";
export const PUBLISHER_PROPERTY_NOT_AVAILABLE = "Not Available";

export const DETAILS_TIMESTAMP_START_DATE = "00:00:00"
export const DETAILS_TIMESTAMP_END_DATE = "23:59:59"
export const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const MMM_DD_YYYY_HH_MI_AM_PM = 'MMM DD, YYYY hh:mm A';
export const YYYY_MM_DD = 'YYYY-MM-DD';
export const MMM_DD_YYYY = 'MMM DD, YYYY';
export const BOOKED = "Booked";
export const PROPOSED = "Proposed";
export const DECLINED = "Declined";
export const STATUS = "status";

/* cleanup */
const NA_FLAT_FEE_OPTION = {value: 'N/A- Flat Fee Only', translationKey: 'campaigns.program.term.options.na.flat.fee.only'};

const NA_OPTION = {value: 'N/A', translationKey: 'campaigns.dropdown.options.na'};

export const EXCEL_ERROR_ROW = "Excel Row No.";
export const EXCEL_ERRORS = ": Error(s):";
export const EXCEL_WARNINGS = ": Warning(s):";

export const PLACEMENT_TYPE = [
    {value: 'App Push', translationKey: 'campaigns.placement.type.options.app.push'},
    {value: 'Article Inclusion', translationKey: 'campaigns.placement.type.options.article.inclusion'},
    {value: 'Cashback Event', translationKey: 'campaigns.placement.type.options.cashback.event'},
    {value: 'Category Leader', translationKey: 'campaigns.placement.type.options.category.leader'},
    {value: 'Category Page', translationKey: 'campaigns.placement.type.options.category.page'},
    {value: 'Content', translationKey: 'campaigns.placement.type.options.content'},
    {value: 'Content - Dedicated Review', translationKey: 'campaigns.placement.type.options.content.dedicated.review'},
    {value: 'Content - Editorial', translationKey: 'campaigns.placement.type.options.content.editorial'},
    {value: 'Content - Gift Guide', translationKey: 'campaigns.placement.type.options.content.gift.guide'},
    {value: 'Content - Multi Merchant Review', translationKey: 'campaigns.placement.type.options.content.multi.merchant.review'},
    {value: 'Coupon Page', translationKey: 'campaigns.placement.type.options.coupon.page'},
    {value: 'Deal Page', translationKey: 'campaigns.placement.type.options.deal.page'},
    {value: 'Double Cashback', translationKey: 'campaigns.placement.type.options.double.cashback'},
    {value: 'Footer Ads', translationKey: 'campaigns.placement.type.options.footer.ads'},
    {value: 'Homepage', translationKey: 'campaigns.placement.type.options.homepage'},
    {value: 'Homepage - Search Keywords', translationKey: 'campaigns.placement.type.options.homepage.search.keywords'},
    {value: 'Homepage Carousel', translationKey: 'campaigns.placement.type.options.homepage.carousel'},
    {value: 'Loyalty', translationKey: 'campaigns.placement.type.options.loyalty'},
    {value: 'Make Good', translationKey: 'campaigns.placement.type.options.make.good'},
    {value: 'Mobile App', translationKey: 'campaigns.placement.type.options.mobile.app'},
    {value: 'Mobile Messaging', translationKey: 'campaigns.placement.type.options.mobile.messaging'},
    {value: 'Mobile Webpage', translationKey: 'campaigns.placement.type.options.mobile.webpage'},
    {value: 'Multi-Merchant Email', translationKey: 'campaigns.placement.type.options.multi-merchant.email'},
    {value: 'Package', translationKey: 'campaigns.placement.type.options.package'},
    {value: 'Pop-Up Ads', translationKey: 'campaigns.placement.type.options.pop-up.ads'},
    {value: 'Search', translationKey: 'campaigns.placement.type.options.search'},
    {value: 'Seasonal Event Page', translationKey: 'campaigns.placement.type.options.seasonal.event.page'},
    {value: 'Social Media - Facebook', translationKey: 'campaigns.placement.type.options.social.media.facebook'},
    {value: 'Social Media - Google+', translationKey: 'campaigns.placement.type.options.social.media.google'},
    {value: 'Social Media - Instagram', translationKey: 'campaigns.placement.type.options.social.media.instagram'},
    {value: 'Social Media - Snapchat', translationKey: 'campaigns.placement.type.options.social.media.snapchat'},
    {value: 'Social Media - TikTok', translationKey: 'campaigns.placement.type.options.social.media.tiktok'},
    {value: 'Social Media - Twitter', translationKey: 'campaigns.placement.type.options.social.media.twitter'},
    {value: 'Social Media - WeChat', translationKey: 'campaigns.placement.type.options.social.media.wechat'},
    {value: 'Social Media - Weibo', translationKey: 'campaigns.placement.type.options.social.media.weibo'},
    {value: 'Social Media - YouTube', translationKey: 'campaigns.placement.type.options.social.media.youtube'},
    {value: 'Social Media - Others', translationKey: 'campaigns.placement.type.options.social.media.others'},
    {value: 'Solo Email', translationKey: 'campaigns.placement.type.options.solo.email'},
    {value: 'Sponsored', translationKey: 'campaigns.placement.type.options.sponsored'},
    {value: 'Staff Picks', translationKey: 'campaigns.placement.type.options.staff.picks'},
    {value: 'Store Page Category', translationKey: 'campaigns.placement.type.options.store.page.category'},
    {value: 'Widget', translationKey: 'campaigns.placement.type.options.widget'},
    {value: 'Other', translationKey: 'campaigns.placement.type.options.other'}
];

export const YES_NO_OPTION = [
    {value: 'Yes', translationKey: 'campaigns.dropdown.options.yes'},
    {value: 'No', translationKey: 'campaigns.dropdown.options.no'},
];

export const DYNAMIC_OR_STATIC_FIELD = [
    {value: 'Dynamic', translationKey: 'campaigns.dynamic.static.options.dynamic'},
    {value: 'Static', translationKey: 'campaigns.dynamic.static.options.static'},
];

export const NEW_OR_COMPED_FIELD = [
    {value: 'Repeated', translationKey: 'campaigns.new.repeated.options.repeated'},
    {value: 'New', translationKey: 'campaigns.new.comped.options.new'}
];

export const PAID_IN_CJ_OPTION = [
    {value: 'Fully Paid', translationKey: 'campaigns.paid.in.cj.options.fully.paid'},
    {value: 'Partially Paid', translationKey: 'campaigns.paid.in.cj.options.partially.paid'},
    {value: 'Not Paid', translationKey: 'campaigns.paid.in.cj.options.not.paid'},
    {value: 'CPA Increase Only', translationKey: 'campaigns.paid.in.cj.options.cpa.increase.only'},
    {value: 'Make Good - No Cost', translationKey: 'campaigns.paid.in.cj.options.make.good.no.cost'},
    NA_OPTION,
];

export const YES_NO_NA_OPTION = [
    ...YES_NO_OPTION,
    NA_OPTION,
];

export const FLAT_FEE_OPTION = [
    ...YES_NO_OPTION,
    NA_FLAT_FEE_OPTION
];

export const PLACEMENT_STATUS_FIELD = [
    {value: BOOKED, translationKey: "campaigns.placement.status.options.booked"},
    {value: PROPOSED, translationKey: "campaigns.placement.status.options.proposed"},
    {value: DECLINED, translationKey: "campaigns.placement.status.options.declined"}
];

export const EVENTS = [
    {value: '', translationKey: 'campaigns.dropdown.options.select'},
    {value: 'August Bank Holiday', translationKey: 'campaigns.events.options.august.bank.holiday'},
    {value: 'Back to School', translationKey: 'campaigns.events.options.back.to.school'},
    {value: 'Black Friday', translationKey: 'campaigns.events.options.black.friday'},
    {value: 'Blue Monday (January)', translationKey: 'campaigns.events.options.blue.monday'},
    {value: 'Boxing Day', translationKey: 'campaigns.events.options.boxing.day'},
    {value: 'Cheltenham Festival', translationKey: 'campaigns.events.options.cheltenham.festival'},
    {value: 'Christmas', translationKey: 'campaigns.events.options.christmas'},
    {value: 'Cyber Monday', translationKey: 'campaigns.events.options.cyber.monday'},
    {value: 'Cyber/Giving Tuesday', translationKey: 'campaigns.events.options.cyber.giving.tuesday'},
    {value: 'Early May Bank Holiday', translationKey: 'campaigns.events.options.early.may.bank.holiday'},
    {value: 'Easter', translationKey: 'campaigns.events.options.easter'},
    {value: 'FA Cup', translationKey: 'campaigns.events.options.fa.cup'},
    {value: 'Father\'s Day', translationKey: 'campaigns.events.options.fathers.day'},
    {value: 'Grand National', translationKey: 'campaigns.events.options.grand.national'},
    {value: 'Halloween', translationKey: 'campaigns.events.options.halloween'},
    {value: 'Holiday', translationKey: 'campaigns.events.options.holiday'},
    {value: 'Independence Day', translationKey: 'campaigns.events.options.independence.day'},
    {value: 'Labor Day', translationKey: 'campaigns.events.options.labor.day'},
    {value: 'Late May Bank Holiday', translationKey: 'campaigns.events.options.late.may.bank.holiday'},
    {value: 'Lunar New Year', translationKey: 'campaigns.events.options.lunar.new.year'},
    {value: 'Memorial Day', translationKey: 'campaigns.events.options.memorial.day'},
    {value: 'Mother\'s Day', translationKey: 'campaigns.events.options.mothers.day'},
    {value: 'New Year', translationKey: 'campaigns.events.options.new.year'},
    {value: 'President\'s Day', translationKey: 'campaigns.events.options.presidents.day'},
    {value: 'Product Launch', translationKey: 'campaigns.events.options.product.launch'},
    {value: 'Single\'s Day', translationKey: 'campaigns.events.options.singles.day'},
    {value: 'St Patrick\'s Day', translationKey: 'campaigns.events.options.st.patricks.day'},
    {value: 'Super Bowl', translationKey: 'campaigns.events.options.super.bowl'},
    {value: 'Tax Season', translationKey: 'campaigns.events.options.tax.season'},
    {value: 'Thanksgiving', translationKey: 'campaigns.events.options.thanksgiving'},
    {value: 'Valentine\'s Day', translationKey: 'campaigns.events.options.valentino'},
    {value: 'Wimbledon', translationKey: 'campaigns.events.options.wimbledon'},
    {value: 'World Cup', translationKey: 'campaigns.events.options.world.cup'}
];

export const OFFER_TYPE_FIELD = [
    {value: "AMOUNT_OFF", translationKey: "campaigns.offer.type.options.amount.off"},
    {value: "BNPL (Buy Now Pay Later)", translationKey: "campaigns.offer.type.options.bnpl"},
    {value: "BOGO", translationKey: "campaigns.offer.type.options.bogo"},
    {value: "COUPON", translationKey: "campaigns.offer.type.options.coupon"},
    {value: "EVERGREEN", translationKey: "campaigns.offer.type.options.evergreen"},
    {value: "FREE_GIFT", translationKey: "campaigns.offer.type.options.free.gift"},
    {value: "FREE_SHIP", translationKey: "campaigns.offer.type.options.free.ship"},
    {value: "INTRODUCTORY_OFFER", translationKey: "campaigns.offer.type.options.introductory.offer"},
    {value: "PERCENT_OFF", translationKey: "campaigns.offer.type.options.percent.off"},
    {value: "PRODUCT", translationKey: "campaigns.offer.type.options.product"},
    {value: "PUBLISHER EXCLUSIVE", translationKey: "campaigns.offer.type.options.publisher.exclusive"},
    {value: "SUBSCRIPTION", translationKey: "campaigns.offer.type.options.subscription"}
];

export const OFFER_CONDITION_FIELD = [
    {value: "BRAND_CARD_SIGNUP_SPECIFIC", translationKey: "campaigns.offer.condition.options.brand.card.signup"},
    {value: "BRAND_CARD_SPECIFIC", translationKey: "campaigns.offer.condition.options.brand.card.specific"},
    {value: "COMBO CODE", translationKey: "campaigns.offer.condition.options.combo.code"},
    {value: "DEMOGRAPHICS", translationKey: "campaigns.offer.condition.options.demographics"},
    {value: "EMAIL_SIGNUP_REQUIRED", translationKey: "campaigns.offer.condition.options.email.signup.required"},
    {value: "LOCATION_SPECIFIC", translationKey: "campaigns.offer.condition.options.location.specific"},
    {value: "LOYALTY_REQUIRED", translationKey: "campaigns.offer.condition.options.loyalty.required"},
    {value: "MEMBERSHIP_REQUIRED", translationKey: "campaigns.offer.condition.options.membership.required"},
    {value: "MULTI_BUY", translationKey: "campaigns.offer.condition.options.multi.buy"},
    {value: "NEW_CUSTOMER_SPECIFIC", translationKey: "campaigns.offer.condition.options.new.customer.specific"},
    {value: "POINT_OF_SALE_SPECIFIC", translationKey: "campaigns.offer.condition.options.point.of.sale.specific"},
    {value: "PRODUCT_SPECIFIC", translationKey: "campaigns.offer.condition.options.product.specific"},
    {value: "PROMO_CODE", translationKey: "campaigns.offer.condition.options.promo.code"},
    {value: "VANITY", translationKey: "campaigns.offer.condition.options.vanity"},
    {value: "N/A", translationKey: "campaigns.offer.condition.options.na"}
];

export const MAX_LENGTH_500 = "500";
export const MAX_LENGTH_200 = "200";
export const PAYMENT_NOTES_TEXT_FIELD_MAX_CHARACTER = "4000"
export const COPY_OF = "Copy of"
export const SPACE = ' ';
export const YES = 'Yes';
export const NO = 'No';

/* cleanup */

/*DataTable Column Headers and Create Modal Labels*/
export const PLACEMENT_ID_LABEL = "Placement ID";
export const ADVERTISER_CID_LABEL = "Advertiser CID";
export const ADVERTISER_NAME_LABEL = "Advertiser Name";
export const PLACEMENT_STATUS_LABEL = "Placement Status";
export const CALENDAR_YEAR_LABEL = "Calendar Year";
export const CALENDAR_QTR_LABEL = "Calendar Quarter";
export const CALENDAR_MONTH_LABEL = "Calendar Month";
export const CALENDAR_WEEK_LABEL = "Calendar Week";
export const PLACEMENT_END_DATE_LABEL = "End Date & Time";
export const PUBLISHER_NAME_LABEL = "Publisher Name";
export const PUBLISHER_CID_LABEL = "Publisher CID";
export const PUBLISHER_PROPERTY_TEXT_FIELD = "Publisher Property ID";
export const PLACEMENT_TYPE_LABEL = "Placement Type [Location]";
export const PLACEMENT_NAME = "Placement Name";
export const ORIGINAL_PUBLISHER_COMMISSION_LABEL = "Original Publisher Commission (%)";
export const NEW_PUBLISHER_COMMISSION_LABEL = "Publisher Commission during Placement (%)";
export const ORIGINAL_FLAT_FEE_LABEL = "Original/Rate Card Flat Fee Cost";
export const CJ_FLAT_FEE_LABEL = "Negotiated Flat Fee Cost";
export const WEBSITE_URL_LABEL = "Website URL";
export const PROPERTY_TYPE_LABEL = "Property Type";
export const PROPERTY_MODEL_LABEL = "Property Model";
export const PERSONALIZED_TARGETED_LABEL = "Personalized/Targeted Offer";
export const DYNAMIC_STATIC_LABEL = "Dynamic vs. Static Placement Type";
export const RANK_OR_SPOT_NUMBER_OF_PLACEMENT_TEXT_FIELD = "Placement Spot/Position";
export const EVENTS_LABEL = "Events";
export const SEND_SIZE_LABEL = "Send Size, Expected Unique Views";
export const PACKAGE_NAME_LABEL = "Package Name";
export const NEW_OR_COMPED_LABEL = "Repeated from Last Year or New";
export const VIP_CONTENT_LABEL = "CJ Influence Campaign";
export const PROPOSED_OFFER_USED_DESCRIPTION_TEXT_FIELD = "Proposed Ad Copy/Offer Description";
export const SOV_BEFORE_LABEL = "Share of Voice Pre-Placement (%)";
export const ACTUAL_SOV_AFTER_LABEL = "Share of Voice Post-Placement (%)";
export const YOY_GROWTH_TREND_LABEL = "Organic Lift over Baseline (%)";
export const PRIOR_PLACEMENT_LIFT_LABEL = "Expected Placement Lift over Baseline (%)";
export const OTHER_EXPECTED_LIFT_LABEL = "Other Lift over Baseline e.g. Sale (%)";
export const TIERED_OFFER_LABEL = "Tiered Offer Details";
export const OFFER_TYPE_LABEL = "Offer Type";
export const OFFER_VALUE_LABEL = "Offer Value";
export const OFFER_CONDITIONS_LABEL = "Offer Conditions";
export const OFFER_THRESHOLD_LABEL = "Offer Threshold";
export const LINK_ID_LABEL = "Link ID for Placement";
export const ASSETS_NEEDED_LABEL = "Assets Needed";
export const ASSET_DUE_DATE_LABEL = "Asset Due Date";
export const ASSETS_SENT_BY_ADVERTISER_LABEL = "Assets Sent by Advertiser on";
export const CJ_MARKETPLACE_IO_LABEL = "Insertion Order ID from CJ Marketplace";
export const PUBLISHER_IO_LABEL = "Publisher's Insertion Order ID";
export const IO_SIGNED_LABEL = "IO Signed?";
export const PROGRAM_TERM_NAME_LABEL = "Program Terms Name";
export const PROGRAM_TERM_PUSHED_LABEL = "Have Program Terms been Pushed?";
export const CASHBACK_AMOUNT_ONSITE_LABEL = "Cashback/Incentive on Placement";
export const PUBLISHER_USED_LINK_ID_LABEL = "Publisher Used Specified Link ID?";
export const OFFER_DESCRIPTION_TEXT_LABEL = "Live Ad Copy on Publisher Property";
export const CORRECT_OFFER_USED_LABEL = "Was Correct Offer Used in Placement?";
export const PATH_TO_SAVED_EMAIL_LABEL = "Path for Screenshot/Email";
export const VALIDATION_NOTES_LABEL = "Validation Notes";
export const PAID_IN_CJ_LABEL = "Paid in CJ?";
export const PAYMENT_DATE_LABEL = "Last Payment Date";
export const PAYMENT_OID_LABEL = "Payment OID or Transaction ID";
export const AMOUNT_PAID_LABEL = "Amount Paid";
export const PAYMENT_NOTES_LABEL = "Payment Notes";
export const PROJECTED_SPEND_WITH_PLACEMENT_LABEL = "Projected Spend with Placement";
export const PROJECTED_SPEND_WITHOUT_PLACEMENT_LABEL = "Projected Spend without Placement";
export const PROJECTED_REVENUE_WITH_PLACEMENT_LABEL = "Projected Revenue with Placement";
export const PROJECTED_REVENUE_WITHOUT_PLACEMENT_LABEL = "Projected Revenue without Placement";
export const PROJECTED_ROAS_LABEL = "Projected ROAS";
export const PROJECTED_INCREMENTAL_REVENUE_LABEL = "Projected Incremental Revenue";
export const PROJECTED_INCREMENTAL_SPEND_LABEL = "Projected Incremental Spend";
export const PROJECTED_INCREMENTAL_ROAS_LABEL = "Projected Incremental ROAS";
export const LAST_YEAR_ACTUAL_REVENUE_LABEL = "Last Year Rev. for Same Placement Dates";
export const LAST_YEAR_ACTUAL_COMMISSION_LABEL = "Last Year Comm. for Same Placement Dates";
export const YOY_QUARTER_START_DATE_CURRENT_YEAR_LABEL = "Past 3 mos - Current Year(Start Date)";
export const YOY_QUARTER_END_DATE_CURRENT_YEAR_LABEL = "Past 3 mos - Current Year(End Date)";
export const YOY_QUARTER_START_DATE_LAST_YEAR_LABEL = "Past 3 mos - Last Year(Start Date)";
export const YOY_QUARTER_END_DATE_LAST_YEAR_LABEL = "Past 3 mos - Last Year(End Date)";
export const YOY_CALCULATION_CURRENT_YEAR_REVENUE_LABEL = "Past 3 mos - Current Year Revenue";
export const YOY_CALCULATION_LAST_YEAR_REVENUE_LABEL = "Past 3 mos - Last Year Revenue";
export const YOY_OBSERVED_PERCENTAGE_LABEL = "Past 3 mos - YoY Observed (%)";
export const AVERAGE_DAILY_REVENUE_BASELINE_LABEL = "Average Daily Revenue Baseline";
export const CALCULATED_BASELINE_USED_LABEL = "Auto Calculated Baseline Used";
export const ACTUAL_REVENUE_LABEL = "Actual Revenue";
export const ACTUAL_INCREMENTAL_REVENUE_LABEL = "Actual Incremental Revenue";
export const ACTUAL_TOTAL_COMMISSIONS_LABEL = "Actual Total Commissions";
export const ACTUAL_TOTAL_SPEND_LABEL = "Actual Total Spend";
export const ACTUAL_INCREMENTAL_SPEND_LABEL = "Actual Incremental Spend";
export const ACTUAL_ROAS_LABEL = "Actual ROAS";
export const ACTUAL_INCREMENTAL_ROAS_LABEL = "Actual Incremental ROAS";
export const ACTUAL_CLICKS_LABEL = "Actual Clicks";
export const ACTUAL_RPV_LABEL = "Actual RPV";
export const ACTUAL_SALES_LABEL = "Actual Action Count";
export const ACTUAL_CR_LABEL = "Actual CR (%)";
export const ACTUAL_PLACEMENT_LIFT_LABEL = "Actual Placement Lift (%)";
export const REVENUE_DELTA_LABEL = "Revenue Delta $ (Projected vs. Actuals)";
export const REVENUE_ACCURACY_LABEL = "Revenue Accuracy % (Projected vs. Actuals)";
export const ROAS_ACCURACY_LABEL = "ROAS Accuracy % (Projected vs. Actuals)";
export const PROGRAM_TERM_DESCRIPTION_LABEL = "Program Terms Description";

/* Excel Addition Column Header Labels*/
export const START_DATE_LABEL = "Start Date";
export const END_DATE_LABEL = "End Date";
export const ADVERTISER_TIMEZONE_LABEL = "Advertiser Timezone";
export const ADVERTISERS_FUNCTIONAL_CURRENCY_LABEL = "Advertiser's Functional Currency";
export const FISCAL_YEAR_LABEL = "Fiscal Year";
export const FISCAL_QUARTER_LABEL = "Fiscal Quarter";
export const FISCAL_MONTH_LABEL = "Fiscal Month";
export const FISCAL_WEEK_LABEL = "Fiscal Week";
export const PLACEMENT_CREATION_DATE_TIME_LABEL = "Placement Creation Date & Time";
export const PLACEMENT_CREATED_BY_LABEL = "Placement Created By";
export const START_TIME_LABEL = "Start Time";
export const END_TIME_LABEL = "End Time";
export const PERFORMANCE_LAST_UPDATED_ON_LABEL = "Performance Last Updated On";
export const CJ_INVOICE_MONTH_LABEL = "Chargeable Month on CJ Invoice";
export const VALIDATION_DETAILS_CREATED_ON_LABEL = "Validation Details Created On";
export const VALIDATION_DETAILS_CREATED_BY_LABEL = "Validation Details Created By";
export const VALIDATION_DETAILS_LAST_UPDATED_ON_LABEL = "Validation Details Last Updated On";
export const VALIDATION_DETAILS_LAST_UPDATED_BY_LABEL = "Validation Details Last Updated By";
export const PAYMENT_DETAILS_CREATED_ON_LABEL = "Payment Details Created On";
export const PAYMENT_DETAILS_CREATED_BY_LABEL = "Payment Details Created By";
export const PAYMENT_DETAILS_LAST_UPDATED_ON_LABEL = "Payment Details Last Updated On";
export const PAYMENT_DETAILS_LAST_UPDATED_BY_LABEL = "Payment Details Last Updated By";

export const EXCEL_SINGLE_SHEET_NAME = 'Placements';
export const EXCEL_SINGLE_SHEET_COLUMNS = [
    {key: "id", label: PLACEMENT_ID_LABEL},
    {key: "placementname", label: PLACEMENT_NAME},
    {key: "advertisercompanyid", label: ADVERTISER_CID_LABEL},
    {key: "advertisername", label: ADVERTISER_NAME_LABEL},
    {key: "startdate", label: START_DATE_LABEL},
    {key: "starttime", label: START_TIME_LABEL},
    {key: "enddate", label: END_DATE_LABEL},
    {key: "endtime", label: END_TIME_LABEL},
    {key: "timezone", label: ADVERTISER_TIMEZONE_LABEL},
    {key: "status", label: PLACEMENT_STATUS_LABEL},
    {key: "publishername", label: PUBLISHER_NAME_LABEL},
    {key: "publishercompanyid", label: PUBLISHER_CID_LABEL},
    {key: "publisherpropertyid", label: PUBLISHER_PROPERTY_TEXT_FIELD},
    {key: "placementtype", label: PLACEMENT_TYPE_LABEL},
    {key: "advertisercurrency", label: ADVERTISERS_FUNCTIONAL_CURRENCY_LABEL},
    {key: "calendaryear", label: CALENDAR_YEAR_LABEL},
    {key: "calendarqtr", label: CALENDAR_QTR_LABEL},
    {key: "calendarmonth", label: CALENDAR_MONTH_LABEL},
    {key: "calendarweek", label: CALENDAR_WEEK_LABEL},
    {key: "fiscalyear", label: FISCAL_YEAR_LABEL},
    {key: "fiscalquarter", label: FISCAL_QUARTER_LABEL},
    {key: "fiscalmonth", label: FISCAL_MONTH_LABEL},
    {key: "fiscalweek", label: FISCAL_WEEK_LABEL},
    {key: "websiteurl", label: WEBSITE_URL_LABEL},
    {key: "propertytype", label: PROPERTY_TYPE_LABEL},
    {key: "propertymodel", label: PROPERTY_MODEL_LABEL},
    {key: "personalizedortargeted", label: PERSONALIZED_TARGETED_LABEL},
    {key: "dynamicorstatic", label: DYNAMIC_STATIC_LABEL},
    {key: "rankofplacement", label: RANK_OR_SPOT_NUMBER_OF_PLACEMENT_TEXT_FIELD},
    {key: "event", label: EVENTS_LABEL},
    {key: "performancesendsizeexpecteduniquevalues", label: SEND_SIZE_LABEL},
    {key: "packagename", label: PACKAGE_NAME_LABEL},
    {key: "neworcompedplacement", label: NEW_OR_COMPED_LABEL},
    {key: "vipcontent", label: VIP_CONTENT_LABEL},
    {key: "proposedofferuseddescription", label: PROPOSED_OFFER_USED_DESCRIPTION_TEXT_FIELD},
    {key: "performancesovbefore", label: SOV_BEFORE_LABEL},
    {key: "lastyearsactualrevenue", label: LAST_YEAR_ACTUAL_REVENUE_LABEL},
    {key: "lastyearsactualcommissions", label: LAST_YEAR_ACTUAL_COMMISSION_LABEL},
    {key: "yoyquarterstartdatecurrentyear", label: YOY_QUARTER_START_DATE_CURRENT_YEAR_LABEL},
    {key: "yoyquarterenddatecurrentyear", label: YOY_QUARTER_END_DATE_CURRENT_YEAR_LABEL},
    {key: "yoyquarterstartdatelastyear", label: YOY_QUARTER_START_DATE_LAST_YEAR_LABEL},
    {key: "yoyquarterenddatelastyear", label: YOY_QUARTER_END_DATE_LAST_YEAR_LABEL},
    {key: "yearoveryearlastyearrevenue", label: YOY_CALCULATION_LAST_YEAR_REVENUE_LABEL},
    {key: "yearoveryearcurrentyearrevenue", label: YOY_CALCULATION_CURRENT_YEAR_REVENUE_LABEL},
    {key: "yearoveryearobserved", label: YOY_OBSERVED_PERCENTAGE_LABEL},
    {key: "performanceoriginalpublishercommission", label: ORIGINAL_PUBLISHER_COMMISSION_LABEL},
    {key: "performancenewpublishercommission", label: NEW_PUBLISHER_COMMISSION_LABEL},
    {key: "performanceoriginalratecardflatfeeprice", label: ORIGINAL_FLAT_FEE_LABEL},
    {key: "performancecjnegotiatedflatfeecost", label: CJ_FLAT_FEE_LABEL},
    {key: "performanceyoygrowthtrendused", label: YOY_GROWTH_TREND_LABEL},
    {key: "performancepredictedplacementlift", label: PRIOR_PLACEMENT_LIFT_LABEL},
    {key: "performanceotherexpectedlift", label: OTHER_EXPECTED_LIFT_LABEL},
    {key: "averagebaselinerevenue", label: AVERAGE_DAILY_REVENUE_BASELINE_LABEL},
    {key: "isbaselinecalculated", label: CALCULATED_BASELINE_USED_LABEL},
    {key: "projectedspendwithplacement", label: PROJECTED_SPEND_WITH_PLACEMENT_LABEL},
    {key: "projectedspendwithoutplacement", label: PROJECTED_SPEND_WITHOUT_PLACEMENT_LABEL},
    {key: "projectedrevenuewithplacement", label: PROJECTED_REVENUE_WITH_PLACEMENT_LABEL},
    {key: "projectedrevenuewithoutplacement", label: PROJECTED_REVENUE_WITHOUT_PLACEMENT_LABEL},
    {key: "projectedreturnonadspend", label: PROJECTED_ROAS_LABEL},
    {key: "projectedincrementalrevenue", label: PROJECTED_INCREMENTAL_REVENUE_LABEL},
    {key: "projectedincrementalspend", label: PROJECTED_INCREMENTAL_SPEND_LABEL},
    {key: "projectedincrementalreturnonadspend", label: PROJECTED_INCREMENTAL_ROAS_LABEL},
    {key: "actualrevenue", label: ACTUAL_REVENUE_LABEL},
    {key: "actualincrementalrevenue", label: ACTUAL_INCREMENTAL_REVENUE_LABEL},
    {key: "actualtotalcommissions", label: ACTUAL_TOTAL_COMMISSIONS_LABEL},
    {key: "actualtotalspend", label: ACTUAL_TOTAL_SPEND_LABEL},
    {key: "actualincrementalspend", label: ACTUAL_INCREMENTAL_SPEND_LABEL},
    {key: "actualreturnonadspend", label: ACTUAL_ROAS_LABEL},
    {key: "actualincrementalreturnonadspend", label: ACTUAL_INCREMENTAL_ROAS_LABEL},
    {key: "actualclicks", label: ACTUAL_CLICKS_LABEL},
    {key: "actualrevenuepervisits", label: ACTUAL_RPV_LABEL},
    {key: "actualsales", label: ACTUAL_SALES_LABEL},
    {key: "actualnumberofsalesgeneratedperclick", label: ACTUAL_CR_LABEL},
    {key: "actualplacementlift", label: ACTUAL_PLACEMENT_LIFT_LABEL},
    {key: "revenuedelta", label: REVENUE_DELTA_LABEL},
    {key: "revenueaccuracy", label: REVENUE_ACCURACY_LABEL},
    {key: "returnonadspendaccuracy", label: ROAS_ACCURACY_LABEL},
    {key: "performanceactualsovafter", label: ACTUAL_SOV_AFTER_LABEL},
    {key: "bookingcjmarketplaceinsertionordernumber", label: CJ_MARKETPLACE_IO_LABEL},
    {key: "bookingotherinsertionordernumber", label: PUBLISHER_IO_LABEL},
    {key: "bookinginsertionordersignedindicator", label: IO_SIGNED_LABEL},
    {key: "bookingprogramtermdescription", label: PROGRAM_TERM_DESCRIPTION_LABEL},
    {key: "bookingprogramtermname", label: PROGRAM_TERM_NAME_LABEL},
    {key: "bookingprogramtermspushedindicator", label: PROGRAM_TERM_PUSHED_LABEL},
    {key: "bookinglinkid", label: LINK_ID_LABEL},
    {key: "bookingassetsneeded", label: ASSETS_NEEDED_LABEL},
    {key: "bookingassetduedate", label: ASSET_DUE_DATE_LABEL},
    {key: "bookingassetssenttoadvertiser", label: ASSETS_SENT_BY_ADVERTISER_LABEL},
    {key: "bookingoffertype", label: OFFER_TYPE_LABEL},
    {key: "bookingoffervalue", label: OFFER_VALUE_LABEL},
    {key: "bookingofferconditions", label: OFFER_CONDITIONS_LABEL},
    {key: "bookingofferthreshold", label: OFFER_THRESHOLD_LABEL},
    {key: "bookingtieredoffer", label: TIERED_OFFER_LABEL},
    {key: "bookingcashbackorincentiveamountonsite", label: CASHBACK_AMOUNT_ONSITE_LABEL},
    {key: "bookinginvoicemonth", label: CJ_INVOICE_MONTH_LABEL},
    {key: "publisherusedspecifiedlinkid", label: PUBLISHER_USED_LINK_ID_LABEL},
    {key: "description", label: OFFER_DESCRIPTION_TEXT_LABEL},
    {key: "wascorrectofferused", label: CORRECT_OFFER_USED_LABEL},
    {key: "screenshotlink", label: PATH_TO_SAVED_EMAIL_LABEL},
    {key: "validationnotes", label: VALIDATION_NOTES_LABEL},
    {key: "paidincj", label: PAID_IN_CJ_LABEL},
    {key: "transactiondate", label: PAYMENT_DATE_LABEL},
    {key: "transactionid", label: PAYMENT_OID_LABEL},
    {key: "amount", label: AMOUNT_PAID_LABEL},
    {key: "notes", label: PAYMENT_NOTES_LABEL},
    {key: "placementcreated", label: PLACEMENT_CREATION_DATE_TIME_LABEL},
    {key: "placementcreatedby", label: PLACEMENT_CREATED_BY_LABEL},
    {key: "forecastlastupdated", label: PERFORMANCE_LAST_UPDATED_ON_LABEL},
    {key: "validationcreated", label: VALIDATION_DETAILS_CREATED_ON_LABEL},
    {key: "validationcreatedby", label: VALIDATION_DETAILS_CREATED_BY_LABEL},
    {key: "validationlastupdated", label: VALIDATION_DETAILS_LAST_UPDATED_ON_LABEL},
    {key: "validationlastupdatedby", label: VALIDATION_DETAILS_LAST_UPDATED_BY_LABEL},
    {key: "paymentcreated", label: PAYMENT_DETAILS_CREATED_ON_LABEL},
    {key: "paymentcreatedby", label: PAYMENT_DETAILS_CREATED_BY_LABEL},
    {key: "paymentlastupdated", label: PAYMENT_DETAILS_LAST_UPDATED_ON_LABEL},
    {key: "paymentlastupdatedby", label: PAYMENT_DETAILS_LAST_UPDATED_BY_LABEL}
];

export const EXCEL_MULTI_SHEET1_NAME = 'Placement Projections';
export const EXCEL_MULTI_SHEET2_NAME = 'Placement Performance';
export const EXCEL_MULTI_SHEET3_NAME = 'Placement Audit & Payment';
export const EXCEL_MULTI_SHEET1_COLUMNS = [
    {key: "id", label: PLACEMENT_ID_LABEL},
    {key: "placementname", label: PLACEMENT_NAME},
    {key: "advertisercompanyid", label: ADVERTISER_CID_LABEL},
    {key: "advertisername", label: ADVERTISER_NAME_LABEL},
    {key: "startdate", label: START_DATE_LABEL},
    {key: "starttime", label: START_TIME_LABEL},
    {key: "enddate", label: END_DATE_LABEL},
    {key: "endtime", label: END_TIME_LABEL},
    {key: "timezone", label: ADVERTISER_TIMEZONE_LABEL},
    {key: "status", label: PLACEMENT_STATUS_LABEL},
    {key: "publishername", label: PUBLISHER_NAME_LABEL},
    {key: "publishercompanyid", label: PUBLISHER_CID_LABEL},
    {key: "publisherpropertyid", label: PUBLISHER_PROPERTY_TEXT_FIELD},
    {key: "placementtype", label: PLACEMENT_TYPE_LABEL},
    {key: "advertisercurrency", label: ADVERTISERS_FUNCTIONAL_CURRENCY_LABEL},
    {key: "calendaryear", label: CALENDAR_YEAR_LABEL},
    {key: "calendarqtr", label: CALENDAR_QTR_LABEL},
    {key: "calendarmonth", label: CALENDAR_MONTH_LABEL},
    {key: "calendarweek", label: CALENDAR_WEEK_LABEL},
    {key: "fiscalyear", label: FISCAL_YEAR_LABEL},
    {key: "fiscalquarter", label: FISCAL_QUARTER_LABEL},
    {key: "fiscalmonth", label: FISCAL_MONTH_LABEL},
    {key: "fiscalweek", label: FISCAL_WEEK_LABEL},
    {key: "websiteurl", label: WEBSITE_URL_LABEL},
    {key: "propertytype", label: PROPERTY_TYPE_LABEL},
    {key: "propertymodel", label: PROPERTY_MODEL_LABEL},
    {key: "personalizedortargeted", label: PERSONALIZED_TARGETED_LABEL},
    {key: "dynamicorstatic", label: DYNAMIC_STATIC_LABEL},
    {key: "rankofplacement", label: RANK_OR_SPOT_NUMBER_OF_PLACEMENT_TEXT_FIELD},
    {key: "event", label: EVENTS_LABEL},
    {key: "performancesendsizeexpecteduniquevalues", label: SEND_SIZE_LABEL},
    {key: "packagename", label: PACKAGE_NAME_LABEL},
    {key: "neworcompedplacement", label: NEW_OR_COMPED_LABEL},
    {key: "vipcontent", label: VIP_CONTENT_LABEL},
    {key: "proposedofferuseddescription", label: PROPOSED_OFFER_USED_DESCRIPTION_TEXT_FIELD},
    {key: "performancesovbefore", label: SOV_BEFORE_LABEL},
    {key: "lastyearsactualrevenue", label: LAST_YEAR_ACTUAL_REVENUE_LABEL},
    {key: "lastyearsactualcommissions", label: LAST_YEAR_ACTUAL_COMMISSION_LABEL},
    {key: "yoyquarterstartdatecurrentyear", label: YOY_QUARTER_START_DATE_CURRENT_YEAR_LABEL},
    {key: "yoyquarterenddatecurrentyear", label: YOY_QUARTER_END_DATE_CURRENT_YEAR_LABEL},
    {key: "yoyquarterstartdatelastyear", label: YOY_QUARTER_START_DATE_LAST_YEAR_LABEL},
    {key: "yoyquarterenddatelastyear", label: YOY_QUARTER_END_DATE_LAST_YEAR_LABEL},
    {key: "yearoveryearlastyearrevenue", label: YOY_CALCULATION_LAST_YEAR_REVENUE_LABEL},
    {key: "yearoveryearcurrentyearrevenue", label: YOY_CALCULATION_CURRENT_YEAR_REVENUE_LABEL},
    {key: "yearoveryearobserved", label: YOY_OBSERVED_PERCENTAGE_LABEL},
    {key: "performanceoriginalpublishercommission", label: ORIGINAL_PUBLISHER_COMMISSION_LABEL},
    {key: "performancenewpublishercommission", label: NEW_PUBLISHER_COMMISSION_LABEL},
    {key: "performanceoriginalratecardflatfeeprice", label: ORIGINAL_FLAT_FEE_LABEL},
    {key: "performancecjnegotiatedflatfeecost", label: CJ_FLAT_FEE_LABEL},
    {key: "performanceyoygrowthtrendused", label: YOY_GROWTH_TREND_LABEL},
    {key: "performancepredictedplacementlift", label: PRIOR_PLACEMENT_LIFT_LABEL},
    {key: "performanceotherexpectedlift", label: OTHER_EXPECTED_LIFT_LABEL},
    {key: "averagebaselinerevenue", label: AVERAGE_DAILY_REVENUE_BASELINE_LABEL},
    {key: "isbaselinecalculated", label: CALCULATED_BASELINE_USED_LABEL},
    {key: "projectedspendwithplacement", label: PROJECTED_SPEND_WITH_PLACEMENT_LABEL},
    {key: "projectedspendwithoutplacement", label: PROJECTED_SPEND_WITHOUT_PLACEMENT_LABEL},
    {key: "projectedrevenuewithplacement", label: PROJECTED_REVENUE_WITH_PLACEMENT_LABEL},
    {key: "projectedrevenuewithoutplacement", label: PROJECTED_REVENUE_WITHOUT_PLACEMENT_LABEL},
    {key: "projectedreturnonadspend", label: PROJECTED_ROAS_LABEL},
    {key: "projectedincrementalrevenue", label: PROJECTED_INCREMENTAL_REVENUE_LABEL},
    {key: "projectedincrementalspend", label: PROJECTED_INCREMENTAL_SPEND_LABEL},
    {key: "projectedincrementalreturnonadspend", label: PROJECTED_INCREMENTAL_ROAS_LABEL},
    {key: "placementcreated", label: PLACEMENT_CREATION_DATE_TIME_LABEL},
    {key: "placementcreatedby", label: PLACEMENT_CREATED_BY_LABEL}
]
export const EXCEL_MULTI_SHEET2_COLUMNS = [
    {key: "id", label: PLACEMENT_ID_LABEL},
    {key: "placementname", label: PLACEMENT_NAME},
    {key: "advertisercompanyid", label: ADVERTISER_CID_LABEL},
    {key: "advertisername", label: ADVERTISER_NAME_LABEL},
    {key: "startdate", label: START_DATE_LABEL},
    {key: "starttime", label: START_TIME_LABEL},
    {key: "enddate", label: END_DATE_LABEL},
    {key: "endtime", label: END_TIME_LABEL},
    {key: "timezone", label: ADVERTISER_TIMEZONE_LABEL},
    {key: "status", label: PLACEMENT_STATUS_LABEL},
    {key: "publishername", label: PUBLISHER_NAME_LABEL},
    {key: "publishercompanyid", label: PUBLISHER_CID_LABEL},
    {key: "publisherpropertyid", label: PUBLISHER_PROPERTY_TEXT_FIELD},
    {key: "placementtype", label: PLACEMENT_TYPE_LABEL},
    {key: "advertisercurrency", label: ADVERTISERS_FUNCTIONAL_CURRENCY_LABEL},
    {key: "calendaryear", label: CALENDAR_YEAR_LABEL},
    {key: "calendarqtr", label: CALENDAR_QTR_LABEL},
    {key: "calendarmonth", label: CALENDAR_MONTH_LABEL},
    {key: "calendarweek", label: CALENDAR_WEEK_LABEL},
    {key: "fiscalyear", label: FISCAL_YEAR_LABEL},
    {key: "fiscalquarter", label: FISCAL_QUARTER_LABEL},
    {key: "fiscalmonth", label: FISCAL_MONTH_LABEL},
    {key: "fiscalweek", label: FISCAL_WEEK_LABEL},
    {key: "websiteurl", label: WEBSITE_URL_LABEL},
    {key: "propertymodel", label: PROPERTY_MODEL_LABEL},
    {key: "personalizedortargeted", label: PERSONALIZED_TARGETED_LABEL},
    {key: "dynamicorstatic", label: DYNAMIC_STATIC_LABEL},
    {key: "rankofplacement", label: RANK_OR_SPOT_NUMBER_OF_PLACEMENT_TEXT_FIELD},
    {key: "event", label: EVENTS_LABEL},
    {key: "performancesendsizeexpecteduniquevalues", label: SEND_SIZE_LABEL},
    {key: "packagename", label: PACKAGE_NAME_LABEL},
    {key: "neworcompedplacement", label: NEW_OR_COMPED_LABEL},
    {key: "vipcontent", label: VIP_CONTENT_LABEL},
    {key: "proposedofferuseddescription", label: PROPOSED_OFFER_USED_DESCRIPTION_TEXT_FIELD},
    {key: "performanceoriginalpublishercommission", label: ORIGINAL_PUBLISHER_COMMISSION_LABEL},
    {key: "performancenewpublishercommission", label: NEW_PUBLISHER_COMMISSION_LABEL},
    {key: "performanceoriginalratecardflatfeeprice", label: ORIGINAL_FLAT_FEE_LABEL},
    {key: "performancecjnegotiatedflatfeecost", label: CJ_FLAT_FEE_LABEL},
    {key: "bookingcjmarketplaceinsertionordernumber", label: CJ_MARKETPLACE_IO_LABEL},
    {key: "bookingotherinsertionordernumber", label: PUBLISHER_IO_LABEL},
    {key: "bookinginsertionordersignedindicator", label: IO_SIGNED_LABEL},
    {key: "bookingprogramtermdescription", label: PROGRAM_TERM_DESCRIPTION_LABEL},
    {key: "bookingprogramtermname", label: PROGRAM_TERM_NAME_LABEL},
    {key: "bookingprogramtermspushedindicator", label: PROGRAM_TERM_PUSHED_LABEL},
    {key: "bookinglinkid", label: LINK_ID_LABEL},
    {key: "bookingassetsneeded", label: ASSETS_NEEDED_LABEL},
    {key: "bookingassetduedate", label: ASSET_DUE_DATE_LABEL},
    {key: "bookingassetssenttoadvertiser", label: ASSETS_SENT_BY_ADVERTISER_LABEL},
    {key: "bookingoffertype", label: OFFER_TYPE_LABEL},
    {key: "bookingoffervalue", label: OFFER_VALUE_LABEL},
    {key: "bookingofferconditions", label: OFFER_CONDITIONS_LABEL},
    {key: "bookingofferthreshold", label: OFFER_THRESHOLD_LABEL},
    {key: "bookingtieredoffer", label: TIERED_OFFER_LABEL},
    {key: "bookingcashbackorincentiveamountonsite", label: CASHBACK_AMOUNT_ONSITE_LABEL},
    {key: "bookinginvoicemonth", label: CJ_INVOICE_MONTH_LABEL},
    {key: "performancesovbefore", label: SOV_BEFORE_LABEL},
    {key: "lastyearsactualrevenue", label: LAST_YEAR_ACTUAL_REVENUE_LABEL},
    {key: "lastyearsactualcommissions", label: LAST_YEAR_ACTUAL_COMMISSION_LABEL},
    {key: "projectedspendwithplacement", label: PROJECTED_SPEND_WITH_PLACEMENT_LABEL},
    {key: "projectedrevenuewithplacement", label: PROJECTED_REVENUE_WITH_PLACEMENT_LABEL},
    {key: "projectedreturnonadspend", label: PROJECTED_ROAS_LABEL},
    {key: "projectedincrementalrevenue", label: PROJECTED_INCREMENTAL_REVENUE_LABEL},
    {key: "projectedincrementalspend", label: PROJECTED_INCREMENTAL_SPEND_LABEL},
    {key: "projectedincrementalreturnonadspend", label: PROJECTED_INCREMENTAL_ROAS_LABEL},
    {key: "actualrevenue", label: ACTUAL_REVENUE_LABEL},
    {key: "actualincrementalrevenue", label: ACTUAL_INCREMENTAL_REVENUE_LABEL},
    {key: "actualtotalcommissions", label: ACTUAL_TOTAL_COMMISSIONS_LABEL},
    {key: "actualtotalspend", label: ACTUAL_TOTAL_SPEND_LABEL},
    {key: "actualincrementalspend", label: ACTUAL_INCREMENTAL_SPEND_LABEL},
    {key: "actualreturnonadspend", label: ACTUAL_ROAS_LABEL},
    {key: "actualincrementalreturnonadspend", label: ACTUAL_INCREMENTAL_ROAS_LABEL},
    {key: "actualclicks", label: ACTUAL_CLICKS_LABEL},
    {key: "actualrevenuepervisits", label: ACTUAL_RPV_LABEL},
    {key: "actualsales", label: ACTUAL_SALES_LABEL},
    {key: "actualnumberofsalesgeneratedperclick", label: ACTUAL_CR_LABEL},
    {key: "actualplacementlift", label: ACTUAL_PLACEMENT_LIFT_LABEL},
    {key: "performanceactualsovafter", label: ACTUAL_SOV_AFTER_LABEL},
    {key: "revenuedelta", label: REVENUE_DELTA_LABEL},
    {key: "revenueaccuracy", label: REVENUE_ACCURACY_LABEL},
    {key: "returnonadspendaccuracy", label: ROAS_ACCURACY_LABEL},
    {key: "placementcreated", label: PLACEMENT_CREATION_DATE_TIME_LABEL},
    {key: "placementcreatedby", label: PLACEMENT_CREATED_BY_LABEL},
    {key: "forecastlastupdated", label: PERFORMANCE_LAST_UPDATED_ON_LABEL},
]
export const EXCEL_MULTI_SHEET3_COLUMNS = [
    {key: "id", label: PLACEMENT_ID_LABEL},
    {key: "placementname", label: PLACEMENT_NAME},
    {key: "advertisercompanyid", label: ADVERTISER_CID_LABEL},
    {key: "advertisername", label: ADVERTISER_NAME_LABEL},
    {key: "startdate", label: START_DATE_LABEL},
    {key: "starttime", label: START_TIME_LABEL},
    {key: "enddate", label: END_DATE_LABEL},
    {key: "endtime", label: END_TIME_LABEL},
    {key: "timezone", label: ADVERTISER_TIMEZONE_LABEL},
    {key: "status", label: PLACEMENT_STATUS_LABEL},
    {key: "publishername", label: PUBLISHER_NAME_LABEL},
    {key: "publishercompanyid", label: PUBLISHER_CID_LABEL},
    {key: "publisherpropertyid", label: PUBLISHER_PROPERTY_TEXT_FIELD},
    {key: "placementtype", label: PLACEMENT_TYPE_LABEL},
    {key: "advertisercurrency", label: ADVERTISERS_FUNCTIONAL_CURRENCY_LABEL},
    {key: "calendaryear", label: CALENDAR_YEAR_LABEL},
    {key: "calendarqtr", label: CALENDAR_QTR_LABEL},
    {key: "calendarmonth", label: CALENDAR_MONTH_LABEL},
    {key: "calendarweek", label: CALENDAR_WEEK_LABEL},
    {key: "fiscalyear", label: FISCAL_YEAR_LABEL},
    {key: "fiscalquarter", label: FISCAL_QUARTER_LABEL},
    {key: "fiscalmonth", label: FISCAL_MONTH_LABEL},
    {key: "fiscalweek", label: FISCAL_WEEK_LABEL},
    {key: "websiteurl", label: WEBSITE_URL_LABEL},
    {key: "propertymodel", label: PROPERTY_MODEL_LABEL},
    {key: "personalizedortargeted", label: PERSONALIZED_TARGETED_LABEL},
    {key: "dynamicorstatic", label: DYNAMIC_STATIC_LABEL},
    {key: "rankofplacement", label: RANK_OR_SPOT_NUMBER_OF_PLACEMENT_TEXT_FIELD},
    {key: "event", label: EVENTS_LABEL},
    {key: "packagename", label: PACKAGE_NAME_LABEL},
    {key: "neworcompedplacement", label: NEW_OR_COMPED_LABEL},
    {key: "vipcontent", label: VIP_CONTENT_LABEL},
    {key: "proposedofferuseddescription", label: PROPOSED_OFFER_USED_DESCRIPTION_TEXT_FIELD},
    {key: "performanceoriginalpublishercommission", label: ORIGINAL_PUBLISHER_COMMISSION_LABEL},
    {key: "performancenewpublishercommission", label: NEW_PUBLISHER_COMMISSION_LABEL},
    {key: "performanceoriginalratecardflatfeeprice", label: ORIGINAL_FLAT_FEE_LABEL},
    {key: "performancecjnegotiatedflatfeecost", label: CJ_FLAT_FEE_LABEL},
    {key: "bookingoffertype", label: OFFER_TYPE_LABEL},
    {key: "bookingoffervalue", label: OFFER_VALUE_LABEL},
    {key: "bookingofferconditions", label: OFFER_CONDITIONS_LABEL},
    {key: "bookingofferthreshold", label: OFFER_THRESHOLD_LABEL},
    {key: "bookingtieredoffer", label: TIERED_OFFER_LABEL},
    {key: "bookingcashbackorincentiveamountonsite", label: CASHBACK_AMOUNT_ONSITE_LABEL},
    {key: "bookinginvoicemonth", label: CJ_INVOICE_MONTH_LABEL},
    {key: "wascorrectofferused", label: CORRECT_OFFER_USED_LABEL},
    {key: "description", label: OFFER_DESCRIPTION_TEXT_LABEL},
    {key: "publisherusedspecifiedlinkid", label: PUBLISHER_USED_LINK_ID_LABEL},
    {key: "screenshotlink", label: PATH_TO_SAVED_EMAIL_LABEL},
    {key: "validationnotes", label: VALIDATION_NOTES_LABEL},
    {key: "validationcreated", label: VALIDATION_DETAILS_CREATED_ON_LABEL},
    {key: "validationcreatedby", label: VALIDATION_DETAILS_CREATED_BY_LABEL},
    {key: "validationlastupdated", label: VALIDATION_DETAILS_LAST_UPDATED_ON_LABEL},
    {key: "validationlastupdatedby", label: VALIDATION_DETAILS_LAST_UPDATED_BY_LABEL},
    {key: "paidincj", label: PAID_IN_CJ_LABEL},
    {key: "transactionid", label: PAYMENT_OID_LABEL},
    {key: "transactiondate", label: PAYMENT_DATE_LABEL},
    {key: "amount", label: AMOUNT_PAID_LABEL},
    {key: "notes", label: PAYMENT_NOTES_LABEL},
    {key: "paymentcreated", label: PAYMENT_DETAILS_CREATED_ON_LABEL},
    {key: "paymentcreatedby", label: PAYMENT_DETAILS_CREATED_BY_LABEL},
    {key: "paymentlastupdated", label: PAYMENT_DETAILS_LAST_UPDATED_ON_LABEL},
    {key: "paymentlastupdatedby", label: PAYMENT_DETAILS_LAST_UPDATED_BY_LABEL}
]

export const tabErrorMessages = {
    'bookingdetails': 'campaigns.booking.details.error.message',
    'validationDetails': 'campaigns.validation.details.error.message',
    'paymentDetails': 'campaigns.payment.details.error.message'
};
export const tabs = ["validationDetails", "bookingdetails", "paymentDetails"];

export const detailsTab = [
    {getKey: "placementname", formKey: 'placementName'},
    {getKey: "status", formKey: 'placementStatusId'},
    {getKey: "startdate", formKey: 'pStartDate'},
    {getKey: "enddate", formKey: 'pEndDate'},
    {getKey: "packagename", formKey: 'packageName'},
    {getKey: "placementtype", formKey: 'placementType'},
    {getKey: "dynamicorstatic", formKey: 'dynamicOrStatic'},
    {getKey: "personalizedortargeted", formKey: 'personalizedOrTargeted'},
    {getKey: "neworcompedplacement", formKey: 'newOrComped'},
    {getKey: "vipcontent", formKey: 'vipContentCampaign'},
    {getKey: "rankofplacement", formKey: 'rankofplacement'},
    {getKey: "event", formKey: 'events'},
    {getKey: "proposedofferuseddescription", formKey: 'proposedOfferDescription'},
    {getKey: "paymentid", formKey: 'paymentid'},
    {getKey: "validationid", formKey: 'validationid'},
    {getKey: "fiscalyear", formKey: 'fiscalYear'},
    {getKey: "fiscalquarter", formKey: 'fiscalQuarter'},
    {getKey: "fiscalmonth", formKey: 'fiscalMonth'},
    {getKey: "fiscalweek", formKey: 'fiscalWeek'},
]

export const detailsTabPID = [
    {getKey: "publisherpropertyid", formKey: 'publisherPropertyId'},
    {getKey: "publishercompanyid", formKey: 'publisherCompanyId'},
    {getKey: "propertymodel", formKey: 'propertyModel'},
    {getKey: "websiteurl", formKey: 'websiteUrl'},
    {getKey: "publishername", formKey: 'publisherName'},
    {getKey: "propertytype", formKey: 'propertyType'},
    {getKey: "propertyname", formKey: 'propertyName'},
]

export const detailsTabAdvertiser = [
    {getKey: "advertisercompanyid", formKey: "value"},
    {getKey: "advertisername", formKey: "companyName"}
]

export const bookingdetails = [
    {getKey: 'bookingcjmarketplaceinsertionordernumber', formKey: 'bookingmarketinsertionorder'},
    {getKey: 'bookingotherinsertionordernumber', formKey: 'bookingotherio'},
    {getKey: 'bookinginsertionordersignedindicator', formKey: 'bookingissigned'},
    {getKey: 'bookingprogramtermname', formKey: 'bookingprogramtermname'},
    {getKey: 'bookingprogramtermspushedindicator', formKey: 'bookingispushed'},
    {getKey: 'bookinglinkid', formKey: 'bookinglinkid'},
    {getKey: 'bookingassetduedate', formKey: 'bookingduedate'},
    {getKey: 'bookingassetsneeded', formKey: 'bookingassetsneeded'},
    {getKey: 'bookingassetssenttoadvertiser', formKey: 'bookingassetsentdate'},
    {getKey: 'bookingcashbackorincentiveamountonsite', formKey: 'bookingincentiveamount'},
    {getKey: 'bookingtieredoffer', formKey: 'bookingtieredoffer'},
    {getKey: 'bookingoffertype', formKey: 'bookingoffertype'},
    {getKey: 'bookingoffervalue', formKey: 'bookingoffervalue'},
    {getKey: 'bookingofferconditions', formKey: 'bookingoffercondition'},
    {getKey: 'bookingofferthreshold', formKey: 'bookingofferthreshold'},
    {getKey: 'bookingprogramtermdescription', formKey: 'bookingprogramtermdescription'},
    {getKey: 'bookinginvoicemonth', formKey: 'bookinginvoicemonth'},
]

export const validationTabDetails = [
    {getKey: "publisherusedspecifiedlinkid", formKey: 'publisherusedspecifiedlinkid'},
    {getKey: "wascorrectofferused", formKey: 'wascorrectofferused'},
    {getKey: "description", formKey: 'description'},
    {getKey: "screenshotlink", formKey: 'screenshotlink'},
    {getKey: "validationnotes", formKey: 'validationnotes'}
]

export const paymentTabDetails = [
    {getKey: "amount", formKey: 'amount'},
    {getKey: "notes", formKey: 'notes'},
    {getKey: "paidincj", formKey: 'paidInCJ'},
    {getKey: "transactiondate", formKey: 'transactiondate'},
    {getKey: "transactionid", formKey: 'transactionid'}
]

export const performanceTabDetails = [
    {getKey: "performanceactualsovafter", formKey: "performanceActualSovAfter"},
    {getKey: "performancecjnegotiatedflatfeecost", formKey: "performanceCjNegotiatedFlatFeeCost"},
    {getKey: "performancenewpublishercommission", formKey: "performanceNewPublisherCommission"},
    {getKey: "performanceoriginalpublishercommission", formKey: "performanceOriginalPublisherCommission"},
    {getKey: "performanceoriginalratecardflatfeeprice", formKey: "performanceOriginalRateCardFlatFeePrice"},
    {getKey: "performanceotherexpectedlift", formKey: "performanceOtherExpectedLift"},
    {getKey: "performancepredictedplacementlift", formKey: "performancePredictedPlacementLift"},
    {getKey: "performancesendsizeexpecteduniquevalues", formKey: "performanceSendSizeExpectedUniqueValues"},
    {getKey: "performancesovbefore", formKey: "performanceSovBefore"},
    {getKey: "performanceyoygrowthtrendused", formKey: "performanceyoygrowthtrendused"},
    {getKey: "baselineValue", formKey: "baselineValue"}
]

export const forecastPerformanceDetails = [
    {getKey: "yearoveryearlastyearrevenue", formKey: "yearOverYearLastYearRevenue"},
    {getKey: "yearoveryearcurrentyearrevenue", formKey: "yearOverYearCurrentYearRevenue"},
    {getKey: "yoyquarterstartdatelastyear", formKey: "yoyQuarterStartDateLastYear"},
    {getKey: "yoyquarterenddatelastyear", formKey: "yoyQuarterEndDateLastYear"},
    {getKey: "yoyquarterstartdatecurrentyear", formKey: "yoyQuarterStartDateCurrentYear"},
    {getKey: "yoyquarterenddatecurrentyear", formKey: "yoyQuarterEndDateCurrentYear"},
    {getKey: "yearOverYearObserved", formKey: "yearOverYearObserved"}
]

/* Filter Panel Section */
export const PLACEMENT_MODE_BULK_UPLOAD = 'bulk-upload-mode';
export const PLACEMENT_MODE_CREATE = 'create-mode';
export const PLACEMENT_MODE_EDIT = 'edit-mode';
export const PLACEMENT_MODE_DUPLICATE = 'duplicate-mode';
export const PLACEMENT_MODE_REMOVE = 'remove-mode';

/*Date Filter */
export const DATE_FILTER_START_DATE = "2016-01-01"
export const NO_LOCALE = "";

/* Custom Baseline */
export const BASELINE_NOT_AVAILABLE = "Not Available";
export const CURRENCY_AND_PERCENTAGE_FORMAT_LOCALE = "en-US";
export const FETCHING_BASELINE = "Fetching Baseline value...";

/* Column Chooser */
export const PLACEMENT_TYPE_LOCATION_KEY = "placementtype";
export const PLACEMENT_NAME_KEY = "placementname";
export const END_DATE_KEY = "enddate";
export const START_DATE_KEY = "startdate";
export const PUBLISHER_NAME_KEY = "publishername";
export const PUBLISHER_CID_KEY = "publishercompanyid";
export const PUBLISHER_PID_KEY = "publisherpropertyid";
export const ADVERTISER_CID_KEY = "advertisercompanyid";
export const ADVERTISER_NAME_KEY = "advertisername";
export const DEFAULT_SELECTED_COLUMN = [
    START_DATE_KEY,
    END_DATE_KEY,
    ADVERTISER_CID_KEY,
    ADVERTISER_NAME_KEY,
    STATUS,
    PUBLISHER_NAME_KEY,
    PUBLISHER_CID_KEY,
    PUBLISHER_PID_KEY,
    PLACEMENT_TYPE_LOCATION_KEY];

export const MANDATORY_UI_COLUMNS = [PLACEMENT_NAME_KEY];

export const SINGLE_SHEET_MANDATORY_EXCEL_COLUMNS = [
    {key: "placementcreated", label: PLACEMENT_CREATION_DATE_TIME_LABEL},
    {key: "placementcreatedby", label: PLACEMENT_CREATED_BY_LABEL},
    {key: "forecastlastupdated", label: PERFORMANCE_LAST_UPDATED_ON_LABEL},
    {key: "validationcreated", label: VALIDATION_DETAILS_CREATED_ON_LABEL},
    {key: "validationcreatedby", label: VALIDATION_DETAILS_CREATED_BY_LABEL},
    {key: "validationlastupdated", label: VALIDATION_DETAILS_LAST_UPDATED_ON_LABEL},
    {key: "validationlastupdatedby", label: VALIDATION_DETAILS_LAST_UPDATED_BY_LABEL},
    {key: "paymentcreated", label: PAYMENT_DETAILS_CREATED_ON_LABEL},
    {key: "paymentcreatedby", label: PAYMENT_DETAILS_CREATED_BY_LABEL},
    {key: "paymentlastupdated", label: PAYMENT_DETAILS_LAST_UPDATED_ON_LABEL},
    {key: "paymentlastupdatedby", label: PAYMENT_DETAILS_LAST_UPDATED_BY_LABEL}
]
export const MULTI_SHEET1_MANDATORY_EXCEL_COLUMNS = [
    {key: "placementcreated", label: PLACEMENT_CREATION_DATE_TIME_LABEL},
    {key: "placementcreatedby", label: PLACEMENT_CREATED_BY_LABEL}
]
export const MULTI_SHEET2_MANDATORY_EXCEL_COLUMNS = [
    {key: "placementcreated", label: PLACEMENT_CREATION_DATE_TIME_LABEL},
    {key: "placementcreatedby", label: PLACEMENT_CREATED_BY_LABEL},
    {key: "forecastlastupdated", label: PERFORMANCE_LAST_UPDATED_ON_LABEL},
]
export const MULTI_SHEET3_MANDATORY_EXCEL_COLUMNS = [
    {key: "validationcreated", label: VALIDATION_DETAILS_CREATED_ON_LABEL},
    {key: "validationcreatedby", label: VALIDATION_DETAILS_CREATED_BY_LABEL},
    {key: "validationlastupdated", label: VALIDATION_DETAILS_LAST_UPDATED_ON_LABEL},
    {key: "validationlastupdatedby", label: VALIDATION_DETAILS_LAST_UPDATED_BY_LABEL},
    {key: "paymentcreated", label: PAYMENT_DETAILS_CREATED_ON_LABEL},
    {key: "paymentcreatedby", label: PAYMENT_DETAILS_CREATED_BY_LABEL},
    {key: "paymentlastupdated", label: PAYMENT_DETAILS_LAST_UPDATED_ON_LABEL},
    {key: "paymentlastupdatedby", label: PAYMENT_DETAILS_LAST_UPDATED_BY_LABEL}
]

/*Width of Modal Tooltips*/
export const WIDTH_DEFAULT = '270px';
export const TOOLTIP_PROPS = {
    placement: "top-start",
    maxWidth: WIDTH_DEFAULT
}

/*Remove Modal*/
export const ROUTE_BULK_UPLOAD = 'bulk-upload';
export const DATATABLE_LICENSE_KEY = "CompanyName=CJ,LicensedApplication=Campaigns,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=4,LicensedProductionInstancesCount=1,AssetReference=AG-033491,SupportServicesEnd=28_February_2024_[v2]_MTcwOTA3ODQwMDAwMA==268b51a93883a87317b3e239a22911ee";
export const TYPE_BATCH = 'Batch';
export const NOTE_TYPE = "alert";
export const ASCENDING = "ascending";
export const DESCENDING = "descending";

/* Not for translation */
export const AUTHORIZED_VIEW = "AuthorizedView";
export const UNAUTHORIZED_VIEW = "UnauthorizedView";
export const MAINTENANCE_VIEW = "MaintenanceView";
export const BLANKSLATE_VIEW = "BlankSlateView";
export const CREATE_MODAL_SUCCESS_MESSAGE_TO_COMPARE = "successfully!";
export const UPDATE_MODAL_SUCCESS_MESSAGE_TO_COMPARE = "Record updated successfully!";
export const CREATE_MODAL_ERROR_MESSAGE_TO_COMPARE = "Please try again or contact the Support Team."

export const CREATE_MODAL_FAILURE_MESSAGE_TO_COMPARE = "Sorry, the placement was not saved due to technical issues";
export const UPDATE_MODAL_FAILURE_MESSAGE_TO_COMPARE = "Sorry, the placement was not updated due to technical issues.";
export const CREATE_MODAL_REMOVED_PLACEMENT_ERROR_MESSAGE_TO_COMPARE = "This placement has already been removed & cannot be edited";

export const DROPDOWN_OFFER_CONDITION = "OFFER_CONDITION";
export const DROPDOWN_OFFER_TYPE = "OFFER_TYPE";
export const DROPDOWN_EVENTS = "EVENTS";
export const DROPDOWN_PLACEMENT_STATUS = "PLACEMENT_STATUS";
export const DROPDOWN_PROGRAM_TERM_PUSHED = "PROGRAM_TERM_PUSHED";
export const DROPDOWN_YES_NO_NA = "YES_NO_NA";
export const DROPDOWN_PAID_IN_CJ = "PAID_IN_CJ";
export const DROPDOWN_YES_NO = "YES_NO";
export const DROPDOWN_DYNAMIC_OR_STATIC = "DYNAMIC_OR_STATIC";
export const DROPDOWN_NEW_OR_COMPED = "NEW_OR_COMPED";
export const DROPDOWN_PLACEMENT_TYPE = "PLACEMENT_TYPE";

/*Blank Slate - not for translation */
export const HELP = "help"
export const PERFORMANCE_KPI_URL = "https://members.cj.com/member/support/?id=000001337"
// export const LEARN_HOW_URL = "https://members.cj.com/member/support/?id=000001338"
export const SUPPORT_CENTRE_LINK = "https://members.cj.com/member/contactSupport.cj"
export const CONTACT_US_LINK = "https://members.cj.com/member/support/?id=000001340"
export const REGISTER_ADVERTISER_MODAL = 'register-advertiser-modal'
export const CAMPAIGNS_BULK_UPLOAD_SOLUTIONS_URL = "https://members.cj.com/member/support/?id=Campaigns-Solution-Bulk-Upload"
