import React, {useEffect, useState} from "react";
import {Label} from "@cjdev-internal/visual-stack-x/legacy/Form";
import Input from './Input';
import {Box} from "@cjdev-internal/visual-stack-x/Box";
import CampaignsDatePicker from "./CampaignsDatePicker";
import * as appConstant from '../../constants/appConstant'
import CampaignsDropDown from "./CampaignsDropDown";
import {isFlatFeeGT0, sanitize} from "../../utils/util";
import TextFieldHelp from './TextFieldHelp';
import {usePlacementFormDataContext} from '../CampaignsContext/useCampaignsContext';
import {useIntl} from "../../useIntl";

const CreateRecordBookingContent = ({
                                        handleChange,
                                        id,
                                        errorData,
                                        getPlacementFieldData,
                                        modeOfPlacement,
                                        campaignsTranslatedDropdownList
                                    }) => {

    const [bookingDetails, setBookingDetails] = useState({});
    const [formDataStatus, setFormDataStatus] = useState({});
    const {formData} = usePlacementFormDataContext();
    const intl = useIntl();

    function handleBookingDetails(id, value) {
        setBookingDetails({
            ...bookingDetails,
            [id]: value
        });

        if ((value === "" || value === null) && modeOfPlacement === appConstant.PLACEMENT_MODE_CREATE) {
            setBookingDetails(() => {
                const bookingDetailsState = {...bookingDetails};
                delete bookingDetailsState[id]
                return bookingDetailsState
            })
        }
    }

    const handleChangeForTextField = (e) => handleBookingDetails(e.target.name, sanitize(e.target.value.trim()));

    const isRequiredCondition = () => {
        const isStatusBooked = () => {
            const placementRecordStatus = getPlacementFieldData(appConstant.STATUS, 'select').value;
            const activeStatus = formDataStatus ? formDataStatus : placementRecordStatus;
            return activeStatus === appConstant.BOOKED;
        }

        const flatFeeGT0 = () => {
            return isFlatFeeGT0(getPlacementFieldData('performancecjnegotiatedflatfeecost', 'input'),
                getPlacementFieldData('performanceoriginalratecardflatfeeprice', 'input'),
                formData?.performanceDetails?.performanceCjNegotiatedFlatFeeCost,
                formData?.performanceDetails?.performanceOriginalRateCardFlatFeePrice)
        }
        return isStatusBooked() && flatFeeGT0();
    }

    useEffect(() => {
        handleChange(id, bookingDetails);
    }, [bookingDetails]);

    useEffect(() => {
        setFormDataStatus(formData?.placementStatusId);
    }, [formData])

    return (
        <Box direction="column" gap="large">
            <Box direction="row" gap="xl">
                <Box direction="column">
                    <TextFieldHelp id="bookingmarketplaceioid" name="bookingmarketinsertionorder"
                                   label={intl.formatMessage({id: "campaigns.placement.cj.marketplace.io"})}
                                   required={isRequiredCondition()}
                                   onChange={handleChangeForTextField}
                                   maxLength={appConstant.MAX_LENGTH_200}
                                   help={isRequiredCondition() && intl.formatMessage({id: "campaigns.io.field.help.message"})}
                                   errorData={errorData}
                                   defaultValue={getPlacementFieldData('bookingcjmarketplaceinsertionordernumber', 'input')}
                    />
                </Box>
                <Box direction="column">
                    <TextFieldHelp id="bookingotherioid" name="bookingotherio"
                                   label={intl.formatMessage({id: "campaigns.placement.publisher.insertion.order.id"})}
                                   required={isRequiredCondition()}
                                   onChange={handleChangeForTextField}
                                   defaultValue={getPlacementFieldData('bookingotherinsertionordernumber', 'input')}
                                   maxLength={appConstant.MAX_LENGTH_200}
                                   help={isRequiredCondition() && intl.formatMessage({id: "campaigns.io.field.help.message"})}
                                   errorData={errorData}/>
                </Box>
                <Box direction="column">
                    <Label>{intl.formatMessage({id: "campaigns.placement.io.signed"})}</Label>
                    <CampaignsDropDown id='bookingissigned' handleChange={handleBookingDetails}
                                       defaultValue={getPlacementFieldData('bookinginsertionordersignedindicator', 'select')}
                                       optionsValue={campaignsTranslatedDropdownList(appConstant.DROPDOWN_YES_NO_NA, intl)}
                                       errorData={null}/>
                </Box>
            </Box>
            <Box direction="row" gap="xl">
                <Box direction="column">
                    <Label
                        labelTooltip={intl.formatMessage({id: "campaigns.placement.program.term.description.tooltip"})}
                        tooltipProps={appConstant.TOOLTIP_PROPS}>{intl.formatMessage({id: "campaigns.placement.program.term.description"})}</Label>
                    <Input
                        id="bookingprogramtermdescription"
                        type={"text"}
                        name="bookingprogramtermdescription"
                        defaultValue={getPlacementFieldData('bookingprogramtermdescription', 'input')}
                        onChange={handleChangeForTextField}
                        maxLength={appConstant.MAX_LENGTH_500}
                    />
                </Box>
                <Box direction="column">
                    <Label labelTooltip={intl.formatMessage({id: "campaigns.placement.program.term.name.tooltip"})}
                           tooltipProps={appConstant.TOOLTIP_PROPS}>{intl.formatMessage({id: "campaigns.placement.program.term.name"})}</Label>
                    <Input id="bookingprogramtermid" type='text' name="bookingprogramtermname"
                           onChange={handleChangeForTextField}
                           defaultValue={getPlacementFieldData('bookingprogramtermname', 'input')}
                           maxLength={appConstant.MAX_LENGTH_500}/>
                </Box>
                <Box direction="column">
                    <Label>{intl.formatMessage({id: "campaigns.placement.program.term.pushed"})}</Label>
                    <CampaignsDropDown id='bookingispushed' handleChange={handleBookingDetails}
                                       defaultValue={getPlacementFieldData('bookingprogramtermspushedindicator', 'select')}
                                       optionsValue={campaignsTranslatedDropdownList(appConstant.DROPDOWN_PROGRAM_TERM_PUSHED, intl)}
                                       errorData={null}/>
                </Box>
            </Box>
            <Box direction="row" gap="xl">
                <Box direction="column">
                    <Label>{intl.formatMessage({id: "campaigns.placement.asset.due.date"})}</Label>
                    <CampaignsDatePicker id="bookingduedate" handleChange={handleBookingDetails}
                                         defaultValue={getPlacementFieldData('bookingassetduedate', 'date')}
                                         errorData={null}/>
                </Box>
                <Box direction="column">
                    <Label labelTooltip={intl.formatMessage({id: "campaigns.placement.assets.needed.tooltip"})}
                           tooltipProps={appConstant.TOOLTIP_PROPS}>{intl.formatMessage({id: "campaigns.placement.asset.needed"})}</Label>
                    <Input id="bookingassetsneededid" type='text' name="bookingassetsneeded"
                           onChange={handleChangeForTextField}
                           maxLength={appConstant.MAX_LENGTH_500}
                           defaultValue={getPlacementFieldData('bookingassetsneeded', 'input')}/>
                </Box>
                <Box direction="column">
                    <Label>{intl.formatMessage({id: "campaigns.placement.asset.sent.by.advertiser"})}</Label>
                    <CampaignsDatePicker id="bookingassetsentdate" handleChange={handleBookingDetails}
                                         defaultValue={getPlacementFieldData('bookingassetssenttoadvertiser', 'date')}
                                         errorData={null}/>
                </Box>
            </Box>
            <Box direction="row" gap="xl">
                <Box direction="column">
                    <Label labelTooltip={intl.formatMessage({id: "campaigns.placement.tiered.offer.tooltip"})}
                           tooltipProps={appConstant.TOOLTIP_PROPS}>{intl.formatMessage({id: "campaigns.placement.tiered.offer"})}</Label>
                    <Input id="bookingtieredofferid"
                           type='text' name="bookingtieredoffer"
                           onChange={handleChangeForTextField}
                           defaultValue={getPlacementFieldData('bookingtieredoffer', 'input')}
                           maxLength={appConstant.MAX_LENGTH_500}/>
                </Box>
                <Box direction="column">
                    <Label>{intl.formatMessage({id: "campaigns.placement.offer.type"})}</Label>
                    <CampaignsDropDown id='bookingoffertype' handleChange={handleBookingDetails}
                                       defaultValue={getPlacementFieldData('bookingoffertype', 'select')}
                                       optionsValue={campaignsTranslatedDropdownList(appConstant.DROPDOWN_OFFER_TYPE, intl)}
                                       errorData={null}/>
                </Box>
                <Box direction="column">
                    <Label>{intl.formatMessage({id: "campaigns.placement.offer.value"})}</Label>
                    <Input id="bookingoffervalueid" type='text' name="bookingoffervalue"
                           onChange={handleChangeForTextField}
                           maxLength={appConstant.MAX_LENGTH_200}
                           defaultValue={getPlacementFieldData('bookingoffervalue', 'input')}/>
                </Box>
            </Box>
            <Box direction="row" gap="xl">
                <Box direction="column">
                    <Label labelTooltip={intl.formatMessage({id: "campaigns.placement.offer.conditions.tooltip"})}
                           tooltipProps={appConstant.TOOLTIP_PROPS}>{intl.formatMessage({id: "campaigns.placement.offer.condition"})}</Label>
                    <CampaignsDropDown id='bookingoffercondition' handleChange={handleBookingDetails}
                                       defaultValue={getPlacementFieldData('bookingofferconditions', 'select')}
                                       optionsValue={campaignsTranslatedDropdownList(appConstant.DROPDOWN_OFFER_CONDITION, intl)}
                                       errorData={null}/>
                </Box>
                <Box direction="column">
                    <Label labelTooltip={intl.formatMessage({id: "campaigns.placement.offer.threshold.tooltip"})}
                           tooltipProps={appConstant.TOOLTIP_PROPS}>{intl.formatMessage({id: "campaigns.placement.offer.threshold"})}</Label>
                    <Input id="bookingofferthresholdid" type='text' name="bookingofferthreshold"
                           onChange={handleChangeForTextField}
                           maxLength={appConstant.MAX_LENGTH_500}
                           defaultValue={getPlacementFieldData('bookingofferthreshold', 'input')}/>
                </Box>
                <Box direction="column">
                    <Label>{intl.formatMessage({id: "campaigns.placement.cashback.amount.onsite"})}</Label>
                    <Input id="bookingincentiveamountid" type='text' name="bookingincentiveamount"
                           onChange={handleChangeForTextField}
                           maxLength={appConstant.MAX_LENGTH_200}
                           defaultValue={getPlacementFieldData('bookingcashbackorincentiveamountonsite', 'input')}/>
                </Box>
            </Box>
            <Box direction="row" gap="xl">
                <Box direction="column">
                    <Label>{intl.formatMessage({id: "campaigns.placement.link.id"})}</Label>
                    <Input id="bookinglinkid" type='text' name="bookinglinkid"
                           defaultValue={getPlacementFieldData('bookinglinkid', 'input')}
                           onChange={handleChangeForTextField}
                           maxLength={appConstant.MAX_LENGTH_200}/>
                </Box>
                <Box direction="column">
                    <Label>{intl.formatMessage({id: "campaigns.placement.cj.invoice.month"})}</Label>
                    <Input
                        id="bookinginvoicemonth"
                        type={"text"}
                        name="bookinginvoicemonth"
                        defaultValue={getPlacementFieldData('bookinginvoicemonth', 'input')}
                        onChange={handleChangeForTextField}
                        maxLength={appConstant.MAX_LENGTH_500}/>
                </Box>
            </Box>
        </Box>
    );
}
export default CreateRecordBookingContent;
