import moment from "moment";
import lodash from "lodash";
import {hasValue} from "../../../utils/util";
import {BULK_UPLOAD_ERROR_MESSAGE, EN_VALUE} from "../BulkUploadConstant";
import {NO, YES} from "../../../constants/appConstant";

export const dateValidation = (row, column) => {
    const value = lodash.get(row, column.field);
    return hasValue(value) ? moment(value, column.validationArgs.dateFormat, true).isValid() : true;
}

export const maxCharacterValidation = (row, column) => {
    const value = lodash.get(row, column.field);
    return hasValue(value) ? value.length <= column.validationArgs.maxLength : true;
}

export const optionListValidation = (row, column, formatMessage) => {
    const value = lodash.get(row, column.field)?.toLowerCase();
    let originalTranslateValue = '';
    if (!hasValue(value)) return true;
    const searchValue = column.validationArgs.acceptedValues.find(option => {
        const translatedValue = formatMessage({id: option.translationKey});
        if (translatedValue.toLowerCase() === value) {
            originalTranslateValue = translatedValue;
        }
        return translatedValue.toLowerCase() === value;
    });

    if (searchValue) {
        lodash.set(row, column.field, originalTranslateValue);
        lodash.set(row, column.field + EN_VALUE, searchValue.value);
        return true;
    } else {
        return false;
    }
}

export const numericValidation = (row, column) => {
    const value = lodash.get(row, column.field);
    return hasValue(value) ? !isNaN(value) : true;
}

export const nonNegativeNumericValidation = (row, column) => {
    const value = lodash.get(row, column.field);
    return hasValue(value) ? (!isNaN(value) ? Number(value) >= 0 : false) : true;
}

export const baselineCalculatedValidation = (row, column, formatMessage) => {
    const isBaselineCalculated = row?.performanceDetails?.baselineValue ? row.performanceDetails?.baselineValue.isBaselineCalculated : undefined;
    const averageBaselineRevenue = row?.performanceDetails?.baselineValue ? row?.performanceDetails?.baselineValue.averageBaselineRevenue : undefined;
    if (!hasValue(isBaselineCalculated)) {
        column.errorMessage = formatMessage({id: BULK_UPLOAD_ERROR_MESSAGE['isBaselineCalculated']});
        return false;
    } else {
        if (!optionListValidation(row, column, formatMessage)) {
            column.errorMessage = formatMessage({id: BULK_UPLOAD_ERROR_MESSAGE['invalidBaselineCalculated']});
            return false;
        } else if ((row.performanceDetails?.baselineValue.isBaselineCalculatedEnValue?.toLowerCase() === YES.toLowerCase() && hasValue(averageBaselineRevenue)) || (row.performanceDetails?.baselineValue.isBaselineCalculatedEnValue?.toLowerCase() === NO.toLowerCase() && !hasValue(averageBaselineRevenue))) {
            column.errorMessage = formatMessage({id: BULK_UPLOAD_ERROR_MESSAGE['invalidAverageBaselineRevenue']});
            return false;
        }
    }
    return true;
}

export const averageBaselineRevenueValidation = (row, column, formatMessage) => {
    const isBaselineCalculatedEnValue = row?.performanceDetails?.baselineValue ? row.performanceDetails?.baselineValue.isBaselineCalculatedEnValue : undefined;

    if (isBaselineCalculatedEnValue?.toLowerCase() === NO.toLowerCase() && !nonNegativeNumericValidation(row, column)) {
        column.errorMessage = formatMessage({id: BULK_UPLOAD_ERROR_MESSAGE['negativeAverageBaselineRevenue']});
        return false;
    }
    return true;
}

export const invalidMessage = (row, column, formatMessage) => {
    return formatMessage({id: "campaigns.bulkupload.invalid.message"}) + column.headerName;
}

export const exceededMaxLengthMessage = (row, column, formatMessage) => {
    return column.headerName + formatMessage({id: "campaigns.bulkupload.exceed.max.length.message"});
}

export const zeroOrPositiveNumberMessage = (row, column, formatMessage) => {
    return column.headerName + formatMessage({id: "campaigns.bulkupload.accept.zero.or.positive.message"});
}
export const onlyNumberMessage = (row, column, formatMessage) => {
    return column.headerName + formatMessage({id: "campaigns.bulkupload.accept.only.number.message"});
}
