import {Label} from "@cjdev-internal/visual-stack-x/legacy/Form"
import Input from './Input';
import * as appConstant from "../../constants/appConstant";
import {NOTE_TYPE} from "../../constants/appConstant";
import {Box} from "@cjdev-internal/visual-stack-x/Box";
import React, {useEffect, useRef, useState} from "react";
import "./styles/CustomBaseline.css"
import {usePlacementFormDataContext} from "../CampaignsContext/useCampaignsContext";
import {useLazyQuery} from "@apollo/client";
import {GET_BASELINE_DETAIL_QUERY} from "../../constants/graphql-query-mutation";
import moment from "moment";
import {hasValue} from "../../utils/util";
import {formatCurrency} from "../../utils/formatFunctions";
import {Note} from "@cjdev-internal/visual-stack-x/Note"
import {Radio, RadioGroup} from "@cjdev-internal/visual-stack-x/RadioGroup";
import {useIntl} from "../../useIntl";

const CustomBaseline = ({handleChange, getPlacementData, modeOfPlacement, errorData}) => {
    const intl = useIntl();
    const {formData} = usePlacementFormDataContext();
    const [selectedValue, setSelectedValue] = useState("")

    const isDuplicatePlacement = modeOfPlacement === appConstant.PLACEMENT_MODE_DUPLICATE;
    const isEditPlacement = modeOfPlacement === appConstant.PLACEMENT_MODE_EDIT;
    const isCreatePlacement = modeOfPlacement === appConstant.PLACEMENT_MODE_CREATE;

    function getFormattedCurrency({value, currency}) {
        let formattedValue = value;
        if (formattedValue !== appConstant.BASELINE_NOT_AVAILABLE && hasValue(currency))
            formattedValue = formatCurrency({value, currency});
        else if (formattedValue === appConstant.BASELINE_NOT_AVAILABLE)
            formattedValue = intl.formatMessage({id: "campaigns.placement.baseline.not.available"});
        else if (formattedValue === appConstant.FETCHING_BASELINE) {
            formattedValue = intl.formatMessage({id: "campaigns.placement.baseline.fetching.value"});
        }
        return formattedValue;
    }

    const placementData = {
        advertiserCompanyId: parseInt(getPlacementData('advertisercompanyid')),
        publisherCompanyId: getPlacementData('publishercompanyid'),
        publisherPropertyId: getPlacementData('publisherpropertyid'),
        startDate: getPlacementData('startdate')
    }
    const baselineDefaultValueCallback = (placementRecord) => {
        return {
            isBaselineCalculated: placementRecord.isbaselinecalculated,
            averageBaselineRevenue: placementRecord.averagebaselinerevenue?.toString(),
            currency: placementRecord.currency
        }
    }
    const defaultValue = getPlacementData('isbaselinecalculated', "", baselineDefaultValueCallback);
    const baselineData = formData?.performanceDetails?.baselineValue || defaultValue;

    let isCustomBaselineSelected = baselineData?.isBaselineCalculated === appConstant.NO;
    let isDefaultBaselineSelected = baselineData?.isBaselineCalculated === appConstant.YES;
    const [customBaselineInput, setCustomBaselineInput] = useState(isCustomBaselineSelected ? baselineData?.averageBaselineRevenue : '');
    const [defaultBaseline, setDefaultBaseline] = useState({
        value: isDefaultBaselineSelected ? baselineData?.averageBaselineRevenue : appConstant.BASELINE_NOT_AVAILABLE,
        currency: baselineData?.currency
    });

    const initialRender = useRef(true);
    const [fetchBaselineValues, baselineProps] = useLazyQuery(GET_BASELINE_DETAIL_QUERY);

    const handleChangeCustomBaselineRadio = () => {
        handleChange("baselineValue", {
            isBaselineCalculated: appConstant.NO,
            averageBaselineRevenue: customBaselineInput
        });
    }
    const handleChangeDefaultBaselineRadio = () => {
        handleChange("baselineValue", {
            isBaselineCalculated: appConstant.YES,
            averageBaselineRevenue: defaultBaseline.value
        });
    }
    const onBlurOfCustomInput = (event) => {
        const value = event.target.value;
        handleChange("baselineValue", {isBaselineCalculated: appConstant.NO, averageBaselineRevenue: value});
        setCustomBaselineInput(value);
    }

    const getFormattedDate = (date) => {
        return date ? moment(date, appConstant.DATE_TIME_FORMAT).format(appConstant.YYYY_MM_DD) : null
    }

    useEffect(() => {
        let {advertiserCompanyIdForm, publisherCompanyIdForm, publisherPropertyIdForm, startDateForm} = {
            advertiserCompanyIdForm: formData?.advertiserCompanyId?.value,
            publisherCompanyIdForm: formData.pid?.publisherCompanyId,
            publisherPropertyIdForm: formData.pid?.publisherPropertyId,
            startDateForm: getFormattedDate(formData?.pStartDate)
        }

        //(iscreate && advertiserCompanyId && publisherCompanyId && publisherPropertyId && startDate) => call
        // (isedit || duplicate ) && (form Data updated || baselineNot available) => call
        const {advertiserCompanyId, publisherCompanyId, publisherPropertyId, startDate} = {
            advertiserCompanyId: advertiserCompanyIdForm || placementData?.advertiserCompanyId,
            publisherCompanyId: publisherCompanyIdForm || placementData?.publisherCompanyId,
            publisherPropertyId: publisherPropertyIdForm || placementData?.publisherPropertyId,
            startDate: startDateForm || getFormattedDate(placementData?.startDate)
        }

        const isRequiredToFetchBaselineCreate = isCreatePlacement && advertiserCompanyId && publisherCompanyId && publisherPropertyId && startDate;
        const isRequiredToFetchBaselineEditOrDuplicate = (isEditPlacement || isDuplicatePlacement) &&
            (advertiserCompanyIdForm || publisherPropertyIdForm || startDateForm || isNaN(defaultBaseline.value));
        if (isRequiredToFetchBaselineCreate || isRequiredToFetchBaselineEditOrDuplicate) {
            const options = {
                variables: {
                    advertiserCompanyId: advertiserCompanyId,
                    publisherCompanyId: publisherCompanyId,
                    publisherPropertyId: publisherPropertyId,
                    startDate: startDate
                }
            }
            fetchBaselineValues(options);
        }
        if (initialRender.current) {
            if (isDuplicatePlacement) {
                handleChange("baselineValue", {
                        isBaselineCalculated: baselineData?.isBaselineCalculated,
                        averageBaselineRevenue: baselineData?.averageBaselineRevenue
                    }
                )
            }
            initialRender.current = false;
        } else {
            handleChange("baselineValue", {});
        }
    }, [formData.advertiserCompanyId, formData.pid, formData.pStartDate]);

    useEffect(() => {
        if (baselineProps.loading) {
            setDefaultBaseline({value: appConstant.FETCHING_BASELINE, currency: ''})
        } else {
            if (baselineProps.error || baselineProps.data?.getBaselineDetails.averagedailyrevenue === -1) {
                setDefaultBaseline({value: appConstant.BASELINE_NOT_AVAILABLE, currency: ''})
            } else if (baselineProps.data) {
                const fetchedBaselineValue = baselineProps.data.getBaselineDetails.averagedailyrevenue?.toString();
                const currency = baselineProps.data.getBaselineDetails.currency;
                setDefaultBaseline({value: fetchedBaselineValue, currency: currency})
            }
        }
    }, [baselineProps.data, baselineProps.loading])

    useEffect(() => {
        setSelectedValue('');
    }, [formData?.advertiserCompanyId?.value, formData?.pid?.publisherPropertyId, formData?.pStartDate])

    useEffect(() => {
        if (isCustomBaselineSelected) {
            setSelectedValue('customBaseline');
        } else if (isDefaultBaselineSelected && !hasDefaultBaselineValue) {
            setSelectedValue('defaultBaseline');
        }
    }, [isCustomBaselineSelected, isDefaultBaselineSelected])
    const hasDefaultBaselineValue = isNaN(defaultBaseline.value);

    const renderWarningNote = (warningMessage, currencyValue) => {
        return (
            <Box direction="row" gap="xl" className="baseline-warning">
                <Note type={NOTE_TYPE}>
                    {warningMessage.replaceAll('{currencyValue}', currencyValue)}
                </Note>
            </Box>
        );
    }

    return (<Box id="baseline" className={errorData?.value ? "baseline-error" : "baseline-no-error"}>
        <Label labelTooltip={intl.formatMessage({id: "campaigns.placement.custom.baseline.tooltip"})}
               tooltipProps={appConstant.TOOLTIP_PROPS}
               required>{intl.formatMessage({id: "campaigns.placement.sov.baseline.selection"})}</Label>
        <RadioGroup direction={"column"} selected={selectedValue} setSelected={setSelectedValue}>
            <Radio
                id={'default-baseline-radio'}
                name="baseline"
                className={"radio-width"}
                value="defaultBaseline"
                checked={isDefaultBaselineSelected}
                onClick={handleChangeDefaultBaselineRadio}
                onChange={() => {
                }}
                disabled={hasDefaultBaselineValue}>
                {intl.formatMessage({id: "campaigns.auto.cal.revenue"}) + ` ${getFormattedCurrency(defaultBaseline)}`}
            </Radio>
            <Box direction="row" gap="xl">
                <Radio
                    id={'custom-baseline-radio'}
                    name="baseline"
                    className={"radio-width"}
                    value="customBaseline"
                    checked={isCustomBaselineSelected}
                    onClick={handleChangeCustomBaselineRadio}
                    onChange={() => {
                    }}>{intl.formatMessage({id: "campaigns.manual.cal.revenue"})}</Radio>
                <Input
                    disabled={selectedValue !== 'customBaseline'}
                    id={'custom-baseline-input'}
                    required
                    type="number"
                    name="baselineValue"
                    placeholder={intl.formatMessage({id: "campaigns.enter.number.placeholder"})}
                    defaultValue={isCustomBaselineSelected ? baselineData?.averageBaselineRevenue : ''}
                    onBlur={onBlurOfCustomInput}
                    min="0"
                />
            </Box>
            {isCustomBaselineSelected && customBaselineInput === '0' &&
                renderWarningNote(intl.formatMessage({id: 'campaigns.baseline.manual.select.warning.message'}),
                    getFormattedCurrency({
                        value: customBaselineInput,
                        currency: defaultBaseline.currency,
                    }).split('.')[0])}

            {isDefaultBaselineSelected && defaultBaseline?.value === '0' &&
                renderWarningNote(intl.formatMessage({id: 'campaigns.baseline.auto.select.warning.message'}), getFormattedCurrency(defaultBaseline).split('.')[0])}
        </RadioGroup>
    </Box>);
};
export default CustomBaseline;
